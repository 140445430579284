import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllDocumentTypes } from "../api/DocumentServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { DocumentTypesMapper } from "./DocumentTypesMapper";

const oInitialState = {
  aDocumentTypes: [],
  bStatus: false,
};

export const actionDocumentTypesGetAll = createAsyncThunk("documents/getAllDocumentTypes", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllDocumentTypes();
    oThunk.dispatch(actionDismissLoading());
    return DocumentTypesMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const DocumentTypesSlice = createSlice({
  name: "DocumentTypesSlice",
  initialState: oInitialState,
  reducers: {
    actionDocumentTypesClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionDocumentTypesGetAll.fulfilled, (oState, oAction) => {
      oState.aDocumentTypes = oAction.payload;
    });
  },
});

export const { actionDocumentTypesClean } = DocumentTypesSlice.actions;

export const selectDocumentTypesState = ({ DocumentTypesSlice: oState }) => oState;

export default DocumentTypesSlice.reducer;
