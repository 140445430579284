export const LocationsMapper = aLocations => {
  const mapLocations = oServerLocation => ({
    id: oServerLocation.locId,
    name: oServerLocation.locNom,
    stateId: oServerLocation.dptoId,
    stateName: oServerLocation.dptoNom,
    label: oServerLocation.locNom,
  });

  if (Array.isArray(aLocations)) return aLocations.map(mapLocations);
  if ((typeof aLocations).toString() === "object") return mapLocations(aLocations);
  return null;
};

export const LocationsUnMapper = aLocations => {
  const unMapLocations = oLocation => ({
    locId: oLocation.id || 0,
    locNom: oLocation.name,
    dptoId: oLocation.stateId,
    dptoNom: oLocation.stateName,
  });

  if (Array.isArray(aLocations)) return aLocations.map(unMapLocations);
  if ((typeof aLocations).toString() === "object") return unMapLocations(aLocations);
  return null;
};
