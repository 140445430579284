import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiCallGetAllPartnersGroups, apiCallPartnersGroupDelete, apiCallPartnersGroupDeleteDiscounts, apiCallPartnersGroupGetDiscounts, apiCallPartnersGroupSave, apiCallPartnersGroupSaveDiscounts, apiCallPartnersGroupUpdate } from "pages/partners/api/PartnerGroupServices";

import { PartnersGroupDiscountMapper, PartnersGroupDiscountUnMapper, PartnersGroupMapper } from "./PartnersGroupMapper";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  aGroups: [],
  bStatus: false,

  aDiscounts: [],
  bDiscountStatus: false,
};

export const actionPartnersGroupGetAll = createAsyncThunk("partnersGroup/getAll", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllPartnersGroups(oData);
    return PartnersGroupMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGroupDelete = createAsyncThunk("partnersGroup/delete", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess?.message || "Grupo de socios eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGroupGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPartnersGroupSave = createAsyncThunk("partnersGroup/save", async(sName, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupSave({ grsNom: sName });
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Grupo generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGroupGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPartnersGroupUpdate = createAsyncThunk("partnersGroup/update", async(oItem, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupUpdate(oItem.id, { grsNom: oItem.name });
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Grupo actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGroupGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPartnersGroupGetDiscounts = createAsyncThunk("partnersGroup/getDiscounts", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupGetDiscounts(nId);
    return PartnersGroupDiscountMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGroupSaveDiscounts = createAsyncThunk("partnersGroup/saveDiscounts", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupSaveDiscounts(PartnersGroupDiscountUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Descuento agregado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGroupGetDiscounts(oData.id));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGroupDeleteDiscounts = createAsyncThunk("partnersGroup/deleteDiscounts", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGroupDeleteDiscounts({
      grsId: oData.id,
      artId: oData.article?.id,
    });
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Descuento eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGroupGetDiscounts(oData.id));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const PartnersGroupSlice = createSlice({
  name: "PartnersGroupSlice",
  initialState: oInitialState,
  reducers: {
    actionCleanPartnersGroup: oState => {
      oState.aGroups = [];
    },
    actionCleanPartnersGroupStatus: oState => {
      oState.bStatus = false;
    },
    actionCleanPartnersGroupDiscounts: oState => {
      oState.aDiscounts = [];
    },
    actionCleanPartnersGroupDiscountStatus: oState => {
      oState.bDiscountStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionPartnersGroupGetAll.fulfilled, (oState, oAction) => {
      oState.aGroups = oAction.payload;
    });
    builder.addCase(actionPartnersGroupSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionPartnersGroupUpdate.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionPartnersGroupGetDiscounts.fulfilled, (oState, oAction) => {
      oState.aDiscounts = oAction.payload;
    });
    builder.addCase(actionPartnersGroupSaveDiscounts.fulfilled, (oState, oAction) => {
      oState.bDiscountStatus = oAction.payload;
    });
  },
});

export const {
  actionCleanPartnersGroup,
  actionCleanPartnersGroupStatus,
  actionCleanPartnersGroupDiscounts,
  actionCleanPartnersGroupDiscountStatus,
} = PartnersGroupSlice.actions;

export const selectPartnersGroupState = ({ PartnersGroupSlice: oState }) => oState;

export default PartnersGroupSlice.reducer;
