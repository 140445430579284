import oAxiosInstance from "config/Axios";

export const apiCallGetAllRoles = () => {
  return oAxiosInstance.get("seguridad/roles");
};

export const apiCallRolesDelete = nId => {
  return oAxiosInstance.delete(`seguridad/roles/${nId}`);
};

export const apiCallRolesFind = nId => {
  return oAxiosInstance.get(`seguridad/roles/unrol/${nId}`);
};

export const apiCallRolesAdd = (oData) => {
  return oAxiosInstance.post("seguridad/roles", oData);
};

export const apiCallRolesUpdate = (nId, oData) => {
  return oAxiosInstance.put(`seguridad/roles/${nId}`, oData);
};
