import oDayJs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CashRegisterCategoryMapper, CashRegisterMapper, CashRegisterMapperCurrentRegister, CashRegisterPaymentMethodMapper, CashRegisterUnMapper } from "./CashRegisterMapper";
import { apiCallCashRegisterGetCategories, apiCallCashRegisterGetCurrent, apiCallCashRegisterGetPaymentMethods, apiCallCashRegisterGetReport, apiCallCashRegisterReCalculate, apiCallCashRegisterSave, apiCallCashRegisterUpdate, apiCallGetAllCashRegisters } from "../api/CashRegisterServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { DATES_FORMATS } from "config/constants/Dates";
import { CURRENCY_VALUES } from "config/constants/Currency";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

export const actionCashRegisterGetCurrent = createAsyncThunk("cashRegister/getCurrent", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallCashRegisterGetCurrent();
    return CashRegisterMapperCurrentRegister(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterGetAll = createAsyncThunk("cashRegister/getAll", async(args, oThunk) => {
  try {
    const { CashRegisterSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      movcajaid: 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      rubid: oState.oCategory?.id || 0,
      monid: oState.oCurrency?.value || 0,
      fpid: oState.oPaymentMethod?.id || 0,
    };
    const oSuccess = await apiCallGetAllCashRegisters(oData);
    return { data: CashRegisterMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterSaveOrUpdate = createAsyncThunk("cashRegister/saveOrUpdate", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oMovement = CashRegisterUnMapper(oData);
    let oSuccess;
    if (oData.id)
      oSuccess = await apiCallCashRegisterUpdate(oData.id, oMovement);
    else
      oSuccess = await apiCallCashRegisterSave(oMovement);
    oThunk.dispatch(actionCashRegisterGetAll());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Movimiento generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterGetPaymentMethods = createAsyncThunk("cashRegister/getPaymentMethods", async(args, oThunk) => {
  try {
    const oSuccess = await apiCallCashRegisterGetPaymentMethods();
    return CashRegisterPaymentMethodMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterGetCategories = createAsyncThunk("cashRegister/getCategories", async(args, oThunk) => {
  try {
    const oSuccess = await apiCallCashRegisterGetCategories();
    return CashRegisterCategoryMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterReCalculate = createAsyncThunk("cashRegister/reCalculate", async(oParams, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oData = {
      fpid: oParams.idPaymentMethod,
      monid: oParams.idCurrency,
    };
    const oSuccess = await apiCallCashRegisterReCalculate(oData);
    oThunk.dispatch(actionCashRegisterGetCurrent());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Re calculo realizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionCashRegisterGetReport = createAsyncThunk("cashRegister/getReport", async(args, oThunk) => {
  try {
    const { CashRegisterSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      movcajaid: 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      rubid: oState.oCategory?.id || 0,
      monid: oState.oCurrency?.value || 0,
      fpid: oState.oPaymentMethod?.id || 0,
    };
    const oSuccess = await apiCallCashRegisterGetReport(oData);
    return oSuccess.data?.informe;

  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const oInitialState = {
  aRegisters: [],
  nPages: null,
  nPage: 1,
  nRecords: null,
  sFile: null,

  aCurrentCashRegisters: [],

  oCategory: null,
  oPaymentMethod: null,
  oCurrency: CURRENCY_VALUES[0],

  aPaymentMethods: [],
  aCategories: [],

  dDateTo: oDayJs().toString(),
  dDateFrom: oDayJs().toString(),
};

const CashRegisterSlice = createSlice({
  name: "CashRegisterSlice",
  initialState: oInitialState,
  reducers: {
    actionCashRegisterChangeCode: (oState, oAction) => {
      oState.sCode = oAction.payload;
    },
    actionCashRegisterChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionCashRegisterChangeStatus: (oState, oAction) => {
      oState.oStatus = oAction.payload;
    },
    actionCashRegisterChangeArticle: (oState, oAction) => {
      oState.oArticle = oAction.payload;
    },
    actionCashRegisterCleanMovements: (oState) => {
      oState.aMovements = [];
    },
    actionCashRegisterChangeDateFrom: (oState, oAction) => {
      oState.dDateFrom = oAction.payload;
    },
    actionCashRegisterChangeDateTo: (oState, oAction) => {
      oState.dDateTo = oAction.payload;
    },
    actionCashRegisterChangeCurrency: (oState, oAction) => {
      oState.oCurrency = oAction.payload;
    },
    actionCashRegisterChangeCategory: (oState, oAction) => {
      oState.oCategory = oAction.payload;
    },
    actionCashRegisterChangePaymentMethod: (oState, oAction) => {
      oState.oPaymentMethod = oAction.payload;
    },
    actionCashRegisterCleanFile: (oState) => {
      oState.sFile = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionCashRegisterGetCurrent.fulfilled, (oState, oAction) => {
      oState.aCurrentCashRegisters = oAction.payload;
    });
    builder.addCase(actionCashRegisterGetAll.fulfilled, (oState, oAction) => {
      oState.aRegisters = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionCashRegisterGetCategories.fulfilled, (oState, oAction) => {
      oState.aCategories = oAction.payload;
    });
    builder.addCase(actionCashRegisterGetPaymentMethods.fulfilled, (oState, oAction) => {
      oState.aPaymentMethods = oAction.payload;
    });
    builder.addCase(actionCashRegisterGetReport.fulfilled, (oState, oAction) => {
      oState.sFile = oAction.payload;
    });
  },
});

export const {
  actionCashRegisterChangeCode,
  actionCashRegisterChangePage,
  actionCashRegisterChangeStatus,
  actionCashRegisterChangeArticle,
  actionCashRegisterCleanMovements,
  actionCashRegisterChangeDateFrom,
  actionCashRegisterChangeDateTo,
  actionCashRegisterChangeCurrency,
  actionCashRegisterChangeCategory,
  actionCashRegisterChangePaymentMethod,
  actionCashRegisterCleanFile,
} = CashRegisterSlice.actions;

export const selectCashRegisterState = ({ CashRegisterSlice: oState }) => oState;

export default CashRegisterSlice.reducer;
