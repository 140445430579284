import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Highlighter from "react-highlight-words";

import { Button, ScreenContainer, Text, Input, Table } from "components";

import { CONSTANTS_COLORS } from "config/constants/Colors";
import { BUTTON_CONFIG } from "config/constants/Button";
import { actionFamiliesClean, actionFamiliesDelete, actionFamiliesGetAll, actionFamiliesSave, actionFamiliesUpdate, selectAllFamilies, selectStatusFamilies } from "pages/families/redux";
import ConfirmationModal from "components/ConfirmationModal";
import CustomModal from "components/CustomModal";
import { actionMessage } from "redux/shared/SharedSlice";

const FamiliesList = () => {
  const oDispatch = useDispatch();
  const aFamilies = useSelector(selectAllFamilies);
  const [sFilter, setFilter] = useState("");
  const [aFilteredFamilies, setFilteredFamilies] = useState([]);
  const bStatus = useSelector(selectStatusFamilies);

  const [bDelete, setDelete] = useState(false);
  const [oFamilyDelete, setFamilyDelete] = useState(null);

  const [bNew, setNew] = useState(false);
  const [sFamilyName, setFamilyName] = useState("");

  const [oFamilyUpdate, setFamilyUpdate] = useState(null);

  useEffect(() => {
    oDispatch(actionFamiliesGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bStatus) {
      onClose();
      oDispatch(actionFamiliesClean());
    }
  }, [bStatus]);

  useEffect(() => {
    setFilteredFamilies(() => {
      return aFamilies?.length
        ? aFamilies
          .filter(oFamily => oFamily.name?.toLowerCase().includes(sFilter.toLowerCase()))
          .map(oFamily => ({ ...oFamily, sFilter }))
        : [];
    });
  }, [aFamilies, sFilter]);

  const onDelete = oFamily => {
    setFamilyDelete(oFamily);
    setDelete(true);
  };

  const onConfirmDelete = () => {
    setDelete(false);
    setFamilyDelete(null);
    oDispatch(actionFamiliesDelete(oFamilyDelete.id));
  };

  const onClose = () => {
    setDelete(false);
    setFamilyDelete(null);
    setNew(false);
    setFamilyName("");
    setFamilyUpdate(null);
  };

  const onConfirm = () => {
    const oData = { name: sFamilyName.trim() };
    if (!oData.name) return oDispatch(actionMessage({ message: "Nombre requerido" }));
    if (oFamilyUpdate) return oDispatch(actionFamiliesUpdate({ ...oData, id: oFamilyUpdate.id }));
    oDispatch(actionFamiliesSave(oData));
  };

  const onUpdate = oFamily => {
    setFamilyUpdate(oFamily);
    setFamilyName(oFamily.name);
    setNew(true);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          Listado de familias
        </Text>
        <Button
          className="flex align-middle"
          containerClassName="ml-auto"
          label="Nueva"
          onClick={() => setNew(true)}
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Buscar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"familiesTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col scope="col" className="py-3 text-center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFilteredFamilies.length > 0 ? (
              aFilteredFamilies.map((oFamily, nIndex) => (
                <Table.Row
                  key={oFamily.id}
                  className="cursor-pointer"
                  onClick={() => onUpdate(oFamily)}
                >
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    <Highlighter
                      searchWords={[oFamily.sFilter]}
                      autoEscape={true}
                      textToHighlight={oFamily.name}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 text-center">
                    <div className="flex justify-center">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oFamily);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={3}>
                  <em>
                    {sFilter.length > 0
                      ? "No se encontraron resultados"
                      : "Sin familias registradas"}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar familia"
        question={`Está seguro que desea eliminar la familia "${oFamilyDelete?.name}"`}
        message="Esta acción no se puede revertir"
      />
      <CustomModal show={bNew} title={oFamilyUpdate ? `Editando familia "${oFamilyUpdate.name}"` : "Nombre de la familia"} className="w-96" onClose={onClose}>
        <p>
          {
            oFamilyUpdate
              ? "Nuevo nombre de la familia"
              : "Nombre de la familia"
          }
        </p>
        <Input
          value={sFamilyName}
          onChange={setFamilyName}
          placeholder="Nombre"
          containerClassName="mt-4"
          className="w-full"
        />
        <div className="mt-5 md:text-right flex justify-center md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            onClick={onClose}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
          />
          <Button
            label="Guardar"
            onClick={onConfirm}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
    </ScreenContainer>
  );
};

export default FamiliesList;
