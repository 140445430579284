export const ReceiptsMapper = aReceipts => {
  const mapReceipts = oServerReceipt => ({
    id: oServerReceipt.recId,
    serie: oServerReceipt.recSerie,
    code: oServerReceipt.recNro,
    date: oServerReceipt.recFch,
    hour: oServerReceipt.recHora,
    observations: oServerReceipt.recObs,
    total: oServerReceipt.recTotal,
    partner: {
      id: oServerReceipt.socId,
      name: oServerReceipt.socNom,
      rut: oServerReceipt.docSocRUT,
      address: oServerReceipt.docSocDir,
      state: oServerReceipt.docSocDptoNom,
    },
    type: {
      value: oServerReceipt.recTipo,
      name: oServerReceipt.recTipoNom,
    },
    // TODO: Quedan estos datos sin saber que son
    // recAnu
    currency: {
      id: oServerReceipt.monID,
      code: oServerReceipt.monSim,
      change: oServerReceipt.monTC,
    },
    cae: {
      status: +oServerReceipt.enviadoDGI ? true : false,
      number: oServerReceipt.caeNro,
      link: oServerReceipt.caeLinkVerif,
    },
    lines: ReceiptsLineMapper(oServerReceipt.lineas),
  });

  if (Array.isArray(aReceipts)) return aReceipts.map(mapReceipts);
  if ((typeof aReceipts).toString() === "object") return mapReceipts(aReceipts);
  return null;
};

export const ReceiptsUnMapper = aReceipts => {
  const unMapReceipts = oReceipt => ({
    recid: oReceipt.id || "",
    recserie: oReceipt.serie,
    socid: oReceipt.partner?.id,
    socnom: oReceipt.partner?.name,
    monid: oReceipt.currency?.id || null,
    MonSim: oReceipt.currency?.code || "",
    rectipo: oReceipt.type?.value,
    rectiponom: oReceipt.type?.label,
    RecAnu: 0,
    RecFch: oReceipt.date,
    rechora: oReceipt.hour,
    recobs: oReceipt.observations || "",
    rectotal: oReceipt.total || 0,
    serie: "",
    nro: "",
    envlink: "",
    lineas: ReceiptsLineUnMapper(oReceipt.lines),
    formadepago: ReceiptsLinePaymentMethodUnMapper(oReceipt.paymentMethods),
  });

  if (Array.isArray(aReceipts)) return aReceipts.map(unMapReceipts);
  if ((typeof aReceipts).toString() === "object") return unMapReceipts(aReceipts);
  return null;
};

export const ReceiptsLineMapper = aLines => {
  if (!aLines) return null;
  const mapReceiptsLine = (oServerLine, nIndex) => ({
    id: nIndex + 1,
    document: {
      id: oServerLine.docID,
      name: oServerLine.tipoDocNom,
    },
    amount: oServerLine.recImporte,
  });

  if (Array.isArray(aLines)) return aLines.map(mapReceiptsLine);
  if ((typeof aLines).toString() === "object") return mapReceiptsLine(aLines);
  return null;
};

export const ReceiptsLineUnMapper = aLines => {
  if (!aLines) return null;
  const mapOrdersLine = oLine => ({
    // TODO: Agregar esta línea mas adelante cuando se incorporen los tipos de línea
    // recTipo: oLine.type.value,
    recimporte: oLine.amount,
    docid: oLine.idDocument || "0",
  });

  if (Array.isArray(aLines)) return aLines.map(mapOrdersLine);
  if ((typeof aLines).toString() === "object") return mapOrdersLine(aLines);
  return null;
};

// export const ReceiptsLinePaymentMethodMapper = aLines => {
//   if (!aLines) return null;
//   const map = (oServerLine, nIndex) => ({
//     id: nIndex + 1,
//     document: {
//       id: oServerLine.docID,
//       name: oServerLine.tipoDocNom,
//     },
//     amount: oServerLine.recImporte,
//   });

//   if (Array.isArray(aLines)) return aLines.map(map);
//   if ((typeof aLines).toString() === "object") return map(aLines);
//   return null;
// };

export const ReceiptsLinePaymentMethodUnMapper = aLines => {
  if (!aLines) return null;
  const map = oLine => ({
    fpid: oLine.paymentMethod?.id,
    fpimporte: oLine.amount,
  });

  if (Array.isArray(aLines)) return aLines.map(map);
  if ((typeof aLines).toString() === "object") return map(aLines);
  return null;
};

export const ReceiptsCsvResponseMapper = oServerData => {
  const oTitles = oServerData;
  const aRecords = oServerData.lineas;
  delete oTitles.lineas;
  const aTitles = [];
  const oExample = aRecords[0] ?? null;
  if (!oExample)
    return {
      titles: aTitles,
      records: [],
    };
  const aKeys = Object.keys(oExample).map((key) => key);
  const aLabels = Object.keys(oTitles).map((key) => oTitles[key]);
  for (let nIndex = 0; nIndex < aKeys.length; nIndex++) {
    aTitles.push({
      key: aKeys[nIndex],
      label: aLabels[nIndex],
    });
  }
  return {
    headers: aTitles,
    records: aRecords,
  };
};
