import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "components/Autosuggest";

import PartnersLang from "pages/partners/lang";
import { actionPartnersCleanSuggestions, actionPartnersSuggest, selectSuggestionsPartners } from "pages/partners";

const PartnerSuggestions = ({
  onSelect,
  label: sLabel = null,
  className: sClassName,
  value: sValue = "",
  placeholder: sPlaceholder,
  disabled: bDisabled = false,
}) => {
  const oDispatch = useDispatch();
  const aSuggestions = useSelector(selectSuggestionsPartners);

  const onSuggestionsFetchRequested = (oEvent) => {
    oDispatch(actionPartnersSuggest(oEvent.value));
  };

  const onCleanSuggestions = () => oDispatch(actionPartnersCleanSuggestions());

  return <Autosuggest
    disabled={bDisabled}
    value={sValue}
    label={sLabel}
    onSelect={onSelect}
    className={sClassName}
    suggestions={aSuggestions}
    placeholder={sPlaceholder || PartnersLang.Labels.partner}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onCleanSuggestions}
  />;
};

export default PartnerSuggestions;
