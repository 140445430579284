import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { FamiliesMapper, FamiliesUnMapper } from "./FamiliesMapper";
import { apiCallFamiliesDelete, apiCallFamiliesSave, apiCallFamiliesUpdate, apiCallGetAllFamilies } from "../api/FamilyServices";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  aFamilies: [],
  bStatus: false,
  oFamily: null,
};

export const actionFamiliesGetAll = createAsyncThunk("families/getAllFamilies", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllFamilies();
    oThunk.dispatch(actionDismissLoading());
    return FamiliesMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionFamiliesDelete = createAsyncThunk("families/deleteFamilies", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallFamiliesDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Familia eliminada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionFamiliesGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionFamiliesSave = createAsyncThunk("families/saveFamily", async(oFamily, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oFamilyMapped = FamiliesUnMapper(oFamily);
    const oSuccess = await apiCallFamiliesSave(oFamilyMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Familia generada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionFamiliesGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionFamiliesUpdate = createAsyncThunk("families/updateFamilies", async(oFamily, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oFamilyMapped = FamiliesUnMapper(oFamily);
    const oSuccess = await apiCallFamiliesUpdate(oFamily.id, oFamilyMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Familia actualizada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionFamiliesGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const FamiliesSlice = createSlice({
  name: "FamiliesSlice",
  initialState: oInitialState,
  reducers: {
    actionFamiliesClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionFamiliesGetAll.fulfilled, (oState, oAction) => {
      oState.aFamilies = oAction.payload;
    });
    builder.addCase(actionFamiliesSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionFamiliesUpdate.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
  },
});

export const { actionFamiliesClean } = FamiliesSlice.actions;

export default FamiliesSlice.reducer;
