import { useContext, useEffect } from "react";
import { toast, Zoom } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { selectMessage } from "redux/shared/SharedSelector";
import { SharedContext } from "context/SharedContext";
import { actionCleanMessage } from "redux/shared/SharedSlice";

const CONFIG_MESSAGE = {
  position: "bottom-right",
  autoClose: 5000,
  // icon: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Toast = () => {
  const oDispatch = useDispatch();
  const { bDarkTheme } = useContext(SharedContext);

  const oMessage = useSelector(selectMessage);

  useEffect(() => {
    if (oMessage.message) {
      toast(oMessage.message, {
        ...CONFIG_MESSAGE,
        type: oMessage.type,
        theme: bDarkTheme ? "dark" : "light",
        transition: Zoom,
      });
      oDispatch(actionCleanMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oMessage.message]);

  return null;
};

export default Toast;
