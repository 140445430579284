export const TasksMapper = aTasks => {
  const mapTasks = oServerTask => ({
    id: oServerTask.tarId,
    name: oServerTask.tarNombre,
    label: oServerTask.tarNombre,
  });

  if (Array.isArray(aTasks)) return aTasks.map(mapTasks);
  if ((typeof aTasks).toString() === "object") return mapTasks(aTasks);
  return null;
};
