export const ProductionMapper = aItems => {
  const map = oItem => ({
    id: oItem.prodId,
    date: oItem.prodFch,
    quantity: oItem.prodCntFab,
    procedure: oItem.prodProcArmado,
    lot: {
      id: oItem.loteId,
      name: oItem.loteCod,
      value: oItem.loteId,
      label: oItem.loteCod,
    },
    status: {
      id: oItem.estProdId,
      name: oItem.estProdNom,
      value: oItem.estProdId,
      label: oItem.estProdNom,
    },
    article: {
      id: oItem.artId,
      name: oItem.artNom,
      value: oItem.artId,
      label: oItem.artNom,
    },
    formula: ProductionFormulaMapper(oItem.lineas),
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};

export const ProductionUnMapper = (aItems) => {
  const map = oItem => ({
    ProdId: oItem.id ?? 0,
    ProdFch: oItem.date,
    ArtId: oItem.article?.id,
    LoteId: oItem.lot?.id ?? "",
    ProdCntFab: oItem.quantity,
    EstProdId: oItem.status?.id ?? 1,
    ProdProcArmado: oItem.procedure,
    lineas: ProductionFormulaUnMapper(oItem.formula),
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};

export const ProductionStatusMapper = aItems => {
  const map = oItem => ({
    id: oItem.estProdId,
    value: oItem.estProdId,
    name: oItem.estProdNom,
    label: oItem.estProdNom,
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};

export const ProductionFormulaMapper = aItems => {
  const map = oItem => ({
    article: {
      id: oItem.artId,
      name: oItem.artNom,
    },
    quantity: oItem.cantidad || oItem.prodLinFormCnt,
    relative: oItem.relativa || oItem.prodLinFormRel,
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};

export const ProductionFormulaUnMapper = (aItems) => {
  const map = oItem => ({
    artID: oItem.article?.id || 0,
    ProdLinFormCnt: oItem.quantity,
    ProdLinFormRel: oItem.relative,
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};
