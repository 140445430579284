import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  listRoles: "Listado de roles",
  createRol: "Crear rol",
  editRol: "Editar rol",
  selectTask: "Seleccionar tarea",
  emptyRoles: "Sin roles registrados",
  deleteRole: "Eliminar rol",
};

export default Labels;
