import SysValidations from "config/lang/SysValidations";

const Validations = {
  ...SysValidations,
  articleRequired: "El artículo es requerido",
  linesRequired: "Se require al menos una línea",
  lineTypeRequired: "Se require un tipo de línea",
  amountEnough: "El importe debe ser mayor a cero",
  documentRefRequired: "Se necesita una referencia a documento",
  onlyOneAccountLine: "Solo puede haber una línea de pago a cuenta",
};

export default Validations;
