import React from "react";
import { CONSTANTS_TEXT_STYLE } from "config/constants/Themes";

const Text = ({
  children: sText,
  size: sSize = "",
  className: sCustomClass = "",
  color: sColor = null,
  bold: bIsBold = false,
  italic: bIsItalic = false,
  style: nStyle = CONSTANTS_TEXT_STYLE.DEFAULT,
  simple: bSimple = false,
}) => {
  if (bSimple) return <p className={sCustomClass}>{sText}</p>;

  let aClassNames = [];

  switch (nStyle) {
    case CONSTANTS_TEXT_STYLE.LIGHT:
      aClassNames = ["text-white dark:text-gray-200"];
      break;
    case CONSTANTS_TEXT_STYLE.DARK:
      aClassNames = ["text-gray-800 dark:text-gray-400"];
      break;
    case CONSTANTS_TEXT_STYLE.DEFAULT:
    default:
      aClassNames = ["text-gray-500 dark:text-gray-300"];
      break;
  }

  if (sColor) aClassNames[0] = sColor;
  if (sSize) aClassNames.push(sSize);
  if (bIsItalic) aClassNames.push("italic");
  if (bIsBold) aClassNames.push("font-bold");
  if (sCustomClass) aClassNames.push(sCustomClass);

  return <p className={aClassNames.join(" ")}>{sText}</p>;
};

export default Text;
