import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { BiCalculator, BiFile } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { SysValidations } from "config/lang";
import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { ScreenContainer, Text, Input, Table, Button, CustomModal, Checkbox, ConfirmationModal } from "components";

import {
  actionPricesClean,
  actionPricesDelete,
  actionPricesGetAll,
  actionPricesRecalculate,
  actionPricesSave,
  actionPricesUpdate,
  selectAllPrices,
  selectPricesStatus,
} from "pages/prices/redux";
import { actionMessage } from "redux/shared/SharedSlice";

const PricesList = () => {
  const oDispatch = useDispatch();
  const onNavigate = useNavigate();
  const aPrices = useSelector(selectAllPrices);
  const bStatus = useSelector(selectPricesStatus);
  const [sFilter, setFilter] = useState("");
  const [aFilteredPrices, setFilteredPrices] = useState([]);

  const [oPrice, setPrice] = useState(null);
  const [sValue, setValue] = useState("");
  const [bRounded, setRounded] = useState(true);

  const [bDelete, setDelete] = useState(false);
  const [oDeleteItem, setDeleteItem] = useState(null);

  const [bNew, setNew] = useState(false);
  const [sName, setName] = useState("");
  const [oUpdateItem, setUpdateItem] = useState(null);

  useEffect(() => {
    oDispatch(actionPricesGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredPrices(() => {
      return aPrices?.length
        ? aPrices
          .filter(oPrice => (oPrice.name?.toLowerCase().includes(sFilter.toLowerCase()) || oPrice.stateName?.toLowerCase().includes(sFilter.toLowerCase())))
          .map(oPrice => ({ ...oPrice, sFilter }))
        : [];
    });
  }, [aPrices, sFilter]);

  useEffect(() => {
    if (bStatus) {
      onClose();
      oDispatch(actionPricesClean());
    }
  }, [bStatus]);

  const onRecalculate = (oItem) => setPrice(oItem);

  const onConfirmChange = () => {
    if (!sValue)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Porcentaje") }));
    const nValue = +sValue.trim();
    if (Number.isNaN(nValue) || nValue === 0 || nValue > 100 || nValue < -100)
      return oDispatch(actionMessage({ message: SysValidations.fieldInvalid("Porcentaje") }));
    oDispatch(actionPricesRecalculate({ id: oPrice.id, value: nValue, rounded: bRounded }));
    setValue("");
    setRounded(true);
    setPrice(null);
  };

  const onDelete = oItem => {
    setDeleteItem(oItem);
    setDelete(true);
  };

  const onConfirmDelete = () => {
    setDelete(false);
    setDeleteItem(null);
    oDispatch(actionPricesDelete(oDeleteItem.id));
  };

  const onClose = () => {
    setNew(false);
    setName("");
    setUpdateItem(null);
  };

  const onConfirm = () => {
    const oData = { name: sName.trim(), id: oUpdateItem?.id || undefined };
    if (!oData.name) return oDispatch(actionMessage({ message: "Nombre requerido" }));
    if (oUpdateItem) return oDispatch(actionPricesUpdate(oData));
    oDispatch(actionPricesSave(oData.name));
  };

  const onUpdate = oItem => {
    setUpdateItem(oItem);
    setName(oItem.name);
    setNew(true);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Listado de precios
        </Text>
        <Button
          label="Nuevo"
          onClick={() => setNew(true)}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Buscar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"PricesTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFilteredPrices.length > 0 ? (
              aFilteredPrices.map((oPrice, nIndex) => (
                <Table.Row key={oPrice.id} onClick={() => onUpdate(oPrice)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    <Highlighter
                      searchWords={[oPrice.sFilter]}
                      autoEscape={true}
                      textToHighlight={oPrice.name}
                    />
                  </Table.Col>
                  <Table.Col>
                    <div className="flex justify-end gap-1 sm:gap-3 pr-3">
                      <Button
                        size={BUTTON_CONFIG.SIZE.SM}
                        icon={<BiCalculator size={16} />}
                        type={BUTTON_CONFIG.CLASS.OUTLINE}
                        onClick={(e) => {
                          e.stopPropagation();
                          onRecalculate(oPrice);
                        }}
                      />
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oPrice);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onNavigate(`report/${oPrice.id}`);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.SUCCESS}
                        icon={<BiFile size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={3}>
                  <em>
                    {sFilter.length > 0
                      ? "No se encontraron resultados"
                      : "Sin lista de precios registradas"}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <CustomModal show={!!oPrice} title={`Cambio porcentual (${oPrice?.name})`} className="w-auto" onClose={() => setPrice(null)}>
        <Input
          value={sValue}
          placeholder="10%"
          className="w-full"
          onChange={setValue}
          containerClassName="mt-3"
          label="Porcentaje a cambiar"
        />
        <span className="text-xs">
          Nota: Si el ajuste de precios es un aumento, el porcentaje es un valor positivo, si el ajuste es un descuento el importe debe ser negativo
        </span>
        <Checkbox
          label="Redondeo"
          checked={bRounded}
          containerClassName="flex-1 mb-0 pt-8"
          onChange={() => setRounded(!bRounded)}
        />
        <div className="mt-5 md:text-right flex justify-center md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            size={BUTTON_CONFIG.SIZE.SM}
            onClick={() => setPrice(null)}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
          />
          <Button
            label="Guardar"
            onClick={onConfirmChange}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
      <CustomModal
        show={bNew}
        className="w-96"
        onClose={onClose}
        title={oUpdateItem ? `Editando lista "${oUpdateItem.name}"` : "Nueva lista"}
      >
        <p>{oUpdateItem ? "Nuevo nombre" : "Nombre de la lista"}</p>
        <Input
          value={sName}
          onChange={setName}
          className="w-full"
          placeholder="Nombre"
          containerClassName="mt-2"
        />
        <div className="mt-5 md:text-right flex justify-center md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            onClick={onClose}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
          />
          <Button
            label="Guardar"
            onClick={onConfirm}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar lista"
        question={`Está seguro que desea eliminar la lista "${oDeleteItem?.name}"`}
        message="Esta acción no se puede revertir"
      />
    </ScreenContainer>
  );
};

export default PricesList;
