import oMeta from "meta.json";

export const SYSTEM_CONSTANTS = {
  APP_VERSION: "v1.0",
  APP_DATE: oMeta?.date,
  API_URL: process.env.REACT_APP_API_URL,
  USER_MANUAL_URL: process.env.REACT_APP_USER_MANUAL_URL,
  APP_THEME_KEY: "@Bonsai:DarkTheme",
  APP_TOKEN_KEY: "@Bonsai:Token",
  APP_SESSION_STORAGE_KEY: "@Bonsai:Session",
};
