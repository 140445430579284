const Fields = {
  code: "Código",
  emptyCode: "Sin código asociado",
  status: "Estado",
  name: "Nombre / Denominación",
  businessName: "Razón social",
  centralHouse: "Casa central",
  phone: "Teléfono",
  rut: "R.U.T.",
  documentType: "Tipo de documento",
  document: "Documento",
  address: "Dirección",
  zip: "Código postal",
  location: "Localidad",
  phone1: "Teléfono 1",
  phone2: "Teléfono 2",
  email: "Email",
  url: "Web",
  observations: "Observaciones",
  isClient: "Es Cliente",
  isProvider: "Es Proveedor",
  isTaxed: "Es Gravado",
  priceList: "Lista de precios",
  creditBalance: "Acreedor (UYU)",
  creditBalanceUsd: "Acreedor (USD)",
  dueBalance: "Deudor (UYU)",
  dueBalanceUsd: "Deudor (USD)",
  emptyCredit: "-",
  emptyDue: "-",
  date: "Fecha",
};

export default Fields;
