import React, { useEffect, useState } from "react";
import { BiCalculator } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { Text, Table, ScreenContainer, Button, ConfirmationModal } from "components";

import { selectCashRegisterState, actionCashRegisterGetCurrent, actionCashRegisterReCalculate } from "./redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";

const CurrentCashRegister = () => {
  const oDispatch = useDispatch();

  const [oItemToReCalculate, setItemToReCalculate] = useState(null);

  const { aCurrentCashRegisters } = useSelector(selectCashRegisterState);

  useEffect(() => {
    oDispatch(actionCashRegisterGetCurrent());
  }, []);

  const onShowReCalculate = (oItem) => {
    setItemToReCalculate(oItem);
  };

  const onReCalculate = () => {
    const oData = {
      idPaymentMethod: oItemToReCalculate?.paymentMethod?.id,
      idCurrency: oItemToReCalculate?.currency?.id,
    };
    oDispatch(actionCashRegisterReCalculate(oData));
    setItemToReCalculate(null);
  };

  return (
    <ScreenContainer>
      <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
        Caja actual
      </Text>
      <section className="py-5">
        <Table data-testid={"DocumentsTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Forma de pago
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Moneda
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-right">
                Importe
              </Table.Col>
              <Table.Col scope="col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aCurrentCashRegisters?.length > 0 ? (
              aCurrentCashRegisters.map((oItem, index) => (
                <Table.Row key={`${oItem.id}_${index}`}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {oItem.paymentMethod?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {oItem.currency?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-right">
                    {oItem.amount}
                  </Table.Col>
                  <Table.Col>
                    <Button
                      size={BUTTON_CONFIG.SIZE.SM}
                      type={BUTTON_CONFIG.CLASS.SUCCESS}
                      onClick={() => onShowReCalculate(oItem)}
                      containerClassName="flex items-end justify-center"
                      icon={<BiCalculator size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                    />
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={8}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        onConfirm={onReCalculate}
        show={oItemToReCalculate !== null}
        title="Recalcular caja actual"
        onClose={() => setItemToReCalculate(null)}
        message="Esta acción puede tardar unos minutos"
        question={`Está seguro que desea re calcular la caja actual "${oItemToReCalculate?.paymentMethod?.name ?? ""}"`}
      />
    </ScreenContainer>
  );
};

export default CurrentCashRegister;
