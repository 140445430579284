import oAxiosInstance from "config/Axios";

export const apiCallGetAllProductions = oData => {
  return oAxiosInstance.post("produccion/consultar", oData);
};

export const apiCallProductionsFind = nId => {
  return oAxiosInstance.get(`produccion/consultarCompleto/${nId}`);
};

export const apiCallProductionsSave = (oData) => {
  return oAxiosInstance.post("produccion", oData);
};

export const apiCallProductionsGetStatus = () => {
  return oAxiosInstance.get("produccion/estadoproducciones");
};

export const apiCallProductionsGetReport = (nId) => {
  return oAxiosInstance.get(`produccion/informeproduccion/${nId}`);
};

export const apiCallProductionsGetProcedure = (nId) => {
  return oAxiosInstance.get(`produccion/procedimientofabricacion/${nId}`);
};

export const apiCallProductionsGetFormula = (oData) => {
  return oAxiosInstance.post("produccion/formularelativa", oData);
};

export const apiCallProductionsIsAvailableToClose = nId => {
  return oAxiosInstance.get(`produccion/puedecerrarproduccion/${nId}`);
};

export const apiCallProductionsGetLineLots = oData => {
  return oAxiosInstance.post("produccion/consultarLotesLinea", oData);
};

export const apiCallProductionsRemoveLineLot = oData => {
  return oAxiosInstance.post("produccion/quitarlotelineaproduccion", oData);
};

export const apiCallProductionsAddLineLot = oData => {
  return oAxiosInstance.post("produccion/agregarlotelineaproduccion", oData);
};

export const apiCallProductionsClose = nId => {
  return oAxiosInstance.get(`produccion/cerrarproduccion/${nId}`);
};

export const apiCallProductionsUpdate = (nId, oData) => {
  return oAxiosInstance.put(`produccion/${nId}`, oData);
};
