import oAxiosInstance from "config/Axios";

export const apiCallCashRegisterGetCurrent = () => {
  return oAxiosInstance.get("caja/actual");
};

export const apiCallGetAllCashRegisters = oData => {
  return oAxiosInstance.post("caja/consultar", oData);
};

export const apiCallCashRegisterSave = oData => {
  return oAxiosInstance.post("caja/nuevomovimiento", oData);
};

export const apiCallCashRegisterUpdate = (id, oData) => {
  return oAxiosInstance.put(`caja/${id}`, oData);
};

export const apiCallCashRegisterGetPaymentMethods = () => {
  return oAxiosInstance.get("caja/formasdepago");
};

export const apiCallCashRegisterGetCategories = () => {
  return oAxiosInstance.get("caja/rubros");
};

export const apiCallCashRegisterReCalculate = oData => {
  return oAxiosInstance.post("caja/recalculo", oData);
};

export const apiCallCashRegisterGetReport = oData => {
  return oAxiosInstance.post("caja/diario", oData);
};
