import oDayJs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ProductionFormulaMapper, ProductionMapper, ProductionStatusMapper, ProductionUnMapper } from "./ProductionMapper";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { DATES_FORMATS } from "config/constants/Dates";
import { apiCallGetAllProductions, apiCallProductionsAddLineLot, apiCallProductionsClose, apiCallProductionsFind, apiCallProductionsGetFormula, apiCallProductionsGetLineLots, apiCallProductionsGetProcedure, apiCallProductionsGetReport, apiCallProductionsGetStatus, apiCallProductionsIsAvailableToClose, apiCallProductionsRemoveLineLot, apiCallProductionsSave, apiCallProductionsUpdate } from "../api/ProductionServices";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

export const actionProductionsGetAll = createAsyncThunk("production/getAll", async(args, oThunk) => {
  try {
    const { ProductionSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      ProdId: 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      ArtId: oState.oArticle?.id || 0,
      LoteId: oState.oLot?.id || "",
      estProdId: oState.oStatus?.id || 0,
    };
    const oSuccess = await apiCallGetAllProductions(oData);
    return { data: ProductionMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsFind = createAsyncThunk("production/find", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsFind(nId);
    oThunk.dispatch(actionDismissLoading());
    return ProductionMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionProductionsGetStatus = createAsyncThunk("production/getStatus", async(args, oThunk) => {
  try {
    const oSuccess = await apiCallProductionsGetStatus();
    return ProductionStatusMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsGetReport = createAsyncThunk("production/getReport", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsGetReport(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Informe generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess?.data?.informe;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsGetProcedure = createAsyncThunk("production/getProcedure", async(nId, oThunk) => {
  try {
    const oSuccess = await apiCallProductionsGetProcedure(nId);
    return oSuccess?.data?.artProcArmado;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionProductionsGetFormula = createAsyncThunk("production/getFormula", async(oData, oThunk) => {
  try {
    const oSuccess = await apiCallProductionsGetFormula({
      ArtId: oData.idArticle,
      CantAProducir: oData.quantity,
    });
    return ProductionFormulaMapper(oSuccess?.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionProductionsSave = createAsyncThunk("production/save", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsSave(ProductionUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Producción generada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsUpdate = createAsyncThunk("production/update", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsUpdate(oData.id, ProductionUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Producción actualizada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionProductionsFind(oData.id));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsIsAvailableToClose = createAsyncThunk("production/isAvailableToClose", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsIsAvailableToClose(nId);
    oThunk.dispatch(actionDismissLoading());
    return oSuccess.message;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsGetLineLots = createAsyncThunk("production/getLineLots", async(oData, oThunk) => {
  try {
    const oSuccess = await apiCallProductionsGetLineLots({ ProdId: oData.id, ArtId: oData.idArticle });
    return {
      idArticle: oData.idArticle,
      data: oSuccess.data.map(item => ({ lot: { id: item.loteId, code: item.loteCod }, quantity: item.prodLoteCnt })),
    };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsRemoveLineLot = createAsyncThunk("production/removeLineLot", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsRemoveLineLot({ ProdId: oData.id, ArtId: oData.idArticle, LoteId: oData.idLot });
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lote eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionProductionsGetLineLots(oData));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsAddLineLot = createAsyncThunk("production/addLineLot", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsAddLineLot(
      {
        ProdId: oData.id,
        ArtId: oData.idArticle,
        LoteId: oData.idLot,
        Cantidad: oData.quantity,
      },
    );
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lote agregado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionProductionsGetLineLots(oData));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionProductionsClose = createAsyncThunk("production/close", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallProductionsClose(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Producción cerrada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(actionProductionsFind(nId));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const oInitialState = {
  aProductions: [],
  nPages: null,
  nPage: 1,
  nRecords: null,
  bStatus: false,
  oProduction: null,
  sCloseMessage: "",

  aStatus: [],

  aLots: null,

  oArticle: null,
  oLot: null,
  oStatus: null,
  sFile: null,
  sProcedure: "",
  aFormula: [],

  dDateTo: oDayJs().toString(),
  dDateFrom: oDayJs().subtract(1, DATES_FORMATS.CONSTANTS.UNITS.MONTHS).toString(),
};

const ProductionSlice = createSlice({
  name: "ProductionSlice",
  initialState: oInitialState,
  reducers: {
    actionProductionsClean: (oState) => {
      oState.bStatus = false;
      oState.sProcedure = "";
      oState.aFormula = [];
      oState.oProduction = null;
      oState.sCloseMessage = "";
      oState.aLots = null;
    },
    actionProductionsChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionProductionsChangeArticle: (oState, oAction) => {
      oState.oArticle = oAction.payload;
    },
    actionProductionsChangeDateFrom: (oState, oAction) => {
      oState.dDateFrom = oAction.payload;
    },
    actionProductionsChangeDateTo: (oState, oAction) => {
      oState.dDateTo = oAction.payload;
    },
    actionProductionsChangeLot: (oState, oAction) => {
      oState.oLot = oAction.payload;
    },
    actionProductionsChangeStatus: (oState, oAction) => {
      oState.oStatus = oAction.payload;
    },
    actionProductionsCleanFile: (oState) => {
      oState.sFile = null;
    },
    actionProductionsCleanProcedure: (oState) => {
      oState.sProcedure = "";
    },
    actionProductionsCleanFormula: (oState) => {
      oState.aFormula = [];
    },
    actionProductionsCleanCloseMessage: (oState) => {
      oState.sCloseMessage = "";
    },
  },
  extraReducers: builder => {
    builder.addCase(actionProductionsGetAll.fulfilled, (oState, oAction) => {
      oState.aProductions = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionProductionsGetStatus.fulfilled, (oState, oAction) => {
      oState.aStatus = oAction.payload;
    });
    builder.addCase(actionProductionsGetReport.fulfilled, (oState, oAction) => {
      oState.sFile = oAction.payload;
    });
    builder.addCase(actionProductionsGetProcedure.fulfilled, (oState, oAction) => {
      oState.sProcedure = oAction.payload;
    });
    builder.addCase(actionProductionsGetFormula.fulfilled, (oState, oAction) => {
      oState.aFormula = oAction.payload;
    });
    builder.addCase(actionProductionsSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionProductionsFind.fulfilled, (oState, oAction) => {
      oState.oProduction = oAction.payload;
    });
    builder.addCase(actionProductionsIsAvailableToClose.fulfilled, (oState, oAction) => {
      oState.sCloseMessage = oAction.payload;
    });
    builder.addCase(actionProductionsGetLineLots.fulfilled, (oState, oAction) => {
      if (oState.aLots)
        oState.aLots[oAction.payload.idArticle] = oAction.payload.data;
      else
        oState.aLots = {
          [oAction.payload.idArticle]: oAction.payload.data,
        };
    });
  },
});

export const {
  actionProductionsChangePage,
  actionProductionsChangeArticle,
  actionProductionsChangeDateFrom,
  actionProductionsChangeDateTo,
  actionProductionsChangeLot,
  actionProductionsChangeStatus,
  actionProductionsCleanFile,
  actionProductionsCleanProcedure,
  actionProductionsCleanFormula,
  actionProductionsClean,
  actionProductionsCleanCloseMessage,
} = ProductionSlice.actions;

export const selectProductionsState = ({ ProductionSlice: oState }) => oState;

export default ProductionSlice.reducer;
