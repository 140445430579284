export const SellersMapper = aItems => {
  const mapLots = oServerItem => ({
    id: oServerItem.venId,
    name: oServerItem.venNom,
    commission: oServerItem.comPorc,
  });
  if (!aItems) return null;
  if (Array.isArray(aItems)) return aItems.map(mapLots);
  if ((typeof aItems).toString() === "object") return mapLots(aItems);
  return null;
};

export const SellersUnMapper = aItems => {
  const unMapLots = oItem => ({
    venId: oItem.id || 0,
    venNom: oItem.name,
    comPorc: oItem.commission,
  });

  if (Array.isArray(aItems)) return aItems.map(unMapLots);
  if ((typeof aItems).toString() === "object") return unMapLots(aItems);
  return null;
};
