export const RolesMapper = aRoles => {
  const mapRoles = oServerRol => ({
    id: oServerRol.rolId,
    name: oServerRol.rolNombre,
    label: oServerRol.rolNombre,
    tasks: oServerRol.tareas?.map(item => ({ id: item.tarId, name: item.tarNombre })),
  });

  if (Array.isArray(aRoles)) return aRoles.map(mapRoles);
  if ((typeof aRoles).toString() === "object") return mapRoles(aRoles);
  return null;
};

export const RolesUnMapper = aRoles => {
  const unMapRoles = oRole => ({
    rolId: oRole.id || 0,
    rolNombre: oRole.name,
    lasTareas: oRole?.tasks?.map(item => ({ tarId: item.id })),
  });

  if (Array.isArray(aRoles)) return aRoles.map(unMapRoles);
  if ((typeof aRoles).toString() === "object") return unMapRoles(aRoles);
  return null;
};
