
export const DOCUMENT_STATUS_CODES = {
  NONE: "0",
  OPEN: "1",
  CLOSE: "2",
  CANCELLED: "3",
};

export const DOCUMENT_STATUS_VALUES = [
  { label: "Todos", value: DOCUMENT_STATUS_CODES.NONE },
  { label: "Abierto", value: DOCUMENT_STATUS_CODES.OPEN },
  { label: "Cerrado", value: DOCUMENT_STATUS_CODES.CLOSE },
  { label: "Cancelado", value: DOCUMENT_STATUS_CODES.CANCELLED },
];

export const DOCUMENT_TAX_STATUS_CODES = {
  NO_SENT: 0,
  SENT: 1,
  ALL: 2,
};

export const DOCUMENT_TAX_STATUS_VALUES = [
  { label: "Todos", value: DOCUMENT_TAX_STATUS_CODES.ALL },
  { label: "Enviados", value: DOCUMENT_TAX_STATUS_CODES.SENT },
  { label: "Pendientes de envío", value: DOCUMENT_TAX_STATUS_CODES.NO_SENT },
];
