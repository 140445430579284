import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";

import {
  Text,
  Input,
  Table,
  Button,
  ComboBox,
  DateInput,
  CustomModal,
  ScreenContainer,
  ConfirmationModal,
  ArticlesSuggestions,
} from "components";

import { SysValidations } from "config/lang";
import { DATES_FORMATS } from "config/constants/Dates";
import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";

import { CastDate, SubtractDate } from "utils/SharedUtils";

import { actionMessage } from "redux/shared/SharedSlice";
import {
  selectLotsState,
  actionLotsDelete,
  actionLotsGetAll,
  actionLotsChangeCode,
  actionLotsChangePage,
  actionLotsChangeStatus,
  actionLotsSaveOrUpdate,
  actionLotsChangeArticle,
  actionLotsGetStatusList,
  actionLotsReCalculate,
  actionLotsGetMovements,
  actionLotsCleanMovements,
  actionLotsGetMovementsTypes,
  actionLotsSaveMovement,
} from "./redux";
import { BiArrowBack, BiCalculator } from "react-icons/bi";
import { TbSquarePlus } from "react-icons/tb";

const LotsList = () => {
  const oDispatch = useDispatch();

  const [oLotToDelete, setLotToDelete] = useState(null);
  const [bIsDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [oLotToReCalculate, setLotToReCalculate] = useState(null);
  const [bIsReCalculateModalOpen, setIsReCalculateModalOpen] = useState(false);

  const [bIsAddModalOpen, setIsAddModalOpen] = useState(false);
  const [bIsUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [bIsMovementsModalOpen, setIsMovementsModalOpen] = useState(false);
  const [sLotId, setLotId] = useState(null);
  const [sModalCode, setModalCode] = useState("");
  const [sModalStock, setModalStock] = useState("");
  const [oModalStatus, setModalStatus] = useState(null);
  const [oModalArticle, setModalArticle] = useState(null);
  const [dModalAdmission, setModalAdmission] = useState(new Date());
  const [dModalExpiration, setModalExpiration] = useState(SubtractDate(new Date(), -6, DATES_FORMATS.CONSTANTS.UNITS.MONTHS));

  const [bIsAddMovement, setIsAddMovement] = useState(false);
  const [sMovementRef, setMovementRef] = useState("");
  const [oMovementType, setMovementType] = useState(null);
  const [dMovementDate, setMovementDate] = useState(new Date());
  const [sMovementQuantity, setMovementQuantity] = useState("");

  const {
    aLots,
    nPage,
    sCode,
    nPages,
    oStatus,
    aStatus,
    nRecords,
    aMovements,
    aMovementTypes,
  } = useSelector(selectLotsState);

  useEffect(() => {
    oDispatch(actionLotsGetAll());
  }, []);

  useEffect(() => {
    if (aStatus.length === 0)
      oDispatch(actionLotsGetStatusList());
  }, [aStatus]);

  useEffect(() => {
    if (aMovementTypes.length === 0)
      oDispatch(actionLotsGetMovementsTypes());
  }, [aMovementTypes]);

  const onChangeTimeout = (oState, oData) => {
    if (!oData) { return oState; }
    if (oState) { clearTimeout(oState); }
    return oData;
  };

  const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionLotsChangePage(nSelectedPage));
    oDispatch(actionLotsGetAll());
  };

  const onChangeCode = (sNewCode) => {
    oDispatch(actionLotsChangeCode(sNewCode.trim()));
    setTimer(setTimeout(() => {
      oDispatch(actionLotsChangePage(1));
      oDispatch(actionLotsGetAll());
    }, 800));
  };

  const onChangeArticle = (oArticle) => {
    oDispatch(actionLotsChangeArticle(oArticle));
    oDispatch(actionLotsChangePage(1));
    oDispatch(actionLotsGetAll());
  };

  const onChangeStatus = (oStatus) => {
    oDispatch(actionLotsChangeStatus(oStatus));
    oDispatch(actionLotsChangePage(1));
    oDispatch(actionLotsGetAll());
  };

  const onConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    oDispatch(actionLotsDelete(oLotToDelete.id));
    setLotToDelete(null);
  };

  const onConfirmReCalculate = () => {
    setIsReCalculateModalOpen(false);
    oDispatch(actionLotsReCalculate(oLotToReCalculate.id));
    setLotToReCalculate(null);
  };

  const onCloseModal = () => {
    setIsAddMovement(false);
    setIsAddModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsMovementsModalOpen(false);
    setLotId(null);
    setModalCode("");
    setModalStock("");
    setMovementRef("");
    setModalStatus(null);
    setMovementType(null);
    setModalArticle(null);
    setMovementQuantity("");
    setMovementDate(new Date());
    setModalAdmission(new Date());
    setModalExpiration(SubtractDate(new Date(), -6, DATES_FORMATS.CONSTANTS.UNITS.MONTHS));
    oDispatch(actionLotsCleanMovements());
  };

  const onOpenMovements = () => {
    setIsUpdateModalOpen(false);
    oDispatch(actionLotsGetMovements(sLotId));
    setIsMovementsModalOpen(true);
  };

  const onAdd = () => {
    setModalStatus(aStatus.find(item => item.value === 2) || null);
    setIsAddModalOpen(true);
  };

  const onSelect = (oLot) => {
    setLotId(oLot.id);
    setModalCode(oLot.code);
    setModalStock(oLot.stock);
    setModalStatus(oLot.status);
    setModalArticle(oLot.article);
    setModalAdmission(oLot.admission);
    setModalExpiration(oLot.expiration);
    setIsUpdateModalOpen(true);
  };

  const onSubmit = () => {
    const oData = {
      id: bIsUpdateModalOpen ? sLotId : "",
      stock: 0,
      status: oModalStatus,
      article: oModalArticle,
      code: sModalCode.trim(),
      admission: CastDate(dModalAdmission, DATES_FORMATS.MYSQL_SERVER_TIMESTAMPS_DEFAULT),
      expiration: CastDate(dModalExpiration, DATES_FORMATS.MYSQL_SERVER_TIMESTAMPS_DEFAULT),
    };
    if (bIsAddModalOpen && !oData.article?.id)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Artículo") }));
    if (bIsAddModalOpen && !oData.status?.id)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Estado") }));
    oDispatch(actionLotsSaveOrUpdate(oData));
    onCloseModal();
  };

  const onBack = () => {
    setIsMovementsModalOpen(false);
    setIsUpdateModalOpen(true);
  };

  const onSubmitMovement = () => {
    const oData = {
      idLot: sLotId,
      type: oMovementType,
      quantity: +sMovementQuantity,
      document: sMovementRef.trim(),
      date: CastDate(dMovementDate, DATES_FORMATS.MYSQL_SERVER),
    };
    if (!oData.type?.id)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Tipo") }));
    if (Number.isNaN(oData.quantity))
      return oDispatch(actionMessage({ message: SysValidations.fieldInvalid("Cantidad") }));
    oDispatch(actionLotsSaveMovement(oData));
    onCloseModal();
  };

  const onRenderModalContent = () => {
    if (bIsAddMovement)
      return <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <ComboBox
            label="Tipo"
            selected={oMovementType}
            options={aMovementTypes}
            onChange={setMovementType}
            emptyLabel="Tipo de movimiento"
          />
          <DateInput
            label="Fecha"
            labelClass="mb-1"
            placeholder="Fecha"
            value={dMovementDate}
            onChange={setMovementDate}
            className="w-full text-lg"
          />
          <Input
            label="Referencia"
            className="w-full"
            value={sMovementRef}
            placeholder="Referencia"
            onChange={setMovementRef}
          />
          <Input
            label="Cantidad"
            className="w-full"
            placeholder="Cantidad"
            value={sMovementQuantity}
            onChange={setMovementQuantity}
          />
        </div>
        <div className="col-span-2 md:text-right flex justify-center mt-5 gap-3 md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            onClick={() => setIsAddMovement(false)}
          />
          <Button
            label="Guardar"
            onClick={onSubmitMovement}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </>;
    if (bIsAddModalOpen || bIsUpdateModalOpen)
      return <>
        {!bIsAddModalOpen &&
          <div className="mb-2 text-right">
            <Button
              className="ml-auto"
              size={BUTTON_CONFIG.SIZE.SM}
              type={BUTTON_CONFIG.CLASS.OUTLINE}
              onClick={() => setIsAddMovement(true)}
              icon={<TbSquarePlus aria-hidden="true" className="h-5 w-5 mr-2" />}
            />
          </div>}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Input
            label="Código"
            value={sModalCode}
            className="w-full"
            placeholder="Código"
            onChange={setModalCode}
            disabled={bIsUpdateModalOpen}
          />
          <ComboBox
            options={aStatus}
            label="Estado"
            selected={oModalStatus}
            emptyLabel="Estado"
            onChange={setModalStatus}
          />
          {
            bIsAddModalOpen ?
              <ArticlesSuggestions
                label="Artículo"
                onSelect={setModalArticle}
                value={oModalArticle?.label}
              />
              :
              <>
                <Input
                  disabled
                  label="Stock"
                  className="w-full"
                  placeholder="Stock"
                  value={sModalStock || "0"}
                />
                <Input
                  disabled
                  label="Artículo"
                  className="w-full"
                  placeholder="Artículo"
                  value={oModalArticle?.label}
                />
              </>
          }
          <DateInput
            labelClass="mb-1"
            value={dModalAdmission}
            label="Fecha de ingreso"
            onChange={setModalAdmission}
            className="w-full text-lg"
            placeholder="Fecha de ingreso"
          />
          <DateInput
            value={dModalExpiration}
            labelClass="mb-1"
            className="w-full text-lg"
            label="Fecha de vencimiento"
            onChange={setModalExpiration}
            placeholder="Fecha de vencimiento"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-5 gap-3">
          <div className="col-span-1  text-center md:text-leftd">
            <Button
              className="mr-4"
              label="Movimientos"
              onClick={onOpenMovements}
              size={BUTTON_CONFIG.SIZE.SM}
              type={BUTTON_CONFIG.CLASS.OUTLINE}
            />
          </div>
          <div className="col-span-2 md:text-right flex justify-center md:justify-end">
            <Button
              label="Cancelar"
              className="mr-4"
              onClick={onCloseModal}
              size={BUTTON_CONFIG.SIZE.SM}
              type={BUTTON_CONFIG.CLASS.OUTLINE}
            />
            <Button
              label="Guardar"
              onClick={onSubmit}
              size={BUTTON_CONFIG.SIZE.SM}
              type={BUTTON_CONFIG.CLASS.SUCCESS}
              icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
            />
          </div>
        </div>
      </>;
    else
      return <>
        <div className="mb-2">
          <Button
            label="Volver"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            iconPosition={BUTTON_CONFIG.POSITION.LEFT}
            onClick={onBack}
            icon={<BiArrowBack aria-hidden="true" className="h-5 w-5 mr-2" />}
          />
        </div>
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Tipo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Referencia
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Cantidad
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aMovements?.length > 0 ? (
              aMovements?.map((oItem, nIndex) => (
                <Table.Row key={nIndex}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {CastDate(oItem.date, DATES_FORMATS.DEFAULT)}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.type?.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.document ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.quantity}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={5}>
                  <em>No se encontraron resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </>;
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Lotes
        </Text>
        <div className="gap-2 md:flex">
          <Button
            label="Nuevo"
            containerClassName="ml-auto"
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            onClick={onAdd}
            icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sCode}
            floatLabel={false}
            onChange={onChangeCode}
            placeholder="Código"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <ArticlesSuggestions
            onSelect={onChangeArticle}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            size="sm"
            selected={oStatus}
            onChange={onChangeStatus}
            options={aStatus}
            emptyLabel="Estado"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"DocumentsTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-2 py-3 text-center">
                Lote
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center hidden md:table-cell">
                Código
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center">
                Artículo
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center hidden lg:table-cell">
                Ingreso
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center hidden lg:table-cell">
                Vencimiento
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-right hidden md:table-cell">
                Stock
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center hidden lg:table-cell">
                Estado
              </Table.Col>
              <Table.Col scope="col"></Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aLots?.length > 0 ? (
              aLots.map((oItem, index) => (
                <Table.Row key={`${oItem.id}_${index}`} onClick={() => onSelect(oItem)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {oItem.code}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center hidden md:table-cell">
                    {oItem.article?.code ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center">
                    {oItem.article?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center hidden lg:table-cell">
                    {CastDate(oItem.admission)}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center hidden lg:table-cell">
                    {CastDate(oItem.expiration)}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-right hidden md:table-cell">
                    {oItem.stock}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center hidden lg:table-cell">
                    {oItem?.status?.name || "-"}
                  </Table.Col>
                  <Table.Col className="px-0 py-4 text-center hidden md:table-cell">
                    <div className="grid grid-cols-2 gap-1">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setLotToDelete(oItem);
                          setIsDeleteModalOpen(true);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setLotToReCalculate(oItem);
                          setIsReCalculateModalOpen(true);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.SUCCESS}
                        icon={<BiCalculator size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={8}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="5" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        title="Eliminar lote"
        show={bIsDeleteModalOpen}
        onConfirm={onConfirmDelete}
        message="Esta acción no se puede revertir"
        onClose={() => setIsDeleteModalOpen(false)}
        question={`Está seguro que desea eliminar el lote "${oLotToDelete?.code}"`}
      />
      <ConfirmationModal
        title="Recalcular stock"
        show={bIsReCalculateModalOpen}
        onConfirm={onConfirmReCalculate}
        message="Esta acción puede tardar unos minutos"
        onClose={() => setIsReCalculateModalOpen(false)}
        question={`Está seguro que desea re calcular el stock del lote "${oLotToReCalculate?.code}"`}
      />
      <CustomModal
        onClose={onCloseModal}
        className="md:max-w-3xl"
        show={bIsAddModalOpen || bIsUpdateModalOpen || bIsMovementsModalOpen}
        title={bIsAddMovement ? `Agregar movimiento (${sModalCode})` : bIsAddModalOpen ? "Nuevo lote" : "Actualizar stock"}
      >
        {onRenderModalContent()}
      </CustomModal>
    </ScreenContainer>
  );
};

export default LotsList;
