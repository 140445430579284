export const IVAsMapper = aIVAs => {
  const mapIVAs = oServerIVA => ({
    id: oServerIVA.ivaId,
    name: oServerIVA.ivaNom,
    rate: oServerIVA.ivaTasa,
    codExp: oServerIVA.ivaCodExp,
    label: oServerIVA.ivaNom,
  });

  if (Array.isArray(aIVAs)) return aIVAs.map(mapIVAs);
  if ((typeof aIVAs).toString() === "object") return mapIVAs(aIVAs);
  return null;
};

export const IVAsUnMapper = aIVAs => {
  const unMapIVAs = oIVA => ({
    ivaId: oIVA.id || 0,
    ivaNom: oIVA.name,
    ivaTasa: oIVA.rate,
    ivaCodExp: oIVA.codExp,
  });

  if (Array.isArray(aIVAs)) return aIVAs.map(unMapIVAs);
  if ((typeof aIVAs).toString() === "object") return unMapIVAs(aIVAs);
  return null;
};
