import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { apiCallGetAllUsers, apiCallUsersDelete, apiCallUsersFind, apiCallUsersSave, apiCallUsersUpdate, apiCallUsersChangePassword } from "../api/UserServices";
import { UsersMapper, UsersUnMapper, UsersUnMapperPassword } from "./UsersMapper";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  aUsers: [],
  bStatus: false,
  bStatusChangePassword: false,
  oUser: null,
};

export const actionUsersGetAll = createAsyncThunk("users/getAllUsers", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllUsers();
    oThunk.dispatch(actionDismissLoading());
    return UsersMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionUsersSave = createAsyncThunk("users/saveUser", async(oUser, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oUserMapped = UsersUnMapper(oUser);
    const oSuccess = await apiCallUsersSave(oUserMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Usuario generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionUsersUpdate = createAsyncThunk("users/updateUser", async(oUser, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oUserMapped = UsersUnMapper(oUser);
    const oSuccess = await apiCallUsersUpdate(oUser.id, oUserMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Usuario actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionUsersChangePassword = createAsyncThunk("users/changePassword", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oDataMapped = UsersUnMapperPassword(oData);
    const oSuccess = await apiCallUsersChangePassword(oDataMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Contraseña actualizada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionUsersFind = createAsyncThunk("users/findUsers", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallUsersFind(nId);
    oThunk.dispatch(actionDismissLoading());
    return UsersMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionUsersDelete = createAsyncThunk("users/deleteUsers", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallUsersDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Usuario eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionUsersGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const UsersSlice = createSlice({
  name: "UsersSlice",
  initialState: oInitialState,
  reducers: {
    actionUsersClean: oState => {
      oState.bStatus = false;
      oState.bStatusChangePassword = false;
    },
    actionUsersFindMock: (oState, oAction) => {
      oState.oUser = oAction.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionUsersGetAll.fulfilled, (oState, oAction) => {
      oState.aUsers = oAction.payload;
    });
    builder.addCase(actionUsersSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionUsersFind.fulfilled, (oState, oAction) => {
      oState.oUser = oAction.payload;
    });
    builder.addCase(actionUsersChangePassword.fulfilled, (oState, oAction) => {
      oState.bStatusChangePassword = oAction.payload;
    });
  },
});

export const { actionUsersClean, actionUsersFindMock } = UsersSlice.actions;

export default UsersSlice.reducer;
