import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";

import {
  ScreenContainer,
  Text,
  Input,
  Button,
  Table,
  ConfirmationModal,
  CustomModal,
} from "components";

import { actionCleanPartnersGroupStatus, actionPartnersGroupDelete, actionPartnersGroupGetAll, actionPartnersGroupSave, selectPartnersGroupState } from "./redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { actionMessage } from "redux/shared/SharedSlice";
import { useNavigate } from "react-router-dom";

const PartnersGroupList = () => {
  const oDispatch = useDispatch();
  const onNavigate = useNavigate();

  const { aGroups, bStatus } = useSelector(selectPartnersGroupState);

  const [bDelete, setDelete] = useState(false);
  const [oDeleteItem, setDeleteItem] = useState(null);

  const [bNew, setNew] = useState(false);
  const [sName, setName] = useState("");

  const [sFilter, setFilter] = useState("");

  const aItemsFiltered = useMemo(() => {
    if (sFilter.length > 0) {
      return aGroups.filter(oItem => oItem.name.toLowerCase().includes(sFilter.toLowerCase()));
    }
    return aGroups;
  }, [aGroups, sFilter]);

  useEffect(() => {
    oDispatch(actionPartnersGroupGetAll());
  }, []);

  useEffect(() => {
    if (bStatus) {
      onClose();
      oDispatch(actionCleanPartnersGroupStatus());
    }
  }, [bStatus]);

  const onDelete = oItem => {
    setDeleteItem(oItem);
    setDelete(true);
  };

  const onConfirmDelete = () => {
    setDelete(false);
    setDeleteItem(null);
    oDispatch(actionPartnersGroupDelete(oDeleteItem.id));
  };

  const onClose = () => {
    setNew(false);
    setName("");
  };

  const onConfirm = () => {
    const oData = { name: sName.trim() };
    if (!oData.name) return oDispatch(actionMessage({ message: "Nombre requerido" }));
    oDispatch(actionPartnersGroupSave(oData.name));
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Grupos de socios
        </Text>
        <Button
          label="Nuevo"
          onClick={() => setNew(true)}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Filtrar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col scope="col" className="hidden md:table-cell" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aItemsFiltered?.length > 0 ? (
              aItemsFiltered.map((oItem, nIndex) => (
                <Table.Row key={oItem.id} onClick={() => onNavigate(`${oItem.id}`, { state: oItem })}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.name}
                  </Table.Col>
                  <Table.Col className="text-center px-2 hidden md:table-cell">
                    <div className="flex justify-center">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oItem);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={5}>
                  <em>
                    {sFilter.length > 0
                      ? "No se encontraron resultados"
                      : "Sin grupos registrados"}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar grupo"
        question={`Está seguro que desea eliminar el grupo "${oDeleteItem?.name}"`}
        message="Esta acción no se puede revertir"
      />
      <CustomModal
        show={bNew}
        className="w-96"
        onClose={onClose}
        title="Nuevo grupo"
      >
        <p>Nombre del grupo</p>
        <Input
          value={sName}
          onChange={setName}
          className="w-full"
          placeholder="Nombre"
          containerClassName="mt-2"
        />
        <div className="mt-5 md:text-right flex justify-center md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            onClick={onClose}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
          />
          <Button
            label="Guardar"
            onClick={onConfirm}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
    </ScreenContainer>
  );
};

export default PartnersGroupList;
