import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BiExport, BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, ComboBox, Table, Text } from "components";

import { actionReportPartnerBySeller, selectReportsState } from "../redux";
import { PartnerBySellerMapper } from "../mappers/ReportMapper";
import { actionSellersGetAll, selectSellersState } from "pages/sellers";
import { CastDate } from "utils/SharedUtils";
import { DATES_FORMATS } from "config/constants/Dates";

const csvHeaders = [
  { label: "Vendedor", key: "seller.name" },
  { label: "Código", key: "partner.code" },
  { label: "Socio de negocio Costos", key: "partner.name" },
  { label: "Razón social Ventas", key: "partner.businessName" },
  { label: "RUT", key: "partner.rut" },
  { label: "Grupo", key: "partner.group" },
];

const PartnerBySellerReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);
  const { aSellers } = useSelector(selectSellersState);

  const onGenerate = (oData) => oDispatch(actionReportPartnerBySeller(oData));

  const [oSeller, setSeller] = useState(null);

  useEffect(() => {
    if (!aSellers) oDispatch(actionSellersGetAll());
  }, []);

  const onExport = () => {
    onGenerate({ venId: oSeller?.id ?? 0 });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Socios por vendedor
        </Text>
        <div className="flex items-center justify-end gap-5">
          {aData?.length > 0 &&
          <CSVLink
            className="ml-auto"
            headers={csvHeaders}
            data={aData.map(PartnerBySellerMapper)}
            title={`Socios por vendedor ${CastDate(new Date(), DATES_FORMATS.LATIN)}`}
            filename={`Socios por vendedor ${CastDate(new Date(), DATES_FORMATS.LATIN)}`}
          >
            <Button
              label="Exportar"
              type={BUTTON_CONFIG.CLASS.SUCCESS}
              icon={<BiExport className="text-white ml-2 text-lg" />}
            />
          </CSVLink>
          }
          <Button
            onClick={onExport}
            label="Generar reporte"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<BiFile className="text-white ml-2 text-lg" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <ComboBox
          label="Vendedor"
          selected={oSeller}
          onChange={setSeller}
          emptyLabel="Vendedor"
          containerClassName="relative col-span-1 gap-5"
          options={[{ label: "Todos", value: 0 }, ...(aSellers?.map(item => ({ ...item, label: item.name, value: item.id })) ?? [])]}
        />
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Vendedor
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Código
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Socio de negocio
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Razón social
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                RUT
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(PartnerBySellerMapper).map((oItem, nIndex) => (
                <Table.Row key={nIndex}>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.seller.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.partner.code}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.partner.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.partner.businessName}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.partner.rut}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={6}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </section>
  );
};

export default PartnerBySellerReport;
