import React, { Fragment } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Menu, Transition } from "@headlessui/react";

import Text from "./Text";
import Button from "./Button";
import { BUTTON_CONFIG } from "config/constants/Button";

export const DROPDOWN_ITEMS_TYPE = {
  SEPARATOR: 1,
  ITEM: 2,
};

function Dropdown({
  items = [],
  label = "Opciones",
  containerClassName = "",
  type = BUTTON_CONFIG.CLASS.SUCCESS,
}) {

  return <div className={containerClassName}>
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button as="div">
          <Button
            type={type}
            label={label}
            icon={<FiChevronDown aria-hidden="true" className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100 text-white" />} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-gray-800 ring-opacity-5 z-50">
          <div className="px-1 py-1">
            {
              items.map((item, index) => {
                if (item.type === DROPDOWN_ITEMS_TYPE.SEPARATOR)
                  return <div key={index} className="bg-gray-200 dark:bg-white m-auto w-11/12 h-[.5px] rounded-lg" />;
                return (<Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={item.onClick}
                      className={`${active ? "bg-gray-300 dark:bg-gray-700 text-white" : ""} group flex w-full items-center rounded-md px-3 py-2 text-sm`}
                    >
                      {item?.icon ? item.icon : null}
                      {item?.label ? <Text>{item.label}</Text> : null}
                    </button>
                  )}
                </Menu.Item>);
              })
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>;
}

export default Dropdown;
