export const UnitsMapper = aUnits => {
  const mapUnits = oServerUnit => ({
    id: oServerUnit.undId,
    name: oServerUnit.undNom,
    code: oServerUnit.undCod,
    label: oServerUnit.undNom,
  });

  if (Array.isArray(aUnits)) return aUnits.map(mapUnits);
  if ((typeof aUnits).toString() === "object") return mapUnits(aUnits);
  return null;
};

export const UnitsUnMapper = aUnits => {
  const unMapUnits = oUnit => ({
    undId: oUnit.id || 0,
    undNom: oUnit.name,
    undCod: oUnit.code,
  });

  if (Array.isArray(aUnits)) return aUnits.map(unMapUnits);
  if ((typeof aUnits).toString() === "object") return unMapUnits(aUnits);
  return null;
};
