export const CURRENCY_CODES = {
  NONE: 0,
  URUGUAYAN_PESO: 1,
  DOLLAR: 2,
};

export const CURRENCY_VALUES = [
  { label: "Todas", value: CURRENCY_CODES.NONE },
  { label: "Pesos", value: CURRENCY_CODES.URUGUAYAN_PESO },
  { label: "Dólares", value: CURRENCY_CODES.DOLLAR },
];
