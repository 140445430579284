import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  actionManageMessage,
  actionShowLoading,
  actionDismissLoading,
  actionMessage,
} from "redux/shared/SharedSlice";
import {
  apiCallGetAllArticles,
  apiCallArticlesDelete,
  apiCallArticlesSave,
  apiCallArticlesUpdate,
  apiCallArticlesSuggest,
  apiCallArticlesAddPrice,
  apiCallArticlesPrices,
  apiCallArticlesGetLineInfo,
  apiCallArticlesReCalculateStock,
  apiCallArticlesGetTypes,
  apiCallArticlesGetFormula,
  apiCallArticlesGetHistoricalStock,
} from "../api/ArticleServices";
import {
  ArticlesMapper,
  ArticlesUnMapperPrice,
  ArticlesMapperSuggest,
  ArticlesUnMapper,
  ArticlesMapperPrice,
  ArticlesUnMapperLineInfo,
  ArticlesMapperLineInfo,
  ArticlesMapperType,
  ArticlesMapperFormula,
} from "./ArticlesMapper";

import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { apiCallGetAllLots, apiCallLotsGetMovements } from "pages/lots/api/LotServices";
import { LotsMapper, LotsMovementMapper } from "pages/lots";

const oInitialState = {
  nPage: 1,
  sFilter: "",
  nPages: null,
  nRecords: null,
  aArticles: [],
  bStatus: false,
  bAddPriceStatus: false,
  oArticle: null,
  aPrices: [],
  aSuggestions: [],
  oLineInfo: null,

  aTypes: [],
  aFormula: null,

  aLots: [],
  nLotsPages: 1,
  nLotsRecords: null,
  aLotMovements: [],

  aHistoricalMovements: [],
};

export const actionArticlesGetAll = createAsyncThunk("articles/getAllArticles", async(args, oThunk) => {
  try {
    const { ArticlesSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllArticles({ pagina: oState.nPage, filtro: oState.sFilter?.trim() || "" });
    oThunk.dispatch(actionDismissLoading());
    return { data: ArticlesMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesSuggest = createAsyncThunk("articles/articlesSuggest", async(sFilter, oThunk) => {
  try {
    const oSuccess = await apiCallArticlesSuggest({ filtro: sFilter });
    return ArticlesMapperSuggest(oSuccess.data || []);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesFind = createAsyncThunk("articles/findArticles", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllArticles({ pagina: 1, filtro: `${nId}` });
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(actionArticleGetPrices(nId));
    oThunk.dispatch(actionArticlesGetFormula(nId));
    if (!oSuccess.data[0]) throw new Error("No se encontró el artículo");
    return ArticlesMapper(oSuccess.data[0]);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesGetFormula = createAsyncThunk("articles/getFormula", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesGetFormula(nId);
    oThunk.dispatch(actionDismissLoading());
    return ArticlesMapperFormula(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesSave = createAsyncThunk("articles/saveArticle", async(oArticle, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oArticledMapped = ArticlesUnMapper(oArticle);
    const oSuccess = await apiCallArticlesSave(oArticledMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Artículo generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesUpdate = createAsyncThunk("articles/updateArticle", async(oArticle, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oArticleMapped = ArticlesUnMapper(oArticle);
    const oSuccess = await apiCallArticlesUpdate(oArticle.id, oArticleMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Artículo actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticleDelete = createAsyncThunk("articles/deleteArticles", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Artículo eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionArticlesGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticleAddPrice = createAsyncThunk("articles/addPrice", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesAddPrice(oData.id, ArticlesUnMapperPrice(oData.data));
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Precio agregado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionArticleGetPrices(oData.id));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticleGetPrices = createAsyncThunk("articles/getPrices", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesPrices(nId);
    oThunk.dispatch(actionDismissLoading());
    return ArticlesMapperPrice(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticleGetLineInfo = createAsyncThunk("articles/getLineInfo", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesGetLineInfo(ArticlesUnMapperLineInfo(oData));
    oThunk.dispatch(actionDismissLoading());
    return ArticlesMapperLineInfo(oSuccess.data)[0] || ArticlesMapperLineInfo(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesGetLots = createAsyncThunk("articles/getLots", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oData = {
      loteId: "",
      estloteid: 0,
      pagina: 1,
      loteCod: "",
      artid: nId,
    };
    const oSuccess = await apiCallGetAllLots(oData);
    oThunk.dispatch(actionDismissLoading());
    return LotsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesGetLotMovements = createAsyncThunk("articles/getLotMovements", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsGetMovements(nId);
    return LotsMovementMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionArticlesReCalculateStock = createAsyncThunk("articles/reCalculateStock", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesReCalculateStock(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Re calculo de stock realizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionArticlesFind(nId));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesGetHistoricalStock = createAsyncThunk("articles/getHistoricalStock", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesGetHistoricalStock(oData);
    oThunk.dispatch(actionDismissLoading());
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionArticlesGetTypes = createAsyncThunk("articles/getTypes", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallArticlesGetTypes();
    oThunk.dispatch(actionDismissLoading());
    return ArticlesMapperType(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const ArticlesSlice = createSlice({
  name: "ArticlesSlice",
  initialState: oInitialState,
  reducers: {
    actionArticlesClean: oState => {
      oState.bStatus = false;
      oState.oArticle = null;
      oState.aLots = [];
      oState.nLotsPages = 1;
      oState.nLotsRecords = null;
      oState.aLotMovements = [];
      oState.aFormula = null;
    },
    actionChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionChangeFilter: (oState, oAction) => {
      oState.sFilter = oAction.payload;
    },
    actionArticlesCleanSuggestions: oState => {
      oState.aSuggestions = [];
    },
    actionArticlesCleanStatus: oState => {
      oState.bStatus = false;
      oState.bAddPriceStatus = false;
    },
    actionArticlesCleanLineInfo: oState => {
      oState.oLineInfo = null;
    },
    actionChangeLotsPage: (oState, oAction) => {
      oState.nLotsPages = oAction.payload;
    },
    actionArticlesCleanMovements: (oState) => {
      oState.aLotMovements = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(actionArticlesSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionArticlesUpdate.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionArticlesGetAll.fulfilled, (oState, oAction) => {
      oState.aArticles = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionArticlesFind.fulfilled, (oState, oAction) => {
      if (oAction.payload) oState.oArticle = oAction.payload;
      else oState.bStatus = true;
    });
    builder.addCase(actionArticlesSuggest.fulfilled, (oState, oAction) => {
      oState.aSuggestions = oAction.payload;
    });
    builder.addCase(actionArticleAddPrice.fulfilled, (oState, oAction) => {
      oState.bAddPriceStatus = oAction.payload;
    });
    builder.addCase(actionArticleGetPrices.fulfilled, (oState, oAction) => {
      oState.aPrices = oAction.payload;
    });
    builder.addCase(actionArticleGetLineInfo.fulfilled, (oState, oAction) => {
      oState.oLineInfo = oAction.payload;
    });
    builder.addCase(actionArticlesGetLots.fulfilled, (oState, oAction) => {
      oState.aLots = oAction.payload;
    });
    builder.addCase(actionArticlesGetLotMovements.fulfilled, (oState, oAction) => {
      oState.aLotMovements = oAction.payload;
    });
    builder.addCase(actionArticlesGetTypes.fulfilled, (oState, oAction) => {
      oState.aTypes = oAction.payload;
    });
    builder.addCase(actionArticlesGetFormula.fulfilled, (oState, oAction) => {
      oState.aFormula = oAction.payload;
    });
    builder.addCase(actionArticlesGetHistoricalStock.fulfilled, (oState, oAction) => {
      oState.aHistoricalMovements = oAction.payload;
    });
  },
});

export const {
  actionArticlesClean,
  actionChangePage,
  actionChangeFilter,
  actionChangeLotsPage,
  actionArticlesCleanStatus,
  actionArticlesCleanLineInfo,
  actionArticlesCleanMovements,
  actionArticlesCleanSuggestions,
} = ArticlesSlice.actions;

export const selectArticlesState = ({ ArticlesSlice: oState }) => oState;

export default ArticlesSlice.reducer;
