export const IndicatorsMapper = aIndicators => {
  const mapIndicators = oServerIndicator => ({
    id: oServerIndicator.indFactId,
    name: oServerIndicator.indFactNom,
    label: oServerIndicator.indFactNom,
  });

  if (Array.isArray(aIndicators)) return aIndicators.map(mapIndicators);
  if ((typeof aIndicators).toString() === "object") return mapIndicators(aIndicators);
  return null;
};

export const IndicatorsUnMapper = aIndicators => {
  const unMapIndicators = oIndicator => ({
    indFactId: oIndicator.id || 0,
    indFactNom: oIndicator.name,
  });

  if (Array.isArray(aIndicators)) return aIndicators.map(unMapIndicators);
  if ((typeof aIndicators).toString() === "object") return unMapIndicators(aIndicators);
  return null;
};
