import oAxiosInstance from "config/Axios";

export const apiCallGetAllFamilies = () => {
  return oAxiosInstance.get("familias");
};

export const apiCallFamiliesSave = oData => {
  return oAxiosInstance.post("familias", oData);
};

export const apiCallFamiliesUpdate = (nId, oData) => {
  return oAxiosInstance.put(`familias/${nId}`, oData);
};

export const apiCallFamiliesDelete = (nId) => {
  return oAxiosInstance.delete(`familias/${nId}`);
};
