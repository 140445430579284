import axios from "axios";
import promise from "promise";
import { SYSTEM_CONSTANTS } from "config/constants/System";
import { getLocalStorageItem } from "hooks/useLocalStorage";
import history from "routes/History";

const { API_URL, APP_TOKEN_KEY } = SYSTEM_CONSTANTS;

const oAxiosInstance = axios.create({
  baseURL: API_URL,
});

oAxiosInstance.interceptors.request.use(
  async function(oConfig) {
    let sToken = getLocalStorageItem(APP_TOKEN_KEY);
    if (sToken && oConfig.method !== "OPTIONS") {
      oConfig.headers.Authorization = `Bearer ${sToken}`;
    }
    return oConfig;
  },
  function(oError) {
    return promise.reject(oError);
  },
);

oAxiosInstance.interceptors.response.use(
  oResponse => {
    return oResponse.data?.value || oResponse.data;
  },
  oError => {
    const sMessage = oError?.response?.data?.value?.message || oError?.response?.data || oError.message;
    if (oError?.response?.status === 403) {
      history.replace("/");
      return promise.reject(sMessage || "No cuenta con permisos necesarios para esta tarea");
    }
    return promise.reject(sMessage);
  },
);

export default oAxiosInstance;
