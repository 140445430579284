import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllDocumentTypesPartners } from "../api/DocumentTypePartnersServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { DocumentTypesPartnersMapper } from "./DocumentTypesPartnersMapper";

const oInitialState = {
  aDocumentTypesPartners: [],
};

export const actionDocumentTypesPartnersGetAll = createAsyncThunk("documents/getAllDocumentTypesPartners", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllDocumentTypesPartners();
    oThunk.dispatch(actionDismissLoading());
    return DocumentTypesPartnersMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const DocumentTypesPartnersSlice = createSlice({
  name: "DocumentTypesSlice",
  initialState: oInitialState,
  extraReducers: builder => {
    builder.addCase(actionDocumentTypesPartnersGetAll.fulfilled, (oState, oAction) => {
      oState.aDocumentTypesPartners = oAction.payload;
    });
  },
});

export const { actionDocumentTypesPartnersClean } = DocumentTypesPartnersSlice.actions;

export const selectDocumentTypesPartners = ({ DocumentTypesPartnersSlice: oState }) => oState?.aDocumentTypesPartners;

export default DocumentTypesPartnersSlice.reducer;
