import React, { useEffect, useMemo, useState } from "react";
import { BiExport, BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import oDayJs from "dayjs";

import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, ComboBox, DateInput, Table, Text } from "components";

import { actionReportGetTax, selectReportsState } from "../redux";
import { TaxMapper } from "../mappers/ReportMapper";
import { CastDate } from "utils/SharedUtils";
import { DATES_FORMATS } from "config/constants/Dates";
import { CURRENCY_VALUES } from "config/constants/Currency";
import { selectLoguedUser } from "redux/auth/AuthSelector";

const csvHeaders = [
  { label: "Tipo", key: "type" },
  { label: "Fecha", key: "date" },
  { label: "Nro Interno", key: "nro" },
  { label: "CAE", key: "cae" },
  { label: "Total IVA", key: "iva" },
  { label: "Estado", key: "status" },
];

const TaxReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);

  const onGenerate = (oData) => oDispatch(actionReportGetTax(oData));

  const [oCurrency, setCurrency] = useState(CURRENCY_VALUES[1]);
  const [dDateTo, setDateTo] = useState(oDayJs().toDate());
  const [dDateFrom, setDateFrom] = useState(oDayJs().subtract(1, "month").toDate());

  const nTotal = useMemo(() => {
    if (!aData || aData?.length === 0) return 0;
    let total = aData.reduce((nAcc, oItem) => nAcc + oItem.totIva, 0);
    return total.toFixed(4);
  }, [aData]);

  const oUser = useSelector(selectLoguedUser);
  useEffect(() => {
    if (oUser && oUser.config?.idCurrency)
      setCurrency(CURRENCY_VALUES.find(item => item.value === oUser.config?.idCurrency));
  }, [oUser]);

  const onExport = () => {
    onGenerate({
      hasta: CastDate(dDateTo, DATES_FORMATS.MYSQL_SERVER),
      desde: CastDate(dDateFrom, DATES_FORMATS.MYSQL_SERVER),
      monid: oCurrency.value,
    });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Resumen de IVA
        </Text>
        <div className="flex items-center justify-end gap-5">
          {aData?.length > 0 &&
            <CSVLink
              data={aData.map(TaxMapper)}
              className="ml-auto"
              headers={csvHeaders}
              title={`Resumen de IVA ${CastDate(new Date(), DATES_FORMATS.LATIN)}`}
              filename={`Resumen de IVA ${CastDate(new Date(), DATES_FORMATS.LATIN)}`}
            >
              <Button
                label="Exportar"
                type={BUTTON_CONFIG.CLASS.SUCCESS}
                icon={<BiExport className="text-white ml-2 text-lg" />}
              />
            </CSVLink>
          }
          <Button
            label="Generar reporte"
            onClick={onExport}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<BiFile className="text-white ml-2 text-lg" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha inicio</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={setDateFrom}
            placeholder="Fecha inicio"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha fin</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={setDateTo}
            placeholder="Fecha fin"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            selected={oCurrency}
            onChange={setCurrency}
            label="Moneda"
            emptyLabel="Todas"
            options={[CURRENCY_VALUES[1], CURRENCY_VALUES[2]]}
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Tipo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nro. Interno
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                CAE
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right">
                Total IVA
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Estado
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(TaxMapper).map((oItem, nIndex) => (
                <Table.Row key={`${nIndex}`}>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.type}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.date}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.nro}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.cae || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.iva}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.status || "-"}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="4" className="px-6 py-3 text-right">
                <Text>Total IVA:</Text>
              </Table.Col>
              <Table.Col className="px-6 py-3 text-right">
                <Text>{nTotal}</Text>
              </Table.Col>
              <Table.Col className="px-6 py-3 text-right" />
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
    </section>
  );
};

export default TaxReport;
