export const ReportTypes = {
  SALES_RANKING: "sales_ranking",
  PENDING_CREDIT: "pending_credit",
  STOCK: "stock",
  PARTNERS_BALANCE: "partners_balance",
  TAX: "tax",
  UTILITIES: "utilities",
  LAST_PURCHASE_BY_CLIENT: "last_purchase",
  SELLER: "seller",
  PARTNER_BY_SELLER: "partner_by_seller",
};
