export const selectAllPartners = ({ PartnersSlice: oState }) => oState?.aPartners;
export const selectPagesPartners = ({ PartnersSlice: oState }) => oState?.nPages;
export const selectRecordsPartners = ({ PartnersSlice: oState }) => oState?.nRecords;
export const selectPartnerPartners = ({ PartnersSlice: oState }) => oState?.oPartner;
export const selectStatusPartners = ({ PartnersSlice: oState }) => oState?.bStatus;
export const selectSuggestionsPartners = ({ PartnersSlice: oState }) => oState?.aSuggestions;
export const selectDebtorPartners = ({ PartnersSlice: oState }) => oState?.oDebtorData;
export const selectCreditorPartners = ({ PartnersSlice: oState }) => oState?.oCreditorData;
export const selectStatementAccountPartners = ({ PartnersSlice: oState }) => oState?.oDataStatementAccount;
export const selectPartnerReport = ({ PartnersSlice: oState }) => oState?.sReport;
export const selectPartnerFilter = ({ PartnersSlice: oState }) => oState?.sFilter;
export const selectPartnerPage = ({ PartnersSlice: oState }) => oState?.nPage;
