import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllIVAs } from "../api/IVAServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { IVAsMapper } from "./IVAsMapper";

const oInitialState = {
  aIVAs: [],
  bStatus: false,
};

export const actionIVAsGetAll = createAsyncThunk("IVA/getAllIVAs", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllIVAs();
    oThunk.dispatch(actionDismissLoading());
    return IVAsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const IVAsSlice = createSlice({
  name: "IVAsSlice",
  initialState: oInitialState,
  reducers: {
    actionIVAsClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionIVAsGetAll.fulfilled, (oState, oAction) => {
      oState.aIVAs = oAction.payload;
    });
  },
});

export const { actionIVAsClean } = IVAsSlice.actions;

export default IVAsSlice.reducer;
