import React from "react";
import { CONSTANTS_TEXT_STYLE } from "config/constants/Themes";
import Text from "./Text";

export const INPUT_TYPE = {
  DEFAULT: "text",
  NUMBER: "number",
  EMAIL: "email",
  PASSWORD: "password",
  CHECKBOX: "checkbox",
};

export const INPUT_AUTO_COMPLETE = {
  OFF: "off",
  NEW_PASSWORD: "new-password",
  ON: "on",
};

const Input = ({
  value: sValue,
  onBlur = null,
  onFocus = null,
  onKeyUp = null,
  onSubmit = null,
  onChange = null,
  color: sColor = null,
  label: sLabel = null,
  id: sIdentifier = null,
  setRef: onSetRef = null,
  disabled: bDisabled = false,
  className: sClassName = null,
  textarea: bIsTextArea = false,
  placeholder: sPlaceHolder = "",
  labelClass: sLabelClass = null,
  floatLabel: bFloatLabel = false,
  type: sType = INPUT_TYPE.DEFAULT,
  labelSimple: bLabelSimple = false,
  autocomplete: sAutocomplete = INPUT_AUTO_COMPLETE.ON,
  withoutClass: bWithOutClass = false,
  containerClassName: sContainerClassName = "",
  labelStyle: nLabelStyle = CONSTANTS_TEXT_STYLE.DEFAULT,
  maxLength: nMaxLenght = null,
}) => {
  if (!sValue) sValue = "";

  const aClassName = [
    "text-gray-800 dark:text-white",
    "border-input",
    "rounded-md",
    "px-2",
    "py-2",
    "bg-gray-100 dark:bg-gray-700",
    "mt-1",
    "text-lg",
  ];

  if (sColor) aClassName[0] = sColor;

  if (sClassName) aClassName.push(sClassName);


  const onRenderInput = () => {
    return !bIsTextArea
      ? <input
        type={sType}
        ref={onSetRef}
        onBlur={onBlur}
        id={sIdentifier}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onSubmit={onSubmit}
        value={sValue || ""}
        disabled={bDisabled}
        placeholder={sPlaceHolder}
        autoComplete={sAutocomplete}
        className={bWithOutClass ? sClassName : aClassName.join(" ")}
        onChange={oEvent => (onChange ? onChange(oEvent.target.value, sIdentifier) : null)}
      />
      : <textarea
        type={sType}
        ref={onSetRef}
        onBlur={onBlur}
        id={sIdentifier}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onSubmit={onSubmit}
        value={sValue || ""}
        disabled={bDisabled}
        maxLength={nMaxLenght}
        autoComplete={sAutocomplete}
        className={bWithOutClass ? sClassName : aClassName.join(" ")}
        placeholder={sPlaceHolder}
        onChange={oEvent => (onChange ? onChange(oEvent.target.value, sIdentifier) : null)}
      ></textarea>;
  };

  if (!bFloatLabel) {
    return (
      <div className={sContainerClassName}>
        {sLabel ? (
          <Text
            size={"text-lg"}
            style={nLabelStyle}
            simple={bLabelSimple}
            className={sLabelClass + (sValue ? " active" : "") + " text-ellipsis whitespace-nowrap overflow-hidden"}
          >
            {sLabel}
          </Text>
        ) : null}
        {onRenderInput()}
      </div>
    );
  }

  return (
    <div className={`float-label ${sContainerClassName} transition-all`}>
      {onRenderInput()}
      {sLabel ? (
        <Text className={sValue ? "active" : ""} style={nLabelStyle} size={"text-lg"}>
          {sLabel}
        </Text>
      ) : null}
    </div>
  );
};

export default Input;
