import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import Highlighter from "react-highlight-words";

import { ScreenContainer, Text, Input, Table } from "components";

import { actionDocumentTypesGetAll, selectDocumentTypesState } from "./redux";

const DocumentTypesList = () => {
  const oDispatch = useDispatch();
  const { aDocumentTypes } = useSelector(selectDocumentTypesState);
  const [sFilter, setFilter] = useState("");
  const [aFilteredData, setFilteredData] = useState([]);

  useEffect(() => {
    oDispatch(actionDocumentTypesGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredData(() => {
      return aDocumentTypes?.length
        ? aDocumentTypes
          .filter(oItem => oItem.name?.toLowerCase().includes(sFilter.toLowerCase()))
          .map(oItem => ({ ...oItem, sFilter }))
        : [];
    });
  }, [aDocumentTypes, sFilter]);

  return (
    <ScreenContainer>
      <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center">
        Listado de tipos de documentos
      </Text>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Buscar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"PricesTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFilteredData.length > 0 ? (
              aFilteredData.map((oItem, nIndex) => (
                <Table.Row key={oItem.id}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    <Highlighter
                      searchWords={[oItem.sFilter]}
                      autoEscape={true}
                      textToHighlight={oItem.name}
                    />
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={3}>
                  <em>
                    {sFilter.length > 0
                      ? "No se encontraron resultados"
                      : "Sin tipos de documentos registrados"}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </ScreenContainer>
  );
};

export default DocumentTypesList;
