import React, { useEffect, useState } from "react";
import { BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, ComboBox, PartnerSuggestions, Table, Text } from "components";

import { actionReportGetStock, selectReportsState } from "../redux";
import { StockMapper } from "../mappers/ReportMapper";
import { actionFamiliesGetAll, selectAllFamilies } from "pages/families";

const StockReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);

  const onGenerate = (oData) => oDispatch(actionReportGetStock(oData));

  const aTypes = [
    { label: "Todos los artículos", value: 1 },
    { label: "Stock Crítico (por debajo del mínimo)", value: 2 },
  ];

  const aOrders = [
    { label: "Familia y nombre de Artículo", value: 1 },
    { label: "Proveedor, Familia y nombre de artículo", value: 2 },
  ];

  const aIncludes = [
    { label: "Incluir artículos sin stock", value: 1 },
    { label: "No incluir artículos sin stock", value: 2 },
  ];

  const [oType, setType] = useState(aTypes[1]);
  const [oOrder, setOrder] = useState(aOrders[1]);
  const [oInclude, setInclude] = useState(aIncludes[0]);
  const [oPartner, setPartner] = useState(null);
  const [oFamily, setFamily] = useState(null);

  useEffect(() => {
    if (aFamilies.length === 0)
      oDispatch(actionFamiliesGetAll());
  }, []);

  const onExport = () => {
    onGenerate({
      famId: oFamily?.id ?? 0,
      socId: oPartner?.id ?? 0,
      incluirSinStock: oInclude.value,
      orden: oOrder.value,
      tipo: oType.value,
    });
  };
  const aFamilies = useSelector(selectAllFamilies);


  const getFamilies = () => {
    if (!aFamilies) return [];
    let aFamiliesCopy = JSON.parse(JSON.stringify(aFamilies));
    const aSortedFamilies = aFamiliesCopy?.sort((itemA, itemB) => {
      let nameA = itemA.name.toUpperCase();
      let nameB = itemB.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }) || [];
    aSortedFamilies.unshift({ id: 0, label: "Todas" });
    return aSortedFamilies;
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Inventario (Stock)
        </Text>
        <Button
          label="Generar reporte"
          onClick={onExport}
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<BiFile className="text-white ml-2 text-lg" />}
          containerClassName="mt-5 mx-auto sm:mr-0 sm:mt-0 sm:ml-auto"
        />
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-1 gap-5">
          <ComboBox
            selected={oFamily}
            onChange={setFamily}
            label="Familia"
            emptyLabel="Familia"
            options={getFamilies()}
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <PartnerSuggestions
            className="w-full"
            onSelect={setPartner}
            value={oPartner?.name}
            label="Socio de negocio"
            placeholder="Socio de negocio"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            label="Tipo"
            options={aTypes}
            selected={oType}
            emptyLabel="Todas"
            onChange={setType}
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            options={aIncludes}
            selected={oInclude}
            onChange={setInclude}
            label="Incluir sin stock"
            emptyLabel="Incluir sin stock"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            label="Orden"
            options={aOrders}
            selected={oOrder}
            emptyLabel="Orden"
            onChange={setOrder}
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3">
                Proveedor
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Familia
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Código
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Artículo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Stock mínimo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Stock
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Faltante
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(StockMapper).map((oItem, nIndex) => (
                <React.Fragment key={`${nIndex}`}>
                  <Table.Row>
                    <Table.Col
                      scope="row"
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap"
                    >
                      {oItem.partner?.name}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem.family?.name}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center">
                      {oItem.article?.code}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem.article?.name}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {oItem.minStock}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {oItem.stock}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {oItem.missing}
                    </Table.Col>
                  </Table.Row>
                  {oItem.discounts?.length > 0 && (
                    <>
                      <Table.Row className="bg-gray-200 dark:bg-gray-900">
                        <Table.Col className="py-1 text-center" colSpan={7}>
                          <Text className="font-bold text-gray-400">
                            Descuentos
                          </Text>
                        </Table.Col>
                      </Table.Row>
                      <Table.Row className="bg-gray-300 dark:bg-gray-900">
                        <Table.Col className="px-6 py-1 text-center" colSpan={2}>
                          Tipo
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          Nro
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          CAE
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          Importe
                        </Table.Col>
                      </Table.Row>
                      {oItem.discounts.map((oDiscount, nDiscountIndex) => (
                        <Table.Row key={`${nIndex}_${nDiscountIndex}`} className="bg-gray-200 dark:bg-gray-900">
                          <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                            {oDiscount.type}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            {oDiscount.nro}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            {oDiscount.cae}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            $ {oDiscount.amount ?? "0"}
                          </Table.Col>
                        </Table.Row>))}
                    </>)}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </section>
  );
};

export default StockReport;
