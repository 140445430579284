import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FaLock } from "react-icons/fa6";

import { Button, ScreenContainer, Text, Input, Table, ConfirmationModal } from "components";

import { actionUsersDelete, actionUsersFindMock, actionUsersGetAll, selectAllUsers } from "pages/users/redux";

import { CONSTANTS_COLORS } from "config/constants/Colors";
import { BUTTON_CONFIG } from "config/constants/Button";
import { selectLoguedUser } from "redux/auth/AuthSelector";
import UsersLang from "./lang";

const UsersList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();

  const oLoguedUser = useSelector(selectLoguedUser);
  const aUsers = useSelector(selectAllUsers);
  const [sFilter, setFilter] = useState("");
  const [aFilteredUsers, setFilteredUsers] = useState([]);

  const [bDelete, setDelete] = useState(false);
  const [oUserDelete, setUserDelete] = useState(null);

  useEffect(() => {
    oDispatch(actionUsersGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredUsers(() => {
      return aUsers?.length
        ? aUsers
          .filter(
            oUser =>
              oUser.name.toLowerCase().includes(sFilter.toLowerCase()) ||
              oUser.username.toLowerCase().includes(sFilter.toLowerCase()) ||
              oUser.email.toLowerCase().includes(sFilter.toLowerCase()),
          )
          .map(oUser => ({ ...oUser, sFilter }))
        : [];
    });
  }, [aUsers, sFilter]);

  const onNavigate = () => {
    oNavigator("/users/new");
  };

  const onUpdate = oUser => {
    oDispatch(actionUsersFindMock(oUser));
    if (oUser.id === oLoguedUser?.id) {
      oNavigator("/profile");
    } else {
      oNavigator(`/users/${oUser.id}`);
    }
  };

  const onDelete = oUser => {
    setUserDelete(oUser);
    setDelete(true);
  };

  const onConfirmDelete = () => {
    setDelete(false);
    setUserDelete(null);
    oDispatch(actionUsersDelete(oUserDelete.id));
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          {UsersLang.Labels.listUsers}
        </Text>
        <Button
          label={UsersLang.Buttons.new}
          onClick={onNavigate}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Buscar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"usersTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {UsersLang.Fields.name}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {UsersLang.Fields.email}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                {UsersLang.Fields.username}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {UsersLang.Fields.status}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFilteredUsers.length > 0 ? (
              aFilteredUsers.map((oUser, nIndex) => (
                <Table.Row
                  key={oUser.id}
                  className="cursor-pointer"
                  onClick={() => onUpdate(oUser)}
                >
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    <Highlighter
                      searchWords={[oUser.sFilter]}
                      autoEscape={true}
                      textToHighlight={oUser.name}
                    />
                  </Table.Col>
                  <Table.Col className="py-4 text-center hidden md:table-cell">
                    <Highlighter
                      searchWords={[oUser.sFilter]}
                      autoEscape={true}
                      textToHighlight={oUser.email || "-"}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-center">
                    <Highlighter
                      searchWords={[oUser.sFilter]}
                      autoEscape={true}
                      textToHighlight={oUser.username}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-center hidden md:table-cell">
                    {oUser.isActive ? "Activo" : "Inactivo"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 flex items-center justify-center gap-3">
                    <div className="flex justify-center cursor-pointer" onClick={e => {
                      e.stopPropagation();
                      oNavigator("/change-password", { state: { user: oUser } });
                    }}>
                      <FaLock size={16} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                    </div>
                    <div className="flex justify-center cursor-pointer" onClick={e => {
                      e.stopPropagation();
                      onDelete(oUser);
                    }}>
                      <FaTrashAlt className="text-lg text-red-400 dark:text-red-400" />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={6}>
                  <em>No existen datos de usuarios</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar usuario"
        question={`Está seguro que desea eliminar el usuario "${oUserDelete?.username}"`}
        message="Esta acción no se puede revertir"
      />
    </ScreenContainer>
  );
};

export default UsersList;
