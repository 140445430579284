import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "components/Autosuggest";

import { actionLotsCleanSuggestions, actionLotsSuggest, selectLotsState } from "pages/lots";

const LotsSuggestions = ({
  onSelect,
  value: sValue = "",
  label: sLabel = null,
  className: sClassName,
  clean: bClean = false,
  disabled: bDisabled = false,
  containerClassName: sContainerClassName = null,
}) => {
  const oDispatch = useDispatch();
  const { aSuggestions } = useSelector(selectLotsState);

  const onSuggestionsFetchRequested = (oEvent) => {
    oDispatch(actionLotsSuggest(oEvent.value));
  };

  const onCleanSuggestions = () => oDispatch(actionLotsCleanSuggestions());

  useEffect(() => {
    onCleanSuggestions();
  }, [bClean]);

  return <div className={sContainerClassName || null}>
    <Autosuggest
      value={sValue}
      label={sLabel}
      clean={bClean}
      placeholder="Lote"
      onSelect={onSelect}
      disabled={bDisabled}
      className={sClassName}
      suggestions={aSuggestions}
      onSuggestionsClearRequested={onCleanSuggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    />
  </div>;
};

export default LotsSuggestions;
