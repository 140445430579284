import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  listArticles: "Listado de artículos",
  createArticle: "Crear artículo",
  editArticle: "Editar artículo",
  articleData: "Datos del artículo",
  article: "Artículo",
  prices: "Precios",
  type: "Tipo",
  quantity: "Cantidad",
  quantityFormula: "Cant. Fórmula",
  quantityRelative: "Cant. Relativa",
};

export default Labels;
