import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllIndicators } from "../api/IndicatorServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { IndicatorsMapper } from "./IndicatorsMapper";

const oInitialState = {
  aIndicators: [],
  bStatus: false,
};

export const actionIndicatorsGetAll = createAsyncThunk("indicators/getAllIndicators", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllIndicators();
    oThunk.dispatch(actionDismissLoading());
    return IndicatorsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const IndicatorsSlice = createSlice({
  name: "IndicatorsSlice",
  initialState: oInitialState,
  reducers: {
    actionIndicatorsClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionIndicatorsGetAll.fulfilled, (oState, oAction) => {
      oState.aIndicators = oAction.payload;
    });
  },
});

export const { actionIndicatorsClean } = IndicatorsSlice.actions;

export const selectIndicatorsState = ({ IndicatorsSlice: oState }) => oState;

export default IndicatorsSlice.reducer;
