import SysValidations from "config/lang/SysValidations";

const Validations = {
  ...SysValidations,
  sameArticle: "No puedes usar el mismo artículo que estas fábricando",
  articleAlreadyIncluded: "El artículo ya está incluido",

};

export default Validations;
