export const LotsMapper = aLots => {
  const mapLots = oServerLot => ({
    id: oServerLot.loteId,
    code: oServerLot.loteCod,
    label: oServerLot.loteCod,
    admission: oServerLot.loteFchIng,
    expiration: oServerLot.loteFchVenc,
    stock: oServerLot.stock,
    status: {
      id: oServerLot.estLoteId,
      name: oServerLot.estLoteNom,
      value: oServerLot.estLoteId,
      label: oServerLot.estLoteNom,
    },
    article: {
      id: oServerLot.artId,
      code: oServerLot.artCod,
      name: oServerLot.artNom,
      value: oServerLot.artCod,
      label: oServerLot.artNom,
    },
  });
  if (!aLots) return null;
  if (Array.isArray(aLots)) return aLots.map(mapLots);
  if ((typeof aLots).toString() === "object") return mapLots(aLots);
  return null;
};

export const LotsUnMapper = aLots => {
  const unMapLots = oLot => ({
    loteid: oLot.id,
    lotecod: oLot.code,
    artid: oLot.article?.id || oLot.idArticle || 0,
    lotefching: oLot.admission,
    lotefchvenc: oLot.expiration,
    stock: oLot.stock || 0,
    estloteid: oLot.status?.id || 0,
  });

  if (Array.isArray(aLots)) return aLots.map(unMapLots);
  if ((typeof aLots).toString() === "object") return unMapLots(aLots);
  return null;
};

export const LotsStatusMapper = aServerStatus => {
  const mapLots = oServerStatus => ({
    id: oServerStatus.estLoteId,
    name: oServerStatus.estLoteNom,
    value: oServerStatus.estLoteId,
    label: oServerStatus.estLoteNom,
  });

  if (Array.isArray(aServerStatus)) return aServerStatus.map(mapLots);
  if ((typeof aServerStatus).toString() === "object") return mapLots(aServerStatus);
  return null;
};

export const LotsMovementMapper = aMovements => {
  const mapLots = oServerMovement => ({
    id: oServerMovement.movId,
    date: oServerMovement.movFch,
    document: oServerMovement.refId,
    quantity: oServerMovement.cantidad,
    lot: { id: oServerMovement.loteId },
    type: {
      id: oServerMovement.tipoMovId,
      name: oServerMovement.tipoMovNom,
    },
  });

  if (Array.isArray(aMovements)) return aMovements.map(mapLots);
  if ((typeof aMovements).toString() === "object") return mapLots(aMovements);
  return null;
};

export const LotsMovementUnMapper = aMovements => {
  const unMapLots = oServerMovement => ({
    movId: oServerMovement.id || "",
    loteId: oServerMovement.lot?.id || oServerMovement.idLot,
    movfch: oServerMovement.date,
    tipomovid: oServerMovement.type?.id || 0,
    refid: oServerMovement.document || "",
    cantidad: oServerMovement.quantity,
  });

  if (Array.isArray(aMovements)) return aMovements.map(unMapLots);
  if ((typeof aMovements).toString() === "object") return unMapLots(aMovements);
  return null;
};

export const LotsMovementTypeMapper = aMovementTypes => {
  const mapMovementType = oServerMovementType => ({
    id: oServerMovementType.tipoMovId,
    name: oServerMovementType.tipoMovNom,
    value: oServerMovementType.tipoMovId,
    label: oServerMovementType.tipoMovNom,
  });

  if (Array.isArray(aMovementTypes)) return aMovementTypes.map(mapMovementType);
  if ((typeof aMovementTypes).toString() === "object") return mapMovementType(aMovementTypes);
  return null;
};
