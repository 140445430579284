import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";

import { Button, ScreenContainer, Text, Input, Table } from "components";

import { actionArticleDelete, actionArticlesGetAll, selectArticlesState, actionChangePage, actionChangeFilter } from "./redux";

import { CONSTANTS_COLORS } from "config/constants/Colors";
import { BUTTON_CONFIG } from "config/constants/Button";
import ArticlesLang from "./lang";
import ConfirmationModal from "components/ConfirmationModal";

const ArticlesList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();

  const { aArticles, nPage, sFilter, nPages, nRecords } = useSelector(selectArticlesState);

  const [bIsDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [oArticleToDelete, setArticleToDelete] = useState(null);

  useEffect(() => {
    oDispatch(actionArticlesGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTimeout = (oState, oData) => {
    if (!oData) { return oState; }
    if (oState) { clearTimeout(oState); }
    return oData;
  };

  const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionChangePage(nSelectedPage));
    oDispatch(actionArticlesGetAll());
  };

  const onChangeFilter = (sNewFilter) => {
    oDispatch(actionChangeFilter(sNewFilter));
    setTimer(setTimeout(() => {
      oDispatch(actionChangePage(1));
      oDispatch(actionArticlesGetAll());
    }, 800));
  };

  const onNavigate = () => {
    oNavigator("/articles/new");
  };

  const onUpdate = oArticle => {
    oNavigator(`/articles/${oArticle.id}`);
  };

  const onDelete = oArticle => {
    setArticleToDelete(oArticle);
    setIsDeleteModalOpen(true);
  };

  const onConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    setArticleToDelete(null);
    oDispatch(actionArticleDelete(oArticleToDelete.id));
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          {ArticlesLang.Labels.listArticles}
        </Text>
        <Button
          label={ArticlesLang.Buttons.new}
          onClick={onNavigate}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={onChangeFilter}
            placeholder="Buscar"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"usersTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ArticlesLang.Fields.code}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ArticlesLang.Fields.name}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ArticlesLang.Fields.isActive}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ArticlesLang.Fields.marca}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ArticlesLang.Fields.model}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                {ArticlesLang.Fields.stock}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                {ArticlesLang.Fields.minStock}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aArticles.length > 0 ? (
              aArticles.map((oArticle, nIndex) => (
                <Table.Row
                  key={oArticle.id}
                  className="cursor-pointer"
                  onClick={() => onUpdate(oArticle)}
                >
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    <Highlighter
                      searchWords={[sFilter]}
                      autoEscape={true}
                      textToHighlight={oArticle.code}
                    />
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    <Highlighter
                      searchWords={[sFilter]}
                      autoEscape={true}
                      textToHighlight={oArticle.name}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-center">
                    {oArticle.isActive ? "Activo" : "Inactivo"}
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-center">
                    <Highlighter
                      searchWords={[sFilter]}
                      autoEscape={true}
                      textToHighlight={oArticle.marca}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-center">
                    <Highlighter
                      searchWords={[sFilter]}
                      autoEscape={true}
                      textToHighlight={oArticle.model}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-right hidden md:table-cell">
                    {oArticle.stock ?? "0"}
                  </Table.Col>
                  <Table.Col className="px-2 md:px-6 text-right hidden md:table-cell">
                    {oArticle.minStock}
                  </Table.Col>
                  <Table.Col className="text-center px-2">
                    <div className="flex justify-center">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oArticle);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={9}>
                  <em>No existen datos de artículos</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="4" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="4" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="5" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        show={bIsDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar artículo"
        question={`Está seguro que desea eliminar el artículo ${oArticleToDelete?.name}`}
        message="Esta acción no se puede revertir"
      />
    </ScreenContainer>
  );
};

export default ArticlesList;
