import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { apiCallGetAllTasks } from "../api/TaskServices";
import { TasksMapper } from "./TasksMapper";

const oInitialState = {
  aTasks: [],
};

export const actionTasksGetAll = createAsyncThunk("tasks/getAllTasks", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllTasks();
    oThunk.dispatch(actionDismissLoading());
    return TasksMapper(oSuccess?.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const TasksSlice = createSlice({
  name: "TasksSlice",
  initialState: oInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actionTasksGetAll.fulfilled, (oState, oAction) => {
      oState.aTasks = oAction.payload;
    });
  },
});

export default TasksSlice.reducer;
