import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { SellersMapper, SellersUnMapper } from "./SellersMapper";
import { apiCallGetAllSellers, apiCallSellersDelete, apiCallSellersSave, apiCallSellersUpdate } from "../api/SellerServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

export const actionSellersGetAll = createAsyncThunk("sellers/getAllSellers", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllSellers();
    return { data: SellersMapper(oSuccess.data) };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionSellersSave = createAsyncThunk("sellers/save", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oMappedData = SellersUnMapper(oData);
    const oSuccess = await apiCallSellersSave(oMappedData);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Vendedor generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionSellersGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionSellersUpdate = createAsyncThunk("sellers/update", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oMappedData = SellersUnMapper(oData);
    const oSuccess = await apiCallSellersUpdate(oData.id, oMappedData);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Vendedor actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionSellersGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionSellersDelete = createAsyncThunk("sellers/delete", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallSellersDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Vendedor eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionSellersGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const oInitialState = {
  aSellers: undefined,
  bAction: false,
};

const SellersSlice = createSlice({
  name: "SellersSlice",
  initialState: oInitialState,
  reducers: {
    actionSellersClean: oState => {
      oState.aSellers = [];
    },
    actionSellersCleanAction: oState => {
      oState.bAction = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionSellersGetAll.fulfilled, (oState, oAction) => {
      oState.aSellers = oAction.payload.data;
    });
    builder.addCase(actionSellersSave.fulfilled, (oState, oAction) => {
      oState.bAction = oAction.payload;
    });
    builder.addCase(actionSellersUpdate.fulfilled, (oState, oAction) => {
      oState.bAction = oAction.payload;
    });
    builder.addCase(actionSellersDelete.fulfilled, (oState, oAction) => {
      oState.bAction = oAction.payload;
    });
  },
});

export const {
  actionSellersClean,
  actionSellersCleanAction,
} = SellersSlice.actions;

export const selectSellersState = ({ SellersSlice: oState }) => oState;

export default SellersSlice.reducer;
