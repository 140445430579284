import React, { useEffect, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";

const MenuDropDown = ({
  onClick,
  children,
  title: sTitle,
  icon: oIcon = null,
  onlyIcon: bOnlyIcon = false,
  identifier: sIdentifier,
  open: sOpen,
}) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (sOpen && sOpen !== sIdentifier)
      setIsOpen(false);
  }, [sOpen]);

  let sClass = [
    "flex items-center p-2 text-base font-normal rounded-lg",
    "text-gray-500",
    "dark:text-white",
    "dark:hover:bg-primary-bg",
    "hover:bg-primary-bg",
    "dark:hover:text-primary-500",
    "hover:text-primary-500",
    "w-full",
  ];

  const onToggle = () => {
    setIsOpen(open => !open);
    onClick(sIdentifier);
  };

  return (
    <Disclosure as="div">
      {(dropdown) => (
        <>
          <Disclosure.Button
            onClick={() => onToggle(dropdown)}
            className={sClass.join(" ") + (isOpen ? " dark:bg-primary-bg bg-primary-bg" : " my-2")}
          >
            <div className={isOpen ? "text-primary-500" : ""}>
              {oIcon}
            </div>
            {!bOnlyIcon && <span className={`text-sm whitespace-pre ml-3 ${isOpen ? "text-primary-500" : ""}`}>{sTitle}</span>}
          </Disclosure.Button>
          <Transition
            show={isOpen}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as="ul" className={bOnlyIcon ? "" : "ml-4"}>
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default MenuDropDown;
