export const PartnersGroupMapper = aItems => {
  const mapper = oServerItem => ({
    id: oServerItem.grsId,
    name: oServerItem.grsNom,
    // Field for combo
    label: oServerItem.grsNom,
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const PartnersGroupDiscountMapper = aItems => {
  const mapper = oServerItem => ({
    id: oServerItem.grsId,
    article: {
      id: oServerItem.artId,
      code: oServerItem.artCod,
      name: oServerItem.artNom,
    },
    discount: oServerItem.porcDto,
    from: oServerItem.desde,
    to: oServerItem.hasta,
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const PartnersGroupDiscountUnMapper = aItems => {
  const mapper = oServerItem => ({
    grsId: oServerItem.id,
    artId: oServerItem.article.id,
    porcDto: oServerItem.discount,
    desde: oServerItem.from,
    hasta: oServerItem.to,
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};
