import React from "react";
import PropsTypes from "prop-types";
import Button from "./Button";
import { BUTTON_CONFIG } from "config/constants/Button";

function ConfirmationModal({
  show: bShow = false,
  onClose = () => {},
  onConfirm = () => {},
  message: sMessage = null,
  title: sTitle = "Confirmación",
  noQuestion: bNoQuestion = false,
  labelConfirm: sLabelConfirm = "Aceptar",
  question: sQuestion = "Seguro que quiere realizar esta acción",
}) {

  return (
    bShow && <>
      <div className="fixed top-0 right-0 h-screen w-screen z-[51] bg-gray-800 opacity-50" />
      <div className="fixed z-[52] top-0 right-0 h-screen w-screen shadow rounded flex items-center justify-center" onClick={onClose} >
        <div className="shadow rounded overflow-hidden max-w-xs md:max-w-md" onClick={oEvent => oEvent.stopPropagation()} >
          <div className="bg-gray-800 dark:bg-gray-900 text-white text-center p-2">
            <span>{sTitle}</span>
          </div>
          <div className="bg-white text-dark dark:bg-dark dark:text-gray-300 p-5">
            {!bNoQuestion && <p className="text-center">¿{sQuestion}?</p>}
            {
              sMessage
              && <p className={`${bNoQuestion ? "text-xs mt-5" : "text-sm"} text-center md:text-left`}>
                {sMessage}
              </p>
            }
            <div className="mt-5 text-center md:text-right flex justify-end">
              <Button
                onClick={onClose}
                className="mr-4"
                type={BUTTON_CONFIG.CLASS.DANGER}
                size={BUTTON_CONFIG.SIZE.SM}
                label="Cancelar"
              />
              <Button
                onClick={onConfirm}
                type={BUTTON_CONFIG.CLASS.SUCCESS}
                size={BUTTON_CONFIG.SIZE.SM}
                label={sLabelConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

ConfirmationModal.propTypes = {
  show: PropsTypes.bool.isRequired,
  title: PropsTypes.string,
  question: PropsTypes.string,
  message: PropsTypes.string,
  onConfirm: PropsTypes.func.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default ConfirmationModal;
