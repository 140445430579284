import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallRolesDelete, apiCallGetAllRoles, apiCallRolesAdd, apiCallRolesFind, apiCallRolesUpdate } from "../api/RoleServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { RolesMapper, RolesUnMapper } from "./RolesMapper";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  aRoles: [],
  oRole: null,
  bStatus: false,
};

export const actionRolesGetAll = createAsyncThunk("roles/getAllRoles", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllRoles();
    oThunk.dispatch(actionDismissLoading());
    return RolesMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionRolesFind = createAsyncThunk("roles/rolesFind", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallRolesFind(nId);
    oThunk.dispatch(actionDismissLoading());
    return RolesMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionRolesDelete = createAsyncThunk("roles/deleteRoles", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallRolesDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Rol eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionRolesGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionRolesSave = createAsyncThunk("roles/saveRoles", async(oRole, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oRoleMapped = RolesUnMapper(oRole);
    const oSuccess = await apiCallRolesAdd(oRoleMapped);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Rol generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionRolesUpdate = createAsyncThunk("roles/rolesUpdate", async(oRole, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oRoleMapped = RolesUnMapper(oRole);
    const oSuccess = await apiCallRolesUpdate(oRole.id, oRoleMapped);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Rol actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const RolesSlice = createSlice({
  name: "RolesSlice",
  initialState: oInitialState,
  reducers: {
    actionRolesClean: oState => {
      oState.bStatus = false;
      oState.oRole = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionRolesGetAll.fulfilled, (oState, oAction) => {
      oState.aRoles = oAction.payload;
    });
    builder.addCase(actionRolesFind.fulfilled, (oState, oAction) => {
      oState.oRole = oAction.payload;
    });
    builder.addCase(actionRolesSave.fulfilled, (oState) => {
      oState.bStatus = true;
    });
    builder.addCase(actionRolesUpdate.fulfilled, (oState) => {
      oState.bStatus = true;
    });
    builder.addCase(actionRolesDelete.fulfilled, (oState) => {
      oState.bStatus = true;
    });
  },
});

export const { actionRolesClean } = RolesSlice.actions;

export const selectRolesState = ({ RolesSlice: oState }) => oState;

export default RolesSlice.reducer;
