import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiCallGetReportLastPurchaseByClient, apiCallGetReportPartnerBySeller, apiCallGetReportPartnersBalance, apiCallGetReportPendingCredit, apiCallGetReportSalesRanking, apiCallGetReportSeller, apiCallGetReportStock, apiCallGetReportTax, apiCallGetReportUtilities } from "../api/ReportServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";

const oInitialState = {
  aData: [],
};

export const actionReportGetSalesRanking = createAsyncThunk("reports/getSalesRanking", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportSalesRanking(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetPendingCredit = createAsyncThunk("reports/getPendingCredit", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportPendingCredit(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetStock = createAsyncThunk("reports/getStock", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportStock(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetPartnersBalance = createAsyncThunk("reports/getPartnerBalances", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportPartnersBalance(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetTax = createAsyncThunk("reports/getTax", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportTax(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetUtilities = createAsyncThunk("reports/getUtilities", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportUtilities(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportGetLastPurchaseByClient = createAsyncThunk("reports/getLastPurchaseByClient", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportLastPurchaseByClient(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportSeller = createAsyncThunk("reports/seller", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportSeller(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReportPartnerBySeller = createAsyncThunk("reports/partnerBySeller", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetReportPartnerBySeller(oData);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const ReportsSlice = createSlice({
  name: "ReportsSlice",
  initialState: oInitialState,
  reducers: {
    actionReportsClean: oState => {
      oState.aData = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(actionReportGetSalesRanking.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetPendingCredit.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetStock.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetPartnersBalance.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetTax.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetUtilities.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportGetLastPurchaseByClient.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportSeller.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
    builder.addCase(actionReportPartnerBySeller.fulfilled, (oState, oAction) => {
      oState.aData = oAction.payload;
    });
  },
});

export const { actionReportsClean } = ReportsSlice.actions;

export const selectReportsState = ({ ReportsSlice: oState }) => oState;

export default ReportsSlice.reducer;
