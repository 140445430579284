import React, { Suspense, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import history from "routes/History";

import Menu from "components/menu/Menu";
import Toast from "components/Toast";
import Loading from "components/Loading";

import { RouteConfig } from "routes/Router";
import { SharedContext } from "context/SharedContext";
import { selectLoading } from "redux/shared/SharedSelector";
import { actionRefreshCredentials } from "redux/auth/AuthActions";

export default function Layout() {
  const oDispatch = useDispatch();
  const bLoading = useSelector(selectLoading);
  const { bDarkTheme, bIsAuthenticated, sToken } = useContext(SharedContext);

  const [, setCalled] = useState(false);
  const [bIsOpen, setOpen] = useState(false);

  useEffect(() => {
    setCalled(bValue => {
      if (!bValue && sToken) oDispatch(actionRefreshCredentials(sToken));
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={bDarkTheme ? "dark" : ""}>
      <HistoryRouter history={history}>
        <Suspense fallback={<Loading open />}>
          <div className="flex bg-gray-50 dark:bg-dark">
            {bIsAuthenticated && <Menu open={bIsOpen} onChange={setOpen} />}
            <div
              className={
                "w-screen transition-all duration-300 pl-14 " +
                (bIsAuthenticated ? (bIsOpen ? "md:pl-64" : "pl-14") : "pl-0")
              }
            >
              <RouteConfig />
            </div>
          </div>
        </Suspense>
        <Loading open={bLoading} />
        <Toast />
      </HistoryRouter>
    </main>
  );
}
