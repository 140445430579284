import oAxiosInstance from "config/Axios";

export const apiCallGetAllLots = oData => {
  return oAxiosInstance.post("lotes/consultar", oData);
};

export const apiCallLotsDelete = sId => {
  return oAxiosInstance.delete(`lotes/${sId}`);
};

export const apiCallLotsSaveOrUpdate = oData => {
  return oAxiosInstance.post("lotes", oData);
};

export const apiCallLotsGetStatusList = () => {
  return oAxiosInstance.get("lotes/estadosdelotes");
};

export const apiCallLotsReCalculate = sId => {
  return oAxiosInstance.get(`lotes/recalcularstock/${sId}`);
};

export const apiCallLotsGetMovements = sId => {
  return oAxiosInstance.get(`lotes/listamovstock/${sId}`);
};

export const apiCallLotsGetMovementTypes = () => {
  return oAxiosInstance.get("lotes/tipoMovStock");
};

export const apiCallLotsSaveMovement = (oData) => {
  return oAxiosInstance.post("lotes/nuevomovstock", oData);
};

export const apiCallLotsSuggest = oData => {
  return oAxiosInstance.post("lotes/consultarapida", oData);
};
