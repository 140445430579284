import React, { memo, useEffect, useMemo, useState } from "react";
import ReactAutosuggest from "react-autosuggest";
import Input from "./Input";

import Text from "./Text";

const Autosuggest = memo(({
  onSelect,
  disabled: bDisabled = false,
  label: sLabel = null,
  clean: bClean = false,
  value: sCustomValue = "",
  placeholder: sPlaceHolder,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  className: sClassName = null,
  suggestions: aSuggestions = [],
  onCustomRenderSuggestion = null,
  onCustomGetSuggestionValue = null,
  containerClassName: sContainerClassName,
}) => {
  const [sValue, setValue] = useState(sCustomValue);

  useEffect(() => {
    setValue(sCustomValue);
  }, [sCustomValue]);

  useEffect(() => {
    setValue("");
  }, [bClean]);

  const aClassName = [
    "text-gray-800 dark:text-white",
    "border-input",
    "rounded-md",
    "px-2",
    "py-2",
    "bg-gray-100 dark:bg-gray-700",
    "mt-1",
    "text-md",
  ];

  if (sClassName) aClassName.push(sClassName);

  const onChange = (oEvent, { newValue: sValue }) => {
    setValue(sValue);
    if (sValue === "") onSelect(null);
  };

  const oInputProps = useMemo(() => ({
    onChange,
    value: sValue,
    placeholder: sPlaceHolder || "Sugerencias",
    className: aClassName.join(" ") || null,
  }), [sValue, aClassName]);

  const getSuggestionValue = (oItem) => {
    onSelect(oItem);
    return oItem.label;
  };

  const onRenderSuggestions = (oItem) => {
    return <>
      <div key={`suggestion_${oItem?.id}`} className='suggestion-item'>
        <span>{oItem?.label}</span>
      </div>
    </>;
  };

  return <div className={sContainerClassName}>
    {sLabel ? (
      <Text size={"text-lg"}>
        {sLabel}
      </Text>
    ) : null}
    {
      !bDisabled ?
        <ReactAutosuggest
          inputProps={oInputProps}
          suggestions={aSuggestions}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSuggestion={onCustomRenderSuggestion || onRenderSuggestions}
          getSuggestionValue={onCustomGetSuggestionValue || getSuggestionValue}
        />
        : <Input
          disabled
          {...oInputProps}
        />
    }
  </div>;
});

Autosuggest.displayName = "Autosuggest";

export default Autosuggest;
