export const DocumentsMapper = aDocuments => {
  const mapDocuments = oServerDocument => ({
    id: oServerDocument.docId,
    isUpdatable: oServerDocument.docEditable === 1,
    code: oServerDocument.docNro,
    total: oServerDocument.docTotal,
    rounding: oServerDocument.docRed,
    tax: oServerDocument.docTotIVA,
    subtotal: oServerDocument.docSubTotal,
    status: oServerDocument.docEstado,
    date: oServerDocument.docFch,
    expiration: oServerDocument.docFchVenc,
    observations: oServerDocument.docObs,
    discount: oServerDocument.docTotDto,
    type: {
      id: oServerDocument.tipoDocID,
      name: oServerDocument.tipoDocNom,
    },
    currency: {
      id: oServerDocument.monID,
      code: oServerDocument.monSim,
      change: oServerDocument.monTC,
    },
    cae: {
      status: +oServerDocument.enviadoDGI ? true : false,
      number: oServerDocument.caeNro,
      link: oServerDocument.caeLinkVerif,
    },
    partner: {
      id: oServerDocument.socID,
      name: oServerDocument.docSocNom,
      rut: oServerDocument.docSocRUT,
      address: oServerDocument.docSocDir,
      state: oServerDocument.docSocDptoNom,
      idPriceList: oServerDocument.docSocLisId,
      discount: oServerDocument.docSocDtoGlobal,
      seller: {
        id: oServerDocument.venId,
        name: oServerDocument.venNom,
        value: oServerDocument.venId,
        label: oServerDocument.venNom,
      },
    },
    seller: {
      id: oServerDocument.venId,
      name: oServerDocument.venNom,
      value: oServerDocument.venId,
      label: oServerDocument.venNom,
    },
    lines: DocumentsLineMapper(oServerDocument.lineas),
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(mapDocuments);
  if ((typeof aDocuments).toString() === "object") return mapDocuments(aDocuments);
  return null;
};

export const DocumentsUnMapper = aDocuments => {
  const unMapDocuments = oDocument => ({
    docID: oDocument.id || "",
    docFch: oDocument.date,
    docFchVenc: oDocument.expiration,
    tipoDocId: oDocument.type,
    docNro: oDocument.code,
    socID: oDocument.partner?.id,
    docSocNom: oDocument.partner?.name,
    monID: oDocument.currency?.id || null,
    monTC: oDocument.currency?.change || 0,
    docSubTotal: oDocument.subtotal || 0,
    docTotIVA: oDocument.totalTax || 0,
    docTotDto: oDocument.discount || 0,
    docTotal: oDocument.total || 0,
    docObs: oDocument.observations || "",
    docRed: oDocument.rounding || 0,
    venId: oDocument.seller?.id || 0,
    lineas: DocumentsLineUnMapper(oDocument.lines),
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(unMapDocuments);
  if ((typeof aDocuments).toString() === "object") return unMapDocuments(aDocuments);
  return null;
};

export const DocumentsLineMapper = aLines => {
  if (!aLines) return null;
  const mapDocumentsLine = oServerLine => ({
    id: oServerLine.docId,
    tax: oServerLine.docLinImpIVA,
    discount: oServerLine.docLinImpDto,
    discountPercent: oServerLine.docLinPorcDto,
    taxId: oServerLine.ivaID,
    number: oServerLine.docLinNro,
    quantity: oServerLine.docLinCnt,
    unitPrice: oServerLine.docLinPrc,
    subtotal: oServerLine.docLinSubTotal,
    total: oServerLine.docLinTotal,
    indicator: oServerLine.indFact,
    article: {
      id: oServerLine.artID,
      name: oServerLine.docLinArtNom,
      code: oServerLine.docLinArtCod,
    },
    unit: {
      id: oServerLine.undId,
      code: oServerLine.undNom,
    },
    lot: oServerLine.loteCod,
  });

  if (Array.isArray(aLines)) return aLines.map(mapDocumentsLine);
  if ((typeof aLines).toString() === "object") return mapDocumentsLine(aLines);
  return null;
};

export const DocumentsLineUnMapper = aLines => {
  if (!aLines) return null;
  const mapOrdersLine = oLine => ({
    docID: oLine.id || "",
    docLinNro: oLine.number,
    artID: oLine.article?.id,
    DocLinArtCod: oLine.article?.code,
    DocLinArtNom: oLine.article?.name,
    indFact: oLine.indicator,
    docLinCnt: oLine.quantity,
    undId: oLine.unit?.id,
    docLinPrc: oLine.unitPrice,
    docLinCos: 0,
    ivaID: oLine.taxId,
    docLinImpIVA: oLine.tax,
    docLinImpDto: oLine.discount,
    docLinPorcDto: oLine.discountPercent,
    docLinSubTotal: oLine.subtotal,
    docLinTotal: oLine.total,
    lotecod: oLine.lot,
  });

  if (Array.isArray(aLines)) return aLines.map(mapOrdersLine);
  if ((typeof aLines).toString() === "object") return mapOrdersLine(aLines);
  return null;
};

export const DocumentsCsvResponseMapper = oServerData => {
  const oTitles = oServerData;
  const aRecords = oServerData.lineas;
  delete oTitles.lineas;
  const aTitles = [];
  const oExample = aRecords[0] ?? null;
  if (!oExample)
    return {
      titles: aTitles,
      records: [],
    };
  const aKeys = Object.keys(oExample).map((key) => key);
  const aLabels = Object.keys(oTitles).map((key) => oTitles[key]);
  for (let nIndex = 0; nIndex < aKeys.length; nIndex++) {
    aTitles.push({
      key: aKeys[nIndex],
      label: aLabels[nIndex],
    });
  }
  return {
    headers: aTitles,
    records: aRecords,
  };
};

export const DocumentsRelationsMapper = aServerItems => {
  const mapper = oServerItem => ({
    id: oServerItem.id,
    type: oServerItem.tipo,
    date: oServerItem.fecha,
    identifier: oServerItem.nroInterno,
    cae: oServerItem.cae,
    amount: oServerItem.importe,
  });

  if (Array.isArray(aServerItems)) return aServerItems.map(mapper);
  if ((typeof aServerItems).toString() === "object") return mapper(aServerItems);
  return null;
};
