export const UsersMapper = aUsers => {
  const mapUsers = oServerUser => ({
    id: oServerUser.usrId,
    username: oServerUser.usrUsuario,
    name: oServerUser.usrNombreCompleto,
    email: oServerUser.usrCorreoUsuario,
    idRol: oServerUser.usrRolUsuario,
    token: oServerUser.usrToken,
    status: oServerUser.usrEstado,
    isActive: Boolean(oServerUser.usrActivo),
    createdAt: oServerUser.usrFchCreacion,
    config: {
      company: {
        name: oServerUser.usrConfigNombre,
        image: oServerUser.usrConfigLogo,
      },
      idCurrency: Number.parseInt(oServerUser.usrConfigMonId),
    },
  });

  if (Array.isArray(aUsers)) return aUsers.map(mapUsers);
  if ((typeof aUsers).toString() === "object") return mapUsers(aUsers);
  return null;
};

export const UsersUnMapper = aUsers => {
  const unMapUsers = oUser => ({
    usrId: oUser.id || 0,
    usrUsuario: oUser.username,
    usrNombreCompleto: oUser.name,
    usrCorreoUsuario: oUser.email,
    usrRolUsuario: oUser.rol?.id || oUser.id_rol || null,
    usrEstado: oUser.status || null,
    usrClave: oUser.password,
    usrActivo: oUser.isActive,
  });

  if (Array.isArray(aUsers)) return aUsers.map(unMapUsers);
  if ((typeof aUsers).toString() === "object") return unMapUsers(aUsers);
  return null;
};


export const UsersUnMapperPassword = oData => ({
  usrUsuario: oData.sUsername,
  usrClaveActual: oData.sPasswordCurrent,
  usrClaveNueva: oData.sPasswordNew,
});
