import React, { useEffect, useState } from "react";
import { FaPlus, FaSave, FaTrashAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";

import {
  Text,
  Table,
  Button,
  ScreenContainer,
  Input,
  ConfirmationModal,
  CustomModal,
} from "components";

import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { actionSellersCleanAction, actionSellersDelete, actionSellersGetAll, actionSellersSave, actionSellersUpdate, selectSellersState } from "./redux";
import { actionMessage } from "redux/shared/SharedSlice";
import { SysValidations } from "config/lang";

const SellersList = () => {
  const oDispatch = useDispatch();
  const { aSellers, bAction } = useSelector(selectSellersState);

  const [sFilter, setFilter] = useState("");
  const [oItemToDelete, setItemToDelete] = useState(null);
  const [bIsOpenModal, setIsOpenModal] = useState(false);
  const [nId, setId] = useState(null);
  const [sName, setName] = useState("");
  const [sCommission, setCommission] = useState("");

  useEffect(() => {
    if (!aSellers) oDispatch(actionSellersGetAll());
  }, []);

  useEffect(() => {
    if (bAction) {
      onClose();
      oDispatch(actionSellersCleanAction());
    }
  }, [bAction]);

  const onSelect = (oItem) => {
    setId(oItem.id);
    setName(oItem.name);
    setCommission(oItem.commission);
    setIsOpenModal(true);
  };

  const onConfirmDelete = () => {
    oDispatch(actionSellersDelete(oItemToDelete.id));
  };

  const onSave = () => {
    const oData = {
      id: nId,
      name: sName.trim(),
      commission: +sCommission,
    };

    if (!oData.name)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Nombre") }));
    if (!oData.commission)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Comisión") }));
    if (oData.commission < 0 || oData.commission > 100 || isNaN(oData.commission))
      return oDispatch(actionMessage({ message: SysValidations.fieldInvalid("Comisión") }));

    if (nId)
      return oDispatch(actionSellersUpdate(oData));
    oDispatch(actionSellersSave(oData));
  };

  const onClose = () => {
    setId(null);
    setName("");
    setCommission("");
    setIsOpenModal(false);
    setItemToDelete(null);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Vendedores
        </Text>
        <div className="gap-2 md:flex">
          <Button
            label="Nuevo"
            onClick={() => setIsOpenModal(true)}
            containerClassName="ml-auto"
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder="Filtro"
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid="SellersList">
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-2 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col scope="col" className="px-2 py-3 text-center">
                Comisión
              </Table.Col>
              <Table.Col scope="col"></Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aSellers?.length > 0 ? (
              aSellers.map((oItem, index) => (
                <Table.Row key={`${oItem.id}_${index}`} onClick={() => onSelect(oItem)} className="cursor-pointer">
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {oItem.id}
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center hidden md:table-cell">
                    <Highlighter
                      autoEscape={true}
                      searchWords={[sFilter]}
                      textToHighlight={oItem.name}
                    />
                  </Table.Col>
                  <Table.Col className="px-2 py-4 text-center">
                    {oItem.commission}
                  </Table.Col>
                  <Table.Col className="px-0 py-4 text-center hidden md:table-cell">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setItemToDelete(oItem);
                      }}
                      size={BUTTON_CONFIG.SIZE.SM}
                      type={BUTTON_CONFIG.CLASS.DANGER}
                      icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                    />
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={3}>
                  <em>
                    No se encontraron vendedores
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        title="Eliminar vendedor"
        show={!!oItemToDelete}
        onConfirm={onConfirmDelete}
        onClose={() => setItemToDelete(null)}
        message="Esta acción no se puede revertir"
        question={`Está seguro que desea eliminar el vendedor "${oItemToDelete?.name}"`}
      />

      <CustomModal show={bIsOpenModal} onClose={onClose} title="Nuevo vendedor">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <Input
            label="Nombre"
            value={sName}
            onChange={setName}
            className="w-full"
            placeholder="Nombre"
          />
          <Input
            label="Comisión"
            value={sCommission}
            placeholder="Comisión"
            onChange={setCommission}
            className="w-full text-right"
          />
        </div>
        <div className="col-span-2 md:text-right flex justify-center mt-5 gap-3 md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            onClick={() => onClose()}
          />
          <Button
            label="Guardar"
            onClick={onSave}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
    </ScreenContainer>
  );
};

export default SellersList;
