const Fields = {
  nro: "N°",
  code: "Nro. Interno",
  codeOptional: "Nro. Interno (Opcional)",
  partner: "Socio",
  date: "Fecha",
  currency: "Moneda",
  currencyCode: "Código de moneda",
  currencyChange: "Cambio",
  observations: "Observaciones",
  total: "Total",
  subtotal: "Subtotal",
  tax: "IVA",
  status: "Estado",
  taxStatus: "Estado DGI",
  headline: "Titular",
  number: "Número de pedido",
  cfeNumber: "Número de CFE",
  taxOrInternalNumber: "Número interno o CAE",
  quantity: "Cantidad",
  discountPercent: "Descuento (%)",
  hour: "Hora",
  type: "Tipo",
  caeNumber: "Número CAE",
  caeLink: "Link CAE",
  rut: "R.U.T.",
  address: "Dirección",
  state: "Departamento",
};

export default Fields;
