import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFilePdf } from "react-icons/bs";
import printJS from "print-js";
import oDayJs from "dayjs";

import {
  Text,
  Table,
  ScreenContainer,
  DateInput,
  ArticlesSuggestions,
  ComboBox,
  LotsSuggestions,
  Button,
  ConfirmationModal,
} from "components";

import { CastDate } from "utils/SharedUtils";
import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";

import {
  actionProductionsChangeArticle,
  actionProductionsChangeDateFrom,
  actionProductionsChangeDateTo,
  actionProductionsChangeLot,
  actionProductionsChangePage,
  actionProductionsChangeStatus,
  actionProductionsCleanFile,
  actionProductionsGetAll,
  actionProductionsGetReport,
  actionProductionsGetStatus,
  selectProductionsState,
} from "./redux";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ProductionList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();
  const {
    aProductions,
    nPage,
    nPages,
    nRecords,
    dDateTo,
    dDateFrom,
    oArticle,
    oLot,
    oStatus,
    aStatus,
    sFile,
  } = useSelector(selectProductionsState);

  const [sProductionToPrint, setProductionToPrint] = useState(null);

  useEffect(() => {
    oDispatch(actionProductionsGetAll());
    if (aStatus?.length === 0)
      oDispatch(actionProductionsGetStatus());
  }, []);

  useEffect(() => {
    if (sFile) {
      printJS({
        base64: true,
        printable: sFile,
        modalMessage: "Cargando...",
        documentTitle: `Producción ${sProductionToPrint}`,
        onPrintDialogClose: () => {
          setProductionToPrint(null);
          oDispatch(actionProductionsCleanFile());
        },
      });
    }
  }, [sFile]);

  const onChangeDateFrom = (dDate) => {
    oDispatch(actionProductionsChangeDateFrom(oDayJs(dDate).toString()));
    oDispatch(actionProductionsChangePage(1));
    oDispatch(actionProductionsGetAll());
  };

  const onChangeDateTo = (dDate) => {
    oDispatch(actionProductionsChangeDateTo(oDayJs(dDate).toString()));
    oDispatch(actionProductionsChangePage(1));
    oDispatch(actionProductionsGetAll());
  };

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionProductionsChangePage(nSelectedPage));
    oDispatch(actionProductionsGetAll());
  };

  const onChangeArticle = (oNewArticle) => {
    oDispatch(actionProductionsChangeArticle(oNewArticle));
    oDispatch(actionProductionsChangePage(1));
    oDispatch(actionProductionsGetAll());
  };

  const onChangeLot = (oNewLot) => {
    oDispatch(actionProductionsChangeLot(oNewLot));
    oDispatch(actionProductionsChangePage(1));
    oDispatch(actionProductionsGetAll());
  };

  const onChangeStatus = (oNewStatus) => {
    oDispatch(actionProductionsChangeStatus(oNewStatus));
    oDispatch(actionProductionsChangePage(1));
    oDispatch(actionProductionsGetAll());
  };

  const onView = (oItem) => oNavigator(`/productions/${oItem.id}`);

  const onPrintReport = () => oDispatch(actionProductionsGetReport(sProductionToPrint));

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Lista de producciones
        </Text>
        <div className="gap-2 md:flex">
          <Button
            label="Nuevo"
            onClick={() => oNavigator("/productions/new")}
            containerClassName="ml-auto"
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="md:col-span-3 grid grid-cols-2 gap-5">
          <div className="relative col-span-2 md:col-span-1 gap-5">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <span className="text-gray-500 dark:text-gray-400 text-sm">Desde</span>
            </div>
            <DateInput
              value={dDateFrom}
              onChange={onChangeDateFrom}
              placeholder="Fecha desde"
              containerClassName="rounded-lg overflow-hidden ml-12"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="relative col-span-2 md:col-span-1 gap-5">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <span className="text-gray-500 dark:text-gray-400 text-sm">Hasta</span>
            </div>
            <DateInput
              value={dDateTo}
              onChange={onChangeDateTo}
              placeholder="Fecha hasta"
              containerClassName="rounded-lg overflow-hidden ml-12"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <ArticlesSuggestions className="w-full" value={oArticle?.name || ""} onSelect={onChangeArticle} />
        <LotsSuggestions className="w-full" value={oLot?.code || ""} onSelect={onChangeLot} />
        <ComboBox
          options={aStatus}
          emptyLabel="Todos"
          selected={oStatus}
          onChange={onChangeStatus}
          className="w-full"
          containerClassName="items-end flex"
        />
      </section>
      <section className="py-5">
        <Table data-testid={"DocumentsTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Nro Producción
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Fecha de creación
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Producto fabricado
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Lote
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Estado
              </Table.Col>
              <Table.Col />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aProductions?.length > 0 ? (
              aProductions.map((oItem, index) => (
                <Table.Row key={`${oItem.id}_${index}`} onClick={() => onView(oItem)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {oItem.id}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {CastDate(oItem.date)}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center">
                    {oItem.article?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {oItem.lot?.label ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {oItem.status?.label ?? "-"}
                  </Table.Col>
                  <Table.Col className="text-center hidden md:table-cell">
                    <Button
                      className="m-auto"
                      size={BUTTON_CONFIG.SIZE.SM}
                      type={BUTTON_CONFIG.CLASS.SUCCESS}
                      onClick={(oEvent) => { oEvent.stopPropagation(); setProductionToPrint(oItem.id); }}
                      icon={<BsFilePdf size={18} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                    />
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={8}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="5" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        title="Emitir informe"
        onConfirm={onPrintReport}
        show={!!sProductionToPrint}
        onClose={() => setProductionToPrint(null)}
        message="Esta acción puede tardar unos minutos"
        question={`Seguro desea emitir el informe de la producción "${sProductionToPrint}"`}
      />
    </ScreenContainer>
  );
};

export default ProductionList;
