import { createSlice } from "@reduxjs/toolkit";

import { actionGetManual, actionLogin, actionRefreshCredentials } from "redux/auth/AuthActions";

const oInitialState = {
  oUser: null,
  sManual: null,
  bLogout: false,
};

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: oInitialState,
  reducers: {
    actionLogout: oState => {
      oState.oUser = null;
      oState.bLogout = true;
    },
    actionAuthClean: oState => {
      oState.sManual = null;
      oState.bLogout = false;
    },
  },
  extraReducers: oBuilder => {
    oBuilder.addCase(actionLogin.fulfilled, (oState, oAction) => {
      oState.oUser = oAction.payload;
    });
    oBuilder.addCase(actionRefreshCredentials.fulfilled, (oState, oAction) => {
      oState.oUser = oAction.payload;
    });
    oBuilder.addCase(actionGetManual.fulfilled, (oState, oAction) => {
      oState.sManual = oAction.payload;
    });
  },
});

export const { actionLogout, actionAuthClean } = AuthSlice.actions;

export default AuthSlice.reducer;
