import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ReportTypes } from "./constants/Reports";
import { ScreenContainer } from "components";

import { actionReportsClean } from "./redux";
import SalesRankingReport from "./components/SalesRankingReport";
import PendingDocumentsReport from "./components/PendingDocumentsReport";
import StockReport from "./components/StockReport";
import PartnersBalanceReport from "./components/PartnersBalanceReport";
import TaxReport from "./components/TaxReport";
import UtilitiesReport from "./components/UtilitiesReport";
import LastPurchaseByClientReport from "./components/LastPurchaseByClientReport";
import SellerReport from "./components/SellerReport";
import PartnerBySellerReport from "./components/PartnerBySellerReport";

const Reports = () => {
  const oDispatch = useDispatch();
  const { type: sType } = useParams();

  useEffect(() => {
    return () => {
      oDispatch(actionReportsClean());
    };
  }, []);

  useEffect(() => {
    oDispatch(actionReportsClean());
  }, [sType]);

  const renderContent = () => {
    switch (sType) {
      case ReportTypes.STOCK:
        return <StockReport />;
      case ReportTypes.PENDING_CREDIT:
        return <PendingDocumentsReport />;
      case ReportTypes.PARTNERS_BALANCE:
        return <PartnersBalanceReport />;
      case ReportTypes.TAX:
        return <TaxReport />;
      case ReportTypes.UTILITIES:
        return <UtilitiesReport />;
      case ReportTypes.LAST_PURCHASE_BY_CLIENT:
        return <LastPurchaseByClientReport />;
      case ReportTypes.SELLER:
        return <SellerReport />;
      case ReportTypes.PARTNER_BY_SELLER:
        return <PartnerBySellerReport />;
      case ReportTypes.SALES_RANKING:
      default:
        return <SalesRankingReport />;
    }
  };

  return (
    <ScreenContainer className="pb-5">
      {renderContent()}
    </ScreenContainer>
  );
};

export default Reports;
