import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { LocationsMapper } from "./LocationsMapper";
import { apiCallGetAllLocations } from "../api/LocationServices";

const oInitialState = {
  aLocations: [],
  bStatus: false,
};

export const actionLocationsGetAll = createAsyncThunk("locations/getAllLocations", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllLocations();
    oThunk.dispatch(actionDismissLoading());
    return LocationsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const LocationsSlice = createSlice({
  name: "LocationsSlice",
  initialState: oInitialState,
  reducers: {
    actionLocationsClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionLocationsGetAll.fulfilled, (oState, oAction) => {
      oState.aLocations = oAction.payload;
    });
  },
});

export const { actionLocationsClean } = LocationsSlice.actions;

export default LocationsSlice.reducer;
