export const BUTTON_CONFIG = {
  CLASS: {
    PRIMARY: "primary",
    SUCCESS: "success",
    DANGER: "danger",
    OUTLINE: "outline",
  },
  SIZE: {
    XS: "xs",
    SM: "sm",
    MD: "md",
    LG: "lg",
    XL: "xl",
  },
  POSITION: {
    LEFT: "left",
    RIGHT: "right",
  },
};
