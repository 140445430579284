import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  partner: "Socio",
  listPartners: "Listado de socios",
  createPartner: "Crear socio",
  editPartner: "Editar socio",
  partnerData: "Datos del socio",
  partnerBalance: "Balance del socio",
  openingBalances: "Saldos iniciales",
  amount: "Importe",
  currentAccount: "Cuenta corriente",
  debtor: "Deudor",
  creditor: "Acreedor",
  currentBalance: "Saldo actual",
  includeBranches: "Incluir sucursales",
  statementAccount: "Estado de cuenta",
};

export default Labels;
