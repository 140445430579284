import oAxiosInstance from "config/Axios";

export const apiCallGetAllArticles = (oData) => {
  return oAxiosInstance.post("articulos/consultar", oData);
};

export const apiCallArticlesGetFormula = nId => {
  return oAxiosInstance.get(`articulos/formula/${nId}`);
};

export const apiCallArticlesSave = oData => {
  return oAxiosInstance.post("articulos", oData);
};

export const apiCallArticlesUpdate = (nId, oData) => {
  return oAxiosInstance.put(`articulos/${nId}`, oData);
};

export const apiCallArticlesDelete = (nId) => {
  return oAxiosInstance.delete(`articulos/${nId}`);
};

export const apiCallArticlesSuggest = oData => {
  return oAxiosInstance.post("articulos/consultarapida", oData);
};

export const apiCallArticlesAddPrice = (nArticleId, oData) => {
  return oAxiosInstance.post(`articulos/actualizarprecio/${nArticleId}`, oData);
};

export const apiCallArticlesPrices = (nArticleId) => {
  return oAxiosInstance.get(`articulos/precios/${nArticleId}`);
};

export const apiCallArticlesGetLineInfo = (oData) => {
  return oAxiosInstance.post("articulos/preciolineadoc", oData);
};

export const apiCallArticlesReCalculateStock = nId => {
  return oAxiosInstance.get(`articulos/recalcularstock/${nId}`);
};

export const apiCallArticlesGetHistoricalStock = oData => {
  return oAxiosInstance.post("articulos/historia", oData);
};

export const apiCallArticlesGetTypes = () => {
  return oAxiosInstance.get("articulos/tiposdearticulos");
};
