export const PricesMapper = aPrices => {
  const mapPrices = oServerPrice => ({
    id: oServerPrice.lisId,
    name: oServerPrice.lisDsc,
    label: oServerPrice.lisDsc,
  });

  if (Array.isArray(aPrices)) return aPrices.map(mapPrices);
  if ((typeof aPrices).toString() === "object") return mapPrices(aPrices);
  return null;
};

export const PricesUnMapper = aPrices => {
  const unMapPrices = oPrice => ({
    lisId: oPrice.id || 0,
    lisDsc: oPrice.name,
  });

  if (Array.isArray(aPrices)) return aPrices.map(unMapPrices);
  if ((typeof aPrices).toString() === "object") return unMapPrices(aPrices);
  return null;
};

export const PriceArticlesMapper = aPrices => {
  const mapPrices = oServerPrice => ({
    article: {
      id: oServerPrice.artId,
      name: oServerPrice.artNom,
    },
    price: oServerPrice.precio,
    taxInclusive: oServerPrice.incluyeImpuestos,
    currency: {
      id: oServerPrice.monId,
      code: oServerPrice.monSim,
    },
  });
  if (Array.isArray(aPrices)) return aPrices.map(mapPrices);
  if ((typeof aPrices).toString() === "object") return mapPrices(aPrices);
  return null;
};

export const PriceItemMapper = items => {
  const mapItems = oServerItem => ({
    article: {
      id: oServerItem.artId,
      name: oServerItem.artNom,
      code: oServerItem.artCod,
    },
    family: oServerItem.famNom,
    price: oServerItem.precio,
    taxInclusive: oServerItem.incluyeImpuestos,
    taxRate: oServerItem.ivaTasa,
    taxId: oServerItem.ivaVtaId,
    currency: {
      id: oServerItem.monId,
      code: oServerItem.monSim,
    },
  });
  if (Array.isArray(items)) return items.map(mapItems);
  if ((typeof items).toString() === "object") return mapItems(items);
  return null;
};
