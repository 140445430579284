import oAxiosInstance from "config/Axios";

export const apiCallGetAllReceipts = oData => {
  return oAxiosInstance.post("recibos/consultar", oData);
};

export const apiCallReceiptsExportToCsv = oData => {
  return oAxiosInstance.post("recibos/exportarcsv", oData);
};

export const apiCallReceiptsFind = nId => {
  return oAxiosInstance.get(`recibos/consultarCabezal/${nId}`);
};

export const apiCallReceiptsFindLines = nId => {
  return oAxiosInstance.get(`recibos/consultarLineas/${nId}`);
};

export const apiCallReceiptsSave = oData => {
  return oAxiosInstance.post("recibos", oData);
};

export const apiCallReceiptsCancel = nId => {
  return oAxiosInstance.get(`recibos/anular/${nId}`);
};
