import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaFile, FaPlus, FaSave } from "react-icons/fa";
import printJS from "print-js";
import oDayJs from "dayjs";

import {
  Text,
  Table,
  Button,
  ComboBox,
  ScreenContainer,
  DateInput,
  CustomModal,
  Input,
  ConfirmationModal,
} from "components";

import { SysValidations } from "config/lang";
import { DATES_FORMATS } from "config/constants/Dates";
import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { CURRENCY_VALUES } from "config/constants/Currency";

import { CastDate } from "utils/SharedUtils";
import { actionMessage } from "redux/shared/SharedSlice";

import {
  actionCashRegisterChangeCategory,
  actionCashRegisterChangeCurrency,
  actionCashRegisterChangeDateFrom,
  actionCashRegisterChangeDateTo,
  actionCashRegisterChangePage,
  actionCashRegisterChangePaymentMethod,
  actionCashRegisterGetCategories,
  actionCashRegisterGetPaymentMethods,
  actionCashRegisterSaveOrUpdate,
  actionCashRegisterGetAll, selectCashRegisterState, actionCashRegisterGetReport, actionCashRegisterCleanFile,
} from "./redux";

const CashRegisterList = () => {
  const oDispatch = useDispatch();

  const {
    aRegisters,
    nPage,
    nPages,
    nRecords,
    dDateTo,
    dDateFrom,
    oCurrency,
    oPaymentMethod,
    aPaymentMethods,
    oCategory,
    aCategories,
    sFile,
  } = useSelector(selectCashRegisterState);

  const [oItemSelected, setItemSelected] = useState(null);
  const [bIsOpenModal, setIsOpenModal] = useState(false);
  const [dDate, setDate] = useState(new Date());
  const [sHour, setHour] = useState(CastDate(new Date(), DATES_FORMATS.TIME_DEFAULT));
  const [sAmount, setAmount] = useState("");
  const [oModalCurrency, setModalCurrency] = useState(CURRENCY_VALUES[1]);
  const [oModalCategory, setModalCategory] = useState(null);
  const [oModalPaymentMethod, setModalPaymentMethod] = useState(null);
  const [sDetails, setDetails] = useState("");
  const [isGetReport, setIsGetReport] = useState(false);

  useEffect(() => {
    oDispatch(actionCashRegisterGetAll());
    if (aCategories.length === 0)
      oDispatch(actionCashRegisterGetCategories());
    if (aPaymentMethods.length === 0)
      oDispatch(actionCashRegisterGetPaymentMethods());
  }, []);

  useEffect(() => {
    if (sFile) {
      printJS({
        base64: true,
        printable: sFile,
        modalMessage: "Cargando...",
        documentTitle: "Caja",
        onPrintDialogClose: () => {
          oDispatch(actionCashRegisterCleanFile());
        },
      });
    }
  }, [sFile]);

  const getCategories = () => {
    const all = {
      id: 0,
      value: 0,
      name: "Todos",
      label: "Todos",
    };
    return [all, ...aCategories];
  };

  const onChangeDateFrom = (dDate) => {
    oDispatch(actionCashRegisterChangeDateFrom(oDayJs(dDate).toString()));
    oDispatch(actionCashRegisterChangePage(1));
    oDispatch(actionCashRegisterGetAll());
  };

  const onChangeDateTo = (dDate) => {
    oDispatch(actionCashRegisterChangeDateTo(oDayJs(dDate).toString()));
    oDispatch(actionCashRegisterChangePage(1));
    oDispatch(actionCashRegisterGetAll());
  };

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionCashRegisterChangePage(nSelectedPage));
    oDispatch(actionCashRegisterGetAll());
  };

  const onChangeCurrency = (oNewCurrency) => {
    oDispatch(actionCashRegisterChangeCurrency(oNewCurrency));
    oDispatch(actionCashRegisterChangePage(1));
    oDispatch(actionCashRegisterGetAll());
  };

  const onChangeCategory = (oNewCategory) => {
    oDispatch(actionCashRegisterChangeCategory(oNewCategory));
    oDispatch(actionCashRegisterChangePage(1));
    oDispatch(actionCashRegisterGetAll());
  };

  const onChangePaymentMethod = (oNewPaymentMethod) => {
    oDispatch(actionCashRegisterChangePaymentMethod(oNewPaymentMethod));
    oDispatch(actionCashRegisterChangePage(1));
    oDispatch(actionCashRegisterGetAll());
  };

  const onSave = () => {
    const oData = {
      id: oItemSelected?.id ?? 0,
      date: CastDate(dDate, DATES_FORMATS.MYSQL_SERVER_TIMESTAMPS_DEFAULT),
      hour: sHour ?? CastDate(new Date(), DATES_FORMATS.TIME_DEFAULT),
      amount: +sAmount,
      category: oModalCategory,
      currency: oModalCurrency,
      paymentMethod: oModalPaymentMethod,
      details: sDetails?.trim() ?? "",
    };

    if (!oData.category)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Rubro") }));
    if (!oData.paymentMethod)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Forma de pago") }));
    if (!oData.currency)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Moneda") }));
    if (!oData.amount || Number.isNaN(oData.amount))
      return oDispatch(actionMessage({ message: SysValidations.fieldInvalid("Importe") }));
    oDispatch(actionCashRegisterSaveOrUpdate(oData));
    onClose();
  };

  const onOpenAddModal = () => {
    setIsOpenModal(true);
  };

  const onClose = () => {
    setIsOpenModal(false);
    setDate(new Date());
    setHour(CastDate(new Date(), DATES_FORMATS.TIME_DEFAULT));
    setAmount("");
    setModalCategory(null);
    setModalPaymentMethod(null);
    setModalCurrency(CURRENCY_VALUES[1]);
    setDetails("");
  };

  const onSelect = (oItem) => {
    setItemSelected(oItem);
    setDate(oItem.date);
    setHour(oItem.hour);
    setAmount(oItem.amount ?? "");
    setModalCategory(oItem.category);
    setModalPaymentMethod(oItem.paymentMethod);
    setModalCurrency(CURRENCY_VALUES.find(item => item.value === oItem.currency?.id));
    setDetails(oItem.details);
    setIsOpenModal(true);
  };

  const getModalTitle = () => {
    if (bIsOpenModal) return "Nuevo movimiento de caja";
    return "Editar movimiento de caja";
  };

  const getReport = () => {
    if (!isGetReport)
      return setIsGetReport(true);
    setIsGetReport(false);
    oDispatch(actionCashRegisterGetReport());
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Movimientos de caja
        </Text>
        <div className="gap-2 md:flex justify-end">
          <Button
            label="Informe de caja"
            onClick={getReport}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaFile size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
          <Button
            label="Nuevo"
            onClick={onOpenAddModal}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="md:col-span-3 grid grid-cols-2 gap-5">
          <div className="relative col-span-2 md:col-span-1 gap-5">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <span className="text-gray-500 dark:text-gray-400 text-sm">Desde</span>
            </div>
            <DateInput
              value={dDateFrom}
              onChange={onChangeDateFrom}
              placeholder="Fecha desde"
              containerClassName="rounded-lg overflow-hidden ml-12"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="relative col-span-2 md:col-span-1 gap-5">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <span className="text-gray-500 dark:text-gray-400 text-sm">Hasta</span>
            </div>
            <DateInput
              value={dDateTo}
              onChange={onChangeDateTo}
              placeholder="Fecha hasta"
              containerClassName="rounded-lg overflow-hidden ml-12"
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <ComboBox
          selected={oCurrency}
          onChange={onChangeCurrency}
          label="Moneda"
          emptyLabel="Todas"
          options={CURRENCY_VALUES}
        />
        <ComboBox
          selected={oCategory}
          onChange={onChangeCategory}
          label="Rubro"
          emptyLabel="Todos"
          options={getCategories()}
        />
        <ComboBox
          selected={oPaymentMethod}
          onChange={onChangePaymentMethod}
          label="Formas de pago"
          emptyLabel="Todos"
          options={aPaymentMethods}
        />
      </section>
      <section className="py-5">
        <Table data-testid={"DocumentsTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Rubro
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Forma de pago
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Moneda
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-right">
                Importe
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aRegisters?.length > 0 ? (
              aRegisters.map((oItem, index) => (
                <Table.Row key={`${oItem.id}_${index}`} onClick={() => onSelect(oItem)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {CastDate(oItem.date)}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {oItem.category?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                    {oItem.paymentMethod?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-center">
                    {oItem.currency?.name ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-3 md:px-6 py-4 text-right">
                    {oItem.amount}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={8}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="5" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <CustomModal show={bIsOpenModal} onClose={onClose} title={getModalTitle()} className="">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <DateInput
            label="Fecha"
            value={dDate}
            labelClass="mb-1"
            onChange={setDate}
            placeholder="Fecha"
            className="w-full text-lg"
          />
          <Input
            label="Hora"
            value={sHour}
            className="w-full"
            onChange={setHour}
            placeholder="Hora"
          />
          <ComboBox
            label="Rubro"
            selected={oModalCategory}
            options={aCategories}
            onChange={setModalCategory}
            emptyLabel="Rubro"
          />
          <ComboBox
            label="Forma de pago"
            options={aPaymentMethods}
            emptyLabel="Forma de pago"
            selected={oModalPaymentMethod}
            onChange={setModalPaymentMethod}
          />
          <ComboBox
            label="Moneda"
            emptyLabel="Moneda"
            selected={oModalCurrency}
            onChange={setModalCurrency}
            options={CURRENCY_VALUES.filter(item => item.value !== 0)}
          />
          <Input
            label="Importe"
            value={sAmount}
            onChange={setAmount}
            placeholder="Importe"
            className="w-full text-right"
          />
          <Input
            textarea
            maxLength={250}
            label="Detalles"
            value={sDetails}
            className="w-full"
            onChange={setDetails}
            placeholder="Detalles"
            containerClassName="md:col-span-2"
          />
        </div>
        <div className="col-span-2 md:text-right flex justify-center mt-5 gap-3 md:justify-end">
          <Button
            label="Cancelar"
            className="mr-4"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            onClick={() => onClose()}
          />
          <Button
            label="Guardar"
            onClick={onSave}
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </CustomModal>
      <ConfirmationModal
        show={isGetReport}
        onConfirm={getReport}
        title="Emitir informe"
        onClose={() => setIsGetReport(false)}
        message="Esta acción puede tardar unos minutos"
        question="Seguro desea emitir el informe de la caja"
      />
    </ScreenContainer>
  );
};

export default CashRegisterList;
