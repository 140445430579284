import { Buffer } from "buffer";
import oAxiosInstance from "config/Axios";

const sSubPath = "seguridad/usuarios";

export const apiCallLogin = async(sEmail, sPassword) => {
  return oAxiosInstance.post(`${sSubPath}/login`, {
    UsrUsuario: sEmail,
    UsrClave: Buffer.from(sPassword).toString("base64"),
  });
};

export const apiCallRefreshCredentials = async(sToken) => {
  const oBody = new FormData();
  oBody.append("token", sToken);
  return oAxiosInstance.post(`${sSubPath}/control`, oBody);
};

export const apiCallLogout = () => {
  return oAxiosInstance.get(`${sSubPath}/logout`);
};
