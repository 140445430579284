import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllPrices, apiCallPricesDelete, apiCallPricesFind, apiCallPricesRecalculate, apiCallPricesSave, apiCallPricesUpdate } from "../api/PriceServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { PriceItemMapper, PricesMapper } from "./PricesMapper";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  oList: null,
  aPrices: [],
  bStatus: false,
};

export const actionPricesGetAll = createAsyncThunk("prices/getAllPrices", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllPrices();
    oThunk.dispatch(actionDismissLoading());
    return PricesMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionFindListPrices = createAsyncThunk("prices/find", async(id, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesFind(id);
    oThunk.dispatch(actionDismissLoading());
    return PriceItemMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPricesRecalculate = createAsyncThunk("prices/recalculate", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesRecalculate({
      lstid: oData.id,
      porcentaje: oData.value,
      redondeo: oData.rounded ? 1 : 0,
    });
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Recalculo realizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPricesGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPricesDelete = createAsyncThunk("prices/delete", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess?.message || "Lista eliminada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPricesGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPricesSave = createAsyncThunk("prices/save", async(sName, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesSave({ lisDsc: sName });
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lista generada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPricesGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPricesUpdate = createAsyncThunk("prices/update", async(oItem, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesUpdate(oItem.id, { lisDsc: oItem.name });
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lista actualizada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPricesGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const PricesSlice = createSlice({
  name: "PricesSlice",
  initialState: oInitialState,
  reducers: {
    actionPricesClean: oState => {
      oState.oList = [];
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionPricesGetAll.fulfilled, (oState, oAction) => {
      oState.aPrices = oAction.payload;
    });
    builder.addCase(actionFindListPrices.fulfilled, (oState, oAction) => {
      oState.oList = oAction.payload;
    });
    builder.addCase(actionFindListPrices.rejected, (oState) => {
      oState.bStatus = true;
    });
  },
});

export const { actionPricesClean } = PricesSlice.actions;

export default PricesSlice.reducer;
