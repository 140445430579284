import React, { useEffect, useMemo, useState } from "react";
import oDayJs from "dayjs";
import { BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { DATES_FORMATS } from "config/constants/Dates";
import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, ComboBox, DateInput, Table, Text } from "components";

import { actionReportSeller, selectReportsState } from "../redux";
import { SellerMapper } from "../mappers/ReportMapper";
import { CURRENCY_VALUES } from "config/constants/Currency";
import { actionSellersGetAll, selectSellersState } from "pages/sellers";
import { actionMessage } from "redux/shared/SharedSlice";
import { SysValidations } from "config/lang";

const SellerReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);
  const { aSellers } = useSelector(selectSellersState);

  const onGenerate = (oData) => oDispatch(actionReportSeller(oData));

  const [oSeller, setSeller] = useState(null);
  const [oCurrency, setCurrency] = useState(CURRENCY_VALUES[1]);
  const [dDateTo, setDateTo] = useState(oDayJs().toDate());
  const [dDateFrom, setDateFrom] = useState(oDayJs().subtract(1, "month").toDate());

  useEffect(() => {
    if (!aSellers) oDispatch(actionSellersGetAll());
  }, []);

  const oTotals = useMemo(() => {
    return aData.map(SellerMapper).reduce((oAccumulator, oItem) => {
      oAccumulator.total += oItem.total;
      oAccumulator.commission += oItem.commission;
      return oAccumulator;
    }, { total: 0, commission: 0 });
  }, [aData]);

  const onExport = () => {
    if (!oSeller)
      return oDispatch(actionMessage({ message: SysValidations.fieldRequired("Vendedor") }));
    onGenerate({
      hasta: oDayJs(dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      desde: oDayJs(dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      venId: oSeller?.id ?? 0,
      monId: oCurrency.value,
    });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Total de ventas por vendedor
        </Text>
        <Button
          onClick={onExport}
          label="Generar reporte"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<BiFile className="text-white ml-2 text-lg" />}
          containerClassName="mt-5 mx-auto sm:mr-0 sm:mt-0 sm:ml-auto"
        />
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha inicio</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={setDateFrom}
            placeholder="Fecha inicio"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha fin</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={setDateTo}
            placeholder="Fecha fin"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <ComboBox
          label="Moneda"
          emptyLabel="Moneda"
          selected={oCurrency}
          onChange={setCurrency}
          containerClassName="relative col-span-1 gap-5"
          options={CURRENCY_VALUES.filter(item => item.value !== 0)}
        />
        <ComboBox
          label="Vendedor"
          selected={oSeller}
          onChange={setSeller}
          emptyLabel="Vendedor"
          containerClassName="relative col-span-1 gap-5"
          options={aSellers?.map(item => ({ ...item, label: item.name, value: item.id }))}
        />
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Tipo de documento
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nro. doc
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Código
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Socio
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right">
                Total
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right">
                Comisión
              </Table.Col>
              <Table.Col />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(SellerMapper).map((oItem, nIndex) => (
                <Table.Row key={nIndex}>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.date}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.documentType}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.documentId}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.partner.code}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.partner.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.total}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.commission}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          {aData.length > 0 &&
            <Table.Footer>
              <Table.Row isHeaderOrFooter>
                <Table.Col colSpan="5" className="px-6 py-3 text-right">
                  <Text>Totales:</Text>
                </Table.Col>
                <Table.Col className="px-6 py-3 text-right">
                  <Text>{oTotals.total}</Text>
                </Table.Col>
                <Table.Col className="px-6 py-3 text-right">
                  <Text>{oTotals.commission}</Text>
                </Table.Col>
              </Table.Row>
            </Table.Footer>
          }
        </Table>
      </section>
    </section>
  );
};

export default SellerReport;
