import React, { useState } from "react";
import { BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, Checkbox, DateInput, Table, Text } from "components";

import { actionReportGetLastPurchaseByClient, selectReportsState } from "../redux";
import { LastPurchaseMapper } from "../mappers/ReportMapper";
import { CastDate, SubtractDate } from "utils/SharedUtils";
import { DATES_FORMATS } from "config/constants/Dates";

const LastPurchaseByClientReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);

  const onGenerate = (oData) => oDispatch(actionReportGetLastPurchaseByClient(oData));

  const [dDateFrom, setDateFrom] = useState(SubtractDate(new Date(), 30));
  const [bInclude, setInclude] = useState(false);

  const onExport = () => {
    onGenerate({
      desde: CastDate(dDateFrom, DATES_FORMATS.MYSQL_SERVER),
      incluyeSinVentas: bInclude ? 1 : 0,
    });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Última venta por cliente
        </Text>
        <Button
          label="Generar reporte"
          onClick={onExport}
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<BiFile className="text-white ml-2 text-lg" />}
          containerClassName="mt-5 mx-auto sm:mr-0 sm:mt-0 sm:ml-auto"
        />
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <DateInput
          value={dDateFrom}
          onChange={setDateFrom}
          placeholder="Fecha inicio"
          label="Fecha de referencia"
          containerClassName="rounded-lg overflow-hidden"
          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <Checkbox
          checked={bInclude}
          label="Incluir clientes sin ventas"
          onChange={() => setInclude(!bInclude)}
          containerClassName="flex items-center justify-end pt-8"
        />
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Código
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Nombre
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Tipo de documento
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Moneda
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right">
                Total
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(LastPurchaseMapper).map((oItem, nIndex) => (
                <Table.Row key={`${nIndex}`}>
                  <Table.Col className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center">
                    {oItem.partner?.code || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.partner?.name || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.date || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.documentType || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.currency || "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.total || "-"}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </section>
  );
};

export default LastPurchaseByClientReport;
