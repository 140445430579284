const Fields = {
  code: "Código",
  name: "Nombre",
  // ToDo averiguar si es iva o idIVA segun la documentacion y el campo mapeado
  ivaSell: "IVA de Venta",
  ivaBuy: "IVA de Compra",
  isActive: "Activo",
  marca: "Marca",
  model: "Modelo",
  stock: "Stock",
  minStock: "Stock mínimo",
  priceList: "Lista de precio",
  price: "Precio",
  currency: "Moneda",
  taxInclude: "Imp incluido",
  partner: "Socio Proveedor",
  procedure: "Procedimiento de fabricación",
  formula: "Fórmula",
};

export default Fields;
