import oAxiosInstance from "config/Axios";

export const apiCallGetAllPrices = () => {
  return oAxiosInstance.get("listas");
};

export const apiCallPricesFind = (nPriceId) => {
  return oAxiosInstance.get(`listas/unalista/${nPriceId}`);
};

export const apiCallPricesRecalculate = (oData) => {
  return oAxiosInstance.post("listas/ajusteporcentual", oData);
};

export const apiCallPricesSave = oData => {
  return oAxiosInstance.post("listas", oData);
};

export const apiCallPricesUpdate = (nId, oData) => {
  return oAxiosInstance.put(`listas/${nId}`, oData);
};

export const apiCallPricesDelete = nId => {
  return oAxiosInstance.delete(`listas/${nId}`);
};
