import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LotsMapper, LotsMovementMapper, LotsMovementTypeMapper, LotsMovementUnMapper, LotsStatusMapper, LotsUnMapper } from "./LotsMapper";
import { apiCallGetAllLots, apiCallLotsDelete, apiCallLotsGetMovementTypes, apiCallLotsGetMovements, apiCallLotsGetStatusList, apiCallLotsReCalculate, apiCallLotsSaveMovement, apiCallLotsSaveOrUpdate, apiCallLotsSuggest } from "../api/LotServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

export const actionLotsGetAll = createAsyncThunk("lots/getAllLots", async(args, oThunk) => {
  try {
    const { LotsSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      loteId: "",
      estloteid: oState.oStatus?.id || 0,
      pagina: oState.nPage,
      loteCod: oState.sCode || "",
      artid: oState.oArticle?.id || 0,
    };
    const oSuccess = await apiCallGetAllLots(oData);
    return { data: LotsMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionLotsGetMovements = createAsyncThunk("lots/getMovements", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsGetMovements(nId);
    return LotsMovementMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionLotsSaveOrUpdate = createAsyncThunk("lots/saveOrUpdate", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsSaveOrUpdate(LotsUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lote guardado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionLotsGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionLotsSaveMovement = createAsyncThunk("lots/saveMovement", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsSaveMovement(LotsMovementUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Movimiento guardado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionLotsGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionLotsDelete = createAsyncThunk("lots/delete", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Lote eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionLotsGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionLotsGetStatusList = createAsyncThunk("lots/getStatusList", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsGetStatusList();
    oThunk.dispatch(actionDismissLoading());
    return LotsStatusMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionLotsReCalculate = createAsyncThunk("lots/reCalculate", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsReCalculate(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Re calculo de stock realizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionLotsGetAll());
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionLotsGetMovementsTypes = createAsyncThunk("lots/getMovementsTypes", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLotsGetMovementTypes(nId);
    oThunk.dispatch(actionDismissLoading());
    return LotsMovementTypeMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionLotsSuggest = createAsyncThunk("lots/lotsSuggest", async(sFilter, oThunk) => {
  try {
    const oSuccess = await apiCallLotsSuggest({
      loteId: "",
      loteCod: sFilter,
      artid: 0,
      estloteid: 0,
      pagina: 1,
    });
    return LotsMapper(oSuccess.data || []);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const oInitialState = {
  aLots: [],
  nPages: null,
  nPage: 1,
  nRecords: null,

  aSuggestions: [],

  aStatus: [],
  aMovements: [],
  aMovementTypes: [],

  sCode: "",
  oStatus: null,
  oArticle: null,
};

const LotsSlice = createSlice({
  name: "LotsSlice",
  initialState: oInitialState,
  reducers: {
    actionLotsChangeCode: (oState, oAction) => {
      oState.sCode = oAction.payload;
    },
    actionLotsChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionLotsChangeStatus: (oState, oAction) => {
      oState.oStatus = oAction.payload;
    },
    actionLotsChangeArticle: (oState, oAction) => {
      oState.oArticle = oAction.payload;
    },
    actionLotsCleanMovements: (oState) => {
      oState.aMovements = [];
    },
    actionLotsCleanSuggestions: oState => {
      oState.aSuggestions = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(actionLotsGetAll.fulfilled, (oState, oAction) => {
      oState.aLots = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionLotsGetStatusList.fulfilled, (oState, oAction) => {
      oState.aStatus = oAction.payload;
    });
    builder.addCase(actionLotsGetMovements.fulfilled, (oState, oAction) => {
      oState.aMovements = oAction.payload;
    });
    builder.addCase(actionLotsGetMovementsTypes.fulfilled, (oState, oAction) => {
      oState.aMovementTypes = oAction.payload;
    });
    builder.addCase(actionLotsSuggest.fulfilled, (oState, oAction) => {
      oState.aSuggestions = oAction.payload;
    });
  },
});

export const {
  actionLotsChangeCode,
  actionLotsChangePage,
  actionLotsChangeStatus,
  actionLotsChangeArticle,
  actionLotsCleanMovements,
  actionLotsCleanSuggestions,
} = LotsSlice.actions;

export const selectLotsState = ({ LotsSlice: oState }) => oState;

export default LotsSlice.reducer;
