import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

import { ScreenContainer, Text, Input, Table, Button, ConfirmationModal } from "components";

import { actionRolesClean, actionRolesDelete, actionRolesGetAll, selectRolesState } from "pages/roles/redux";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { BUTTON_CONFIG } from "config/constants/Button";
import RolesLang from "./lang";

const RolesList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();

  const { aRoles, bStatus } = useSelector(selectRolesState);
  const [sFilter, setFilter] = useState("");
  const [aFilteredRoles, setFilteredRoles] = useState([]);
  const [oRoleToDelete, setRoleToDelete] = useState(null);

  useEffect(() => {
    oDispatch(actionRolesGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bStatus) oDispatch(actionRolesClean());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bStatus]);

  useEffect(() => {
    setFilteredRoles(() => {
      return aRoles?.length
        ? aRoles
          .filter(oRole => (oRole.name?.toLowerCase().includes(sFilter.toLowerCase()) || oRole.stateName?.toLowerCase().includes(sFilter.toLowerCase())))
          .map(oRole => ({ ...oRole, sFilter }))
        : [];
    });
  }, [aRoles, sFilter]);

  const onNavigate = () => {
    oNavigator("/roles/new");
  };

  const onDelete = (oRole) => setRoleToDelete(oRole);

  const onConfirmDelete = () => {
    oDispatch(actionRolesDelete(oRoleToDelete.id));
    setRoleToDelete(null);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center">
          {RolesLang.Labels.listRoles}
        </Text>
        <Button
          label={RolesLang.Buttons.new}
          onClick={onNavigate}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5">
        <div className="relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={setFilter}
            placeholder={RolesLang.Buttons.search}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"RolesTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                {RolesLang.Fields.name}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFilteredRoles.length > 0 ? (
              aFilteredRoles.map((oRole, nIndex) => (
                <Table.Row key={oRole.id} onClick={() => oNavigator(`${oRole.id}`)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    <Highlighter
                      searchWords={[oRole.sFilter]}
                      autoEscape={true}
                      textToHighlight={oRole.name}
                    />
                  </Table.Col>
                  <Table.Col className="text-center px-2">
                    <div className="flex justify-center">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oRole);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={3}>
                  <em>
                    {sFilter.length > 0
                      ? RolesLang.Labels.emptyResults
                      : RolesLang.Labels.emptyRoles}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
      <ConfirmationModal
        show={!!oRoleToDelete}
        onClose={() => setRoleToDelete(null)}
        onConfirm={onConfirmDelete}
        title={RolesLang.Labels.deleteRole}
        question={`Está seguro que desea eliminar el rol "${oRoleToDelete?.name}"`}
        message={RolesLang.Labels.actionNotReversed}
      />
    </ScreenContainer>
  );
};

export default RolesList;
