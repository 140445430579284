const SysButtons = {
  save: "Guardar",
  cancel: "Cancelar",
  back: "Volver",
  new: "Nuevo",
  yes: "Si",
  no: "No",
  update: "Actualizar",
  delete: "Eliminar",
  search: "Buscar",
  add: "Agregar",
  convert: "Crear documento",
};

export default SysButtons;
