export const DocumentTypesMapper = aDocuments => {
  const mapDocumentTypes = oServerDocument => ({
    id: oServerDocument.tipoDocId,
    name: oServerDocument.tipoDocNom,
    label: oServerDocument.tipoDocNom,
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(mapDocumentTypes);
  if ((typeof aDocuments).toString() === "object") return mapDocumentTypes(aDocuments);
  return null;
};

export const DocumentTypesUnMapper = aDocuments => {
  const unMapDocumentTypes = oDocument => ({
    tipoDocId: oDocument.id || 0,
    tipoDocNom: oDocument.name,
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(unMapDocumentTypes);
  if ((typeof aDocuments).toString() === "object") return unMapDocumentTypes(aDocuments);
  return null;
};
