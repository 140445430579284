import { Buffer } from "buffer";

import oAxiosInstance from "config/Axios";

export const apiCallGetAllUsers = () => {
  return oAxiosInstance.get("seguridad/usuarios");
};

export const apiCallUsersSave = oData => {
  return oAxiosInstance.post("seguridad/usuarios", oData);
};

export const apiCallUsersUpdate = (nId, oData) => {
  return oAxiosInstance.put(`seguridad/usuarios/${nId}`, oData);
};

export const apiCallUsersChangePassword = (oData) => {
  const oDataUpdated = {
    ...oData,
    usrClaveActual: Buffer.from(oData.usrClaveActual).toString("base64"),
    usrClaveNueva: Buffer.from(oData.usrClaveNueva).toString("base64"),
  };
  return oAxiosInstance.post("seguridad/usuarios/cambioclave", oDataUpdated);
};

export const apiCallUsersFind = (nId) => {
  return oAxiosInstance.get(`seguridad/unusuarios/${nId}`);
};

export const apiCallUsersDelete = (nId) => {
  return oAxiosInstance.delete(`seguridad/usuarios/${nId}`);
};
