import oAxiosInstance from "config/Axios";
import { PARTNERS_BALANCE_TYPES } from "../constants/Partners";

export const apiCallGetAllPartners = oData => {
  return oAxiosInstance.post("socios/consultar", oData);
};

export const apiCallPartnersSave = oData => {
  return oAxiosInstance.post("socios", oData);
};

export const apiCallPartnersUpdate = (nId, oData) => {
  return oAxiosInstance.put(`socios/${nId}`, oData);
};

export const apiCallPartnersDelete = nId => {
  return oAxiosInstance.delete(`socios/${nId}`);
};

export const apiCallPartnersSuggest = oData => {
  return oAxiosInstance.post("socios/consultarapida", oData);
};

export const apiCallPartnersGetDebtorData = oData => {
  return oAxiosInstance.post("socios/saldodeudor", oData);
};

export const apiCallPartnersGetCreditorData = oData => {
  return oAxiosInstance.post("socios/saldoacreedor", oData);
};

export const apiCallPartnersStatementAccount = (oData, sParamType) => {
  const sType = sParamType === PARTNERS_BALANCE_TYPES.CREDITOR ? "estadodecuentasacreedor" : "estadodecuentasdeudor";
  return oAxiosInstance.post(`socios/${sType}`, oData);
};
