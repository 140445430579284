const Fields = {
  name: "Nombre",
  username: "Usuario",
  email: "Email",
  role: "Rol",
  status: "Estado",
  password: "Contraseña",
  passwordConfirm: "Confirmar contraseña",
  passwordCurrent: "Contraseña actual",
  passwordNew: "Nueva contraseña",
  passwordNewConfirm: "Confirmar nueva contraseña",
};

export default Fields;
