import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiCallGetAllPartners,
  apiCallPartnersDelete,
  apiCallPartnersGetCreditorData,
  apiCallPartnersGetDebtorData,
  apiCallPartnersSave,
  apiCallPartnersStatementAccount,
  apiCallPartnersSuggest,
  apiCallPartnersUpdate,
} from "../api/PartnerServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading, actionMessage } from "redux/shared/SharedSlice";
import { PartnersMapper, PartnersMapperCurrentAccount, PartnersMapperStatementAccount, PartnersMapperStatementAccountReport, PartnersMapperSuggest, PartnersUnMapper } from "./PartnersMapper";
import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { CURRENCY_CODES } from "config/constants/Currency";

const oInitialState = {
  aPartners: [],
  nPages: null,
  nRecords: null,
  bStatus: false,
  oPartner: null,
  aSuggestions: [],

  sFilter: "",
  nPage: 1,

  oDebtorData: null,
  oCreditorData: null,

  oDataStatementAccount: null,

  sReport: null,
};

export const actionPartnersGetAll = createAsyncThunk("partners/getAllPartners", async(_, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const { nPage, sFilter } = oThunk.getState().PartnersSlice;
    const oData = {
      pagina: nPage,
      filtro: sFilter.trim(),
    };
    const oSuccess = await apiCallGetAllPartners(oData);
    return { data: PartnersMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersSave = createAsyncThunk("partners/partnersSave", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersSave(PartnersUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Socio de negocio generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersFind = createAsyncThunk("partners/partnersFind", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllPartners({ pagina: 1, filtro: `${nId}` });
    if (!oSuccess.data[0]) throw new Error("No se encontró el socio de negocio");
    return PartnersMapper(oSuccess.data[0] || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersUpdate = createAsyncThunk("partners/partnersUpdate", async(oPartner, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oPartnerMapped = PartnersUnMapper(oPartner);
    const oSuccess = await apiCallPartnersUpdate(oPartner.id, oPartnerMapped);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Socio actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionPartnersGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersDelete = createAsyncThunk("partners/partnersDelete", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersDelete(nId);
    oThunk.dispatch(actionDismissLoading());
    oThunk.dispatch(
      actionMessage({
        message: oSuccess?.message || "Socio eliminado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPartnersSuggest = createAsyncThunk("partners/partnersSuggest", async(sFilter, oThunk) => {
  try {
    const oSuccess = await apiCallPartnersSuggest({ filtro: sFilter });
    return PartnersMapperSuggest(oSuccess.data || []);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionPartnersGetDebtorData = createAsyncThunk("partners/getDebtorData", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGetDebtorData(PartnersMapperCurrentAccount(oData));
    return { currency: oData.idCurrency, data: oSuccess.data };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGetCreditorData = createAsyncThunk("partners/getCreditorData", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersGetCreditorData(PartnersMapperCurrentAccount(oData));
    return { currency: oData.idCurrency, data: oSuccess.data };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGetStatementAccount = createAsyncThunk("partners/getStatementAccount", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersStatementAccount(PartnersMapperCurrentAccount(oData), oData.type);
    return { currency: oData.idCurrency, data: PartnersMapperStatementAccount(oSuccess.data) };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersGetStatementAccountReport = createAsyncThunk("partners/getStatementAccountReport", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersStatementAccount(PartnersMapperCurrentAccount(oData), oData.type);
    return PartnersMapperStatementAccountReport(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionPartnersSendStatementAccountReport = createAsyncThunk("partners/sendStatementAccountReport", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPartnersStatementAccount(PartnersMapperCurrentAccount(oData), oData.type);
    oThunk.dispatch(
      actionMessage({
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
        message: oSuccess?.message || "Email enviado correctamente",
      }),
    );
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const PartnersSlice = createSlice({
  name: "PartnersSlice",
  initialState: oInitialState,
  reducers: {
    actionCleanPartner: oState => {
      oState.oPartner = null;
      oState.oDebtorData = null;
      oState.oCreditorData = null;
      oState.oDataStatementAccount = null;
      oState.sReport = null;
    },
    actionPartnersCleanStatementAccount: oState => {
      oState.oDataStatementAccount = null;
    },
    actionPartnersClean: oState => {
      oState.bStatus = false;
    },
    actionPartnersCleanSuggestions: oState => {
      oState.aSuggestions = [];
    },
    actionPartnersCleanReport: oState => {
      oState.sReport = null;
    },
    actionPartnersChangeFilter: (oState, oAction) => {
      oState.sFilter = oAction.payload;
    },
    actionPartnersChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionPartnersGetAll.fulfilled, (oState, oAction) => {
      oState.aPartners = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionPartnersSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionPartnersFind.fulfilled, (oState, oAction) => {
      oState.oPartner = oAction.payload;
    });
    builder.addCase(actionPartnersDelete.fulfilled, (oState) => {
      oState.bStatus = true;
    });
    builder.addCase(actionPartnersSuggest.fulfilled, (oState, oAction) => {
      oState.aSuggestions = oAction.payload;
    });
    builder.addCase(actionPartnersUpdate.fulfilled, oState => {
      oState.bStatus = true;
    });
    builder.addCase(actionPartnersGetDebtorData.fulfilled, (oState, oAction) => {
      const oData = {
        ...oState.oDebtorData,
        ...(oAction.payload.currency === CURRENCY_CODES.DOLLAR
          ? { dollar: oAction.payload.data }
          : { uyu: oAction.payload.data }),
      };
      oState.oDebtorData = oData;
    });
    builder.addCase(actionPartnersGetCreditorData.fulfilled, (oState, oAction) => {
      const oData = {
        ...oState.oCreditorData,
        ...(oAction.payload.currency === CURRENCY_CODES.DOLLAR
          ? { dollar: oAction.payload.data }
          : { uyu: oAction.payload.data }),
      };
      oState.oCreditorData = oData;
    });
    builder.addCase(actionPartnersGetStatementAccount.fulfilled, (oState, oAction) => {
      const oData = {
        ...oState.oDataStatementAccount,
        ...(oAction.payload.currency === CURRENCY_CODES.DOLLAR
          ? { dollar: oAction.payload.data }
          : { uyu: oAction.payload.data }),
      };
      oState.oDataStatementAccount = oData;
    });
    builder.addCase(actionPartnersGetStatementAccountReport.fulfilled, (oState, oAction) => {
      oState.sReport = oAction.payload;
    });
  },
});

export const {
  actionCleanPartner,
  actionPartnersClean,
  actionPartnersCleanReport,
  actionPartnersCleanSuggestions,
  actionPartnersCleanStatementAccount,
  actionPartnersChangeFilter,
  actionPartnersChangePage,
} = PartnersSlice.actions;

export default PartnersSlice.reducer;
