export const FamiliesMapper = aFamilies => {
  const mapFamilies = oServerFamily => ({
    id: oServerFamily.famId,
    name: oServerFamily.famNom,
    // Field for combo
    label: oServerFamily.famNom,
  });

  if (Array.isArray(aFamilies)) return aFamilies.map(mapFamilies);
  if ((typeof aFamilies).toString() === "object") return mapFamilies(aFamilies);
  return null;
};

export const FamiliesUnMapper = aFamilies => {
  const unMapFamilies = oFamily => ({
    famId: oFamily.id || 0,
    famNom: oFamily.name,
  });

  if (Array.isArray(aFamilies)) return aFamilies.map(unMapFamilies);
  if ((typeof aFamilies).toString() === "object") return unMapFamilies(aFamilies);
  return null;
};
