import oAxiosInstance from "config/Axios";

export const apiCallGetAllSellers = () => {
  return oAxiosInstance.get("vendedores");
};

export const apiCallSellersSave = oData => {
  return oAxiosInstance.post("vendedores", oData);
};

export const apiCallSellersUpdate = (nId, oData) => {
  return oAxiosInstance.put(`vendedores/${nId}`, oData);
};

export const apiCallSellersDelete = nId => {
  return oAxiosInstance.delete(`vendedores/${nId}`);
};
