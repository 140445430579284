export const DocumentTypesPartnersMapper = aDocuments => {
  const mapDocumentTypesPartners = oServerDocument => ({
    id: oServerDocument.tipoDocId,
    name: oServerDocument.tipoDocIdNom,
    label: oServerDocument.tipoDocIdNom,
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(mapDocumentTypesPartners);
  if ((typeof aDocuments).toString() === "object") return mapDocumentTypesPartners(aDocuments);
  return null;
};

export const DocumentTypesPartnersUnMapper = aDocuments => {
  const unMapDocumentTypesPartners = oDocument => ({
    tipoDocId: oDocument.id || 0,
    tipoDocIdNom: oDocument.name,
  });

  if (Array.isArray(aDocuments)) return aDocuments.map(unMapDocumentTypesPartners);
  if ((typeof aDocuments).toString() === "object") return unMapDocumentTypesPartners(aDocuments);
  return null;
};
