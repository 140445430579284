import oAxiosInstance from "config/Axios";

export const apiCallGetAllPartnersGroups = () => {
  return oAxiosInstance.get("gruposdesocios");
};

export const apiCallPartnersGroupSave = oData => {
  return oAxiosInstance.post("gruposdesocios", oData);
};

export const apiCallPartnersGroupUpdate = (nId, oData) => {
  return oAxiosInstance.put(`gruposdesocios/${nId}`, oData);
};

export const apiCallPartnersGroupDelete = nId => {
  return oAxiosInstance.delete(`gruposdesocios/${nId}`);
};

export const apiCallPartnersGroupGetDiscounts = nId => {
  return oAxiosInstance.get(`gruposdesocios/descuentos/${nId}`);
};

export const apiCallPartnersGroupSaveDiscounts = oData => {
  return oAxiosInstance.post("gruposdesocios/descuentos", oData);
};

export const apiCallPartnersGroupDeleteDiscounts = oData => {
  return oAxiosInstance.delete("gruposdesocios/descuentos", { data: oData });
};
