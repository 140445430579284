import React from "react";
import PropsTypes from "prop-types";
import { BiX } from "react-icons/bi";
import { CONSTANTS_COLORS } from "config/constants/Colors";

function CustomModal({
  title: sTitle,
  children: oChildren,
  show: bShow = false,
  className: sClassName = "",
  onClose: onClose = () => { },
}) {
  return (
    bShow && <>
      <div className="fixed top-0 right-0 h-screen w-screen z-40 bg-gray-800 opacity-50" />
      <div className="fixed z-50 top-0 right-0 h-screen w-screen shadow rounded flex items-center justify-center" onClick={onClose} >
        <div className={"shadow rounded max-w-xs md:max-w-md max-h-[90vh] overflow-scroll " + sClassName} onClick={oEvent => oEvent.stopPropagation()} >
          <div className="bg-gray-800 dark:bg-gray-900 text-white text-center p-2 grid grid-cols-12">
            <span className="col-span-1"></span>
            <span className="col-span-10">{sTitle}</span>
            <div className="col-span-1 flex justify-center align-middle">
              <BiX size={23} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} onClick={onClose} />
            </div>
          </div>
          <div className="bg-white text-dark dark:bg-dark dark:text-gray-300 p-5">
            {oChildren}
          </div>
        </div>
      </div>
    </>
  );
}

CustomModal.propTypes = {
  title: PropsTypes.string,
  show: PropsTypes.bool.isRequired,
  onClose: PropsTypes.func.isRequired,
};

export default CustomModal;
