import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  listDocuments: "Documentos",
  createDocument: "Crear documento",
  editDocument: "Editar documento",
  documentData: "Datos del documento",
  emptyDocuments: "Sin documentos registrados",
  dateFrom: "Fecha desde",
  dateTo: "Fecha hasta",
  lines: "Lineas",
  article: "Artículo",
  quantity: "Cantidad",
  unitPrice: "Precio unitario",
  quantityAbbreviation: "Cant",
  unitPriceAbbreviation: "P/U",
  subtotal: "Subtotal",
  total: "Total",
  emptyLines: "Sin lineas registradas",
  partner: "Socio",
  discount: "Descuento",
  tax: "IVA",
  taxAbbreviation: "IVA",
  unit: "Unidad",
  indicator: "Indicador de facturación",
  rounding: "Redondeo",
  totals: "Totales",
  holder: "Titular del documento",
  lot: "Lote",
};

export default Labels;
