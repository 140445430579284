import SysValidations from "config/lang/SysValidations";

const Validations = {
  ...SysValidations,
  invalidEmail: "Formato de email incorrecto",
  passwordNotMatch: "Las contraseñas proporcionadas no coinciden",
  passwordShort: "La contraseña debe contener 5 o más caracteres",
};

export default Validations;
