import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallGetAllUnits } from "../api/UnitServices";
import { actionManageMessage, actionShowLoading, actionDismissLoading } from "redux/shared/SharedSlice";
import { UnitsMapper } from "./UnitsMapper";

const oInitialState = {
  aUnits: [],
  bStatus: false,
};

export const actionUnitsGetAll = createAsyncThunk("Units/getAllUnits", async(args, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetAllUnits();
    oThunk.dispatch(actionDismissLoading());
    return UnitsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const UnitsSlice = createSlice({
  name: "UnitsSlice",
  initialState: oInitialState,
  reducers: {
    actionUnitsClean: oState => {
      oState.bStatus = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionUnitsGetAll.fulfilled, (oState, oAction) => {
      oState.aUnits = oAction.payload;
    });
  },
});

export const { actionUnitsClean } = UnitsSlice.actions;

export const selectUnitsState = ({ UnitsSlice: oState }) => oState;

export default UnitsSlice.reducer;
