import React from "react";
import oDayJs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import Text from "./Text";

import { CONSTANTS_TEXT_STYLE } from "config/constants/Themes";

registerLocale("es", es);

const DateInput = ({
  value: sValue,
  disabled = false,
  onChange = () => { },
  label: sLabel = null,
  value: dValue = null,
  className: sClassName = null,
  placeholder: sPlaceHolder = "",
  labelClass: sLabelClass = null,
  floatLabel: bFloatLabel = false,
  labelSimple: bLabelSimple = false,
  containerClassName: sContainerClassName = "",
  labelStyle: nLabelStyle = CONSTANTS_TEXT_STYLE.DEFAULT,
  showTimeSelect: bShowTimeSelect = false,
}) => {
  if (!sValue) sValue = "";

  const aClassName = [
    "text-gray-800 dark:text-white",
    "border-input",
    "rounded-md",
    "px-2",
    "py-2",
    "bg-gray-100 dark:bg-gray-700",
    "text-md",
  ];

  if (sClassName) aClassName.push(sClassName);

  const getDateValue = () => {
    return typeof dValue === "string" ? oDayJs(dValue).toDate() : dValue;
  };

  const onRenderInput = () => {
    return <DatePicker
      locale={"es"}
      timeIntervals={5}
      disabled={disabled}
      onChange={onChange}
      selected={getDateValue()}
      placeholderText={sPlaceHolder}
      showTimeSelect={bShowTimeSelect}
      className={aClassName.join(" ")}
      dateFormat={bShowTimeSelect ? "dd/MM/yyyy hh:mm" : "dd/MM/yyyy"}
    />;
  };

  if (!bFloatLabel) {
    return (
      <div className={sContainerClassName}>
        {sLabel ? (
          <Text
            style={nLabelStyle}
            simple={bLabelSimple}
            size={"text-lg"}
            className={sLabelClass + (sValue ? " active" : "")}
          >
            {sLabel}
          </Text>
        ) : null}
        {onRenderInput()}
      </div>
    );
  }

  return (
    <div className={`float-label ${sContainerClassName} transition-all`}>
      {onRenderInput()}
      {sLabel ? (
        <Text className={sValue ? "active" : ""} style={nLabelStyle} size={"text-lg"}>
          {sLabel}
        </Text>
      ) : null}
    </div>
  );
};

export default DateInput;
