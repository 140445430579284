import { createSlice } from "@reduxjs/toolkit";
import { CONSTANTS_MESSAGE } from "config/constants/Message";

const oInitialState = {
  bLoading: false,
  sMessage: null,
  sMessageType: null,
};

const SharedSlice = createSlice({
  name: "SharedSlice",
  initialState: oInitialState,
  reducers: {
    actionMessage: (oState, oAction) => ({
      ...oState,
      bLoading: false,
      sMessage: oAction.payload.message || CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR,
      sMessageType: oAction.payload.type || CONSTANTS_MESSAGE.TYPE_ERROR,
    }),
    actionCleanMessage: oState => ({
      ...oState,
      sMessage: null,
      sMessageType: null,
    }),
    actionShowLoading: oState => ({
      ...oState,
      bLoading: true,
    }),
    actionDismissLoading: oState => ({
      ...oState,
      bLoading: false,
    }),
    actionManageMessage: (oState, oAction) => {
      const oReturn = {
        ...oState,
        bLoading: false,
        sMessage: oAction.payload?.message || CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR,
        sMessageType: oAction.payload?.messageType || CONSTANTS_MESSAGE.TYPE_ERROR,
      };
      if (typeof oAction.payload === "string") {
        if (oAction.payload === "Network Error") return { ...oReturn, sMessage: "Conexión de red inestable" };
        if (oAction.payload === "Request failed with status code 404") return { ...oReturn, sMessageType: CONSTANTS_MESSAGE.TYPE_WARNING, sMessage: "Método no implementado" };
        return { ...oReturn, sMessage: oAction.payload };
      }
      if (oAction.payload?.data?.message) return { ...oReturn, sMessage: oAction.payload.data.message };
      return oReturn;
    },
  },
});

export const { actionMessage, actionCleanMessage, actionShowLoading, actionDismissLoading, actionManageMessage } =
  SharedSlice.actions;

export default SharedSlice.reducer;
