import React, { useEffect, useState } from "react";
import oDayJs from "dayjs";
import { BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { DATES_FORMATS } from "config/constants/Dates";
import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, Checkbox, ComboBox, DateInput, PartnerSuggestions, Table, Text } from "components";

import { actionReportGetPendingCredit, selectReportsState } from "../redux";
import { PendingCreditMapper } from "../mappers/ReportMapper";
import { selectLoguedUser } from "redux/auth/AuthSelector";
import { CURRENCY_VALUES } from "config/constants/Currency";

const PendingDocumentsReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);

  const onGenerate = (oData) => oDispatch(actionReportGetPendingCredit(oData));

  const aTypes = [
    { label: "Venta", value: "V" },
    { label: "Compra", value: "C" },
  ];

  const [oType, setType] = useState(aTypes[0]);
  const [oPartner, setPartner] = useState(null);
  const [bIsActive, setIsActive] = useState(true);
  const [oCurrency, setCurrency] = useState(CURRENCY_VALUES[1]);
  const [dDateTo, setDateTo] = useState(oDayJs().toDate());
  const [dDateFrom, setDateFrom] = useState(oDayJs().subtract(1, "month").toDate());

  const oUser = useSelector(selectLoguedUser);
  useEffect(() => {
    if (oUser && oUser.config?.idCurrency)
      setCurrency(CURRENCY_VALUES.find(item => item.value === oUser.config?.idCurrency));
  }, [oUser]);


  const onExport = () => {
    onGenerate({
      desde: oDayJs(dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      socid: oPartner?.id ?? 0,
      monid: oCurrency.value,
      incsuc: bIsActive ? 1 : 0,
      tipo: oType.value,
    });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Documentos pendientes
        </Text>
        <Button
          label="Generar reporte"
          onClick={onExport}
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<BiFile className="text-white ml-2 text-lg" />}
          containerClassName="mt-5 mx-auto sm:mr-0 sm:mt-0 sm:ml-auto"
        />
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha inicio</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={setDateFrom}
            placeholder="Fecha inicio"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha fin</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={setDateTo}
            placeholder="Fecha fin"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            selected={oCurrency}
            onChange={setCurrency}
            label="Moneda"
            emptyLabel="Todas"
            options={[CURRENCY_VALUES[1], CURRENCY_VALUES[2]]}
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            selected={oType}
            onChange={setType}
            label="Tipo"
            emptyLabel="Todas"
            options={aTypes}
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <PartnerSuggestions
            className="w-full"
            onSelect={setPartner}
            value={oPartner?.name}
            label="Socio de negocio"
            placeholder="Socio de negocio"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <Checkbox
            checked={bIsActive}
            containerClassName="flex-1 mb-0 pt-8"
            label="Incluir sucursales"
            onChange={() => setIsActive(!bIsActive)}
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Socio
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Tipo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Nro
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                CAE
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Total
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Saldo
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(PendingCreditMapper).map((oItem, nIndex) => (
                <React.Fragment key={`${nIndex}`}>
                  <Table.Row>
                    <Table.Col
                      scope="row"
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {oItem.partner?.name}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem.document?.type}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center">
                      {oItem.document?.date}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {oItem.document?.nro}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {oItem.document?.cae}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      $ {oItem.document?.total}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      $ {oItem.document?.balance}
                    </Table.Col>
                  </Table.Row>
                  {oItem.discounts?.length > 0 && (
                    <>
                      <Table.Row className="bg-gray-200 dark:bg-gray-900">
                        <Table.Col className="py-1 text-center" colSpan={7}>
                          <Text className="font-bold text-gray-400">
                            Descuentos
                          </Text>
                        </Table.Col>
                      </Table.Row>
                      <Table.Row className="bg-gray-300 dark:bg-gray-900">
                        <Table.Col className="px-6 py-1 text-center" colSpan={2}>
                          Tipo
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          Nro
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          CAE
                        </Table.Col>
                        <Table.Col className="px-6 py-1 text-right" colSpan={2}>
                          Importe
                        </Table.Col>
                      </Table.Row>
                      {oItem.discounts.map((oDiscount, nDiscountIndex) => (
                        <Table.Row key={`${nIndex}_${nDiscountIndex}`} className="bg-gray-200 dark:bg-gray-900">
                          <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                            {oDiscount.type}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            {oDiscount.nro}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            {oDiscount.cae}
                          </Table.Col>
                          <Table.Col className="px-6 py-4 text-right" colSpan='2'>
                            $ {oDiscount.amount ?? "0"}
                          </Table.Col>
                        </Table.Row>))}
                    </>)}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </section>
  );
};

export default PendingDocumentsReport;
