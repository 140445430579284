import { createAsyncThunk } from "@reduxjs/toolkit";

import { actionDismissLoading, actionManageMessage, actionMessage, actionShowLoading } from "redux/shared/SharedSlice";
import { apiCallLogin, apiCallRefreshCredentials } from "api/AuthServices";
import { actionLogout } from "./AuthSlice";

import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { UsersMapper } from "pages/users/redux";
import { apiCallGetUserManual } from "api/CommonApiServices";

export const actionLogin = createAsyncThunk("auth/actionCallLogin", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallLogin(oData.email, oData.password);
    const oUserMapped = UsersMapper(oSuccess?.data);
    oThunk.dispatch(
      actionMessage({
        message: "Bienvenido",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oUserMapped;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionRefreshCredentials = createAsyncThunk("auth/refreshCredentials", async(sToken, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallRefreshCredentials(sToken);
    const oUser = UsersMapper(oSuccess?.data);
    return oUser || null;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk.dispatch(actionLogout());
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
  return null;
});

export const actionGetManual = createAsyncThunk("auth/getManual", async(sToken, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetUserManual(sToken);
    return oSuccess.data;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
  return null;
});
