import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  listUsers: "Listado de usuarios",
  createUser: "Crear usuario",
  editUser: "Editar usuario",
  userData: "Datos de usuario",
  userProfile: "Perfil de usuario",
  security: "Seguridad",
  changePassword: "Cambiar contraseña",
};

export default Labels;
