import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogoutAction, selectLoguedUser } from "redux/auth/AuthSelector";
import useLocalStorage from "hooks/useLocalStorage";
import { SYSTEM_CONSTANTS } from "config/constants/System";
import { actionAuthClean } from "redux/auth/AuthSlice";

const { APP_THEME_KEY, APP_TOKEN_KEY } = SYSTEM_CONSTANTS;

export const SharedContext = React.createContext({});

const oDarkThemeUser = window.matchMedia("(prefers-color-scheme: dark)");

export default function SharedContextProvider({ children }) {
  const oUser = useSelector(selectLoguedUser);
  const bLogout = useSelector(selectLogoutAction);

  const [bIsAuthenticated, setIsAuthenticated] = useState(false);
  const [bDarkTheme, setDarkTheme] = useLocalStorage(APP_THEME_KEY, oDarkThemeUser?.matches || false);
  const [sToken, setToken] = useLocalStorage(APP_TOKEN_KEY, null);

  useEffect(() => {
    if (oUser?.token || sToken) {
      setToken(oUser?.token || sToken);
      setIsAuthenticated(true);
    } else {
      Logout();
    }
  }, [oUser]);

  useEffect(() => {
    if (bLogout) {
      Logout();
      actionAuthClean();
    }
  }, [bLogout]);

  const Logout = () => {
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <SharedContext.Provider value={{ bIsAuthenticated, bDarkTheme, setDarkTheme, sToken, Logout }}>
      {children}
    </SharedContext.Provider>
  );
}
