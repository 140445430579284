import React, { useId } from "react";
import { INPUT_TYPE } from "./Input";
import Text from "./Text";

const Checkbox = ({
  onChange = () => { },
  label: sLabel = null,
  checked: bChecked = false,
  className: sClassName = "",
  disabled: bDisabled = false,
  labelClassName: sLabelClassName = "",
  containerClassName: sContainerClassName = "",
}) => {
  const id = useId();
  return <div className={"flex items-center " + sContainerClassName}>
    <input
      id={id}
      checked={bChecked}
      onChange={onChange}
      disabled={bDisabled}
      type={INPUT_TYPE.CHECKBOX}
      className={"w-4 h-4 cursor-pointer rounded focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 " + sClassName}
    />
    <Text className={"ml-2 " + sLabelClassName}><label className="cursor-pointer" htmlFor={id}>{sLabel}</label></Text>
  </div>;
};

export default Checkbox;
