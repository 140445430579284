export const CashRegisterMapper = aRegisters => {
  const mapItem = oServerCashRegister => ({
    id: oServerCashRegister.movCajaId,
    date: oServerCashRegister.fecha,
    hour: oServerCashRegister.hora,
    amount: oServerCashRegister.importe,
    details: oServerCashRegister.movCajaDesc,
    paymentMethod: {
      id: oServerCashRegister.fpId,
      value: oServerCashRegister.fpId,
      name: oServerCashRegister.fpNom,
      label: oServerCashRegister.fpNom,
    },
    category: {
      id: oServerCashRegister.rubId,
      value: oServerCashRegister.rubId,
      name: oServerCashRegister.rubNom,
      label: oServerCashRegister.rubNom,
    },
    currency: {
      id: oServerCashRegister.monId,
      value: oServerCashRegister.monId,
      name: oServerCashRegister.monNom,
      label: oServerCashRegister.monNom,
    },
  });
  if (!aRegisters) return null;
  if (Array.isArray(aRegisters)) return aRegisters.map(mapItem);
  if ((typeof aRegisters).toString() === "object") return mapItem(aRegisters);
  return null;
};

export const CashRegisterMapperCurrentRegister = aRegisters => {
  const mapItem = oServerCashRegister => ({
    amount: oServerCashRegister.importe,
    paymentMethod: {
      id: oServerCashRegister.fpId,
      value: oServerCashRegister.fpId,
      name: oServerCashRegister.fpNom,
      label: oServerCashRegister.fpNom,
    },
    currency: {
      id: oServerCashRegister.monId,
      value: oServerCashRegister.monId,
      name: oServerCashRegister.monNom,
      label: oServerCashRegister.monNom,
    },
  });
  if (!aRegisters) return null;
  if (Array.isArray(aRegisters)) return aRegisters.map(mapItem);
  if ((typeof aRegisters).toString() === "object") return mapItem(aRegisters);
  return null;
};

export const CashRegisterUnMapper = aRegisters => {
  const unMapItem = oItem => ({
    // TODO: Is needed?
    // movCajaId: oItem.id ?? 0,
    fecha: oItem.date,
    hora: oItem.hour,
    importe: oItem.amount,
    fpid: oItem.paymentMethod?.id ?? 0,
    rubid: oItem.category?.id ?? 0,
    monid: oItem.currency?.value ?? 0,
    movcajadesc: oItem.details,
  });
  if (!aRegisters) return null;
  if (Array.isArray(aRegisters)) return aRegisters.map(unMapItem);
  if ((typeof aRegisters).toString() === "object") return unMapItem(aRegisters);
  return null;
};

export const CashRegisterPaymentMethodMapper = aRegisters => {
  const mapItem = oPaymentMethod => ({
    id: oPaymentMethod.fpId,
    value: oPaymentMethod.fpId,
    name: oPaymentMethod.fpNom,
    label: oPaymentMethod.fpNom,
  });
  if (!aRegisters) return null;
  if (Array.isArray(aRegisters)) return aRegisters.map(mapItem);
  if ((typeof aRegisters).toString() === "object") return mapItem(aRegisters);
  return null;
};

export const CashRegisterCategoryMapper = aRegisters => {
  const mapItem = oCategory => ({
    id: oCategory.rubId,
    value: oCategory.rubId,
    name: oCategory.rubNom,
    label: oCategory.rubNom,
  });
  if (!aRegisters) return null;
  if (Array.isArray(aRegisters)) return aRegisters.map(mapItem);
  if ((typeof aRegisters).toString() === "object") return mapItem(aRegisters);
  return null;
};
