import Buttons from "./Buttons";
import Fields from "./Fields";
import Labels from "./Labels";
import Validations from "./Validations";

const ArticlesLang = {
  Buttons,
  Fields,
  Labels,
  Validations,
};

export default ArticlesLang;
